var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.notify)?_c('nuxt-link',{attrs:{"to":_vm.nuxtTo},nativeOn:{"click":function($event){return _vm.markNotifyRead($event)}}},[_c('b-row',{staticClass:"justify-content-between notifyItem notify-border-bottom",class:_vm.notify.read ? 'bg-read' : 'bg-unread',attrs:{"noGutters":""}},[(_vm.notify.who)?_c('b-col',{staticClass:"notifyItem__left",attrs:{"cols":"auto"}},[_c('ScanTraderAvatar',{attrs:{"width":"40","height":"40","src":_vm.notify.who.avatar,"lazy":""}})],1):_vm._e(),_vm._v(" "),_c('b-col',{staticClass:"notifyItem__center pr-3 pl-2"},[_c('b-row',{staticClass:"p-0 notifyItem__center__top flex-nowrap mt-2 pt-1",attrs:{"noGutters":""}},[_c('b-row',{staticClass:"notifyItem--flex"},[(_vm.notify.who)?_c('b-col',{staticClass:"notifyItem__name pr-0"},[_vm._v("\n            "+_vm._s(_vm.notify.who.name.nick)+_vm._s(_vm.notify.who.belongOrg && _vm._.get(_vm.notify.who, 'name.real', '')
                ? ("．" + (_vm.notify.who.name.real || ''))
                : '')+"\n          ")]):_vm._e(),_vm._v(" "),_c('b-col',{staticClass:"notifyItem__message pl-0",attrs:{"cols":"auto"},domProps:{"innerHTML":_vm._s(_vm.message)}})],1)],1),_vm._v(" "),_c('div',{staticClass:"notifyItem__center__bottom align-items-center pb-1",attrs:{"noGutters":""}},[_c('span',{staticClass:"notifyItem__center__bottom__left d-inline-block",class:{ notifyItem__subscribe__bg: _vm.paidNotify }},[_c('i',{staticClass:"notifyItem__icon p-1",class:[
              _vm.icon,
              { notifyItem__subscribe__icon: _vm.paidNotify },
              {
                'notifyItem__subscribe__icon--size':
                  _vm.icon === 'fas fa-bullhorn',
              } ]}),_vm._v(" "),(_vm.paidNotify)?_c('span',{staticClass:"notifyItem__subscribe mr-1"},[_vm._v("\n            訂閱\n          ")]):_vm._e()]),_vm._v(" "),_c('span',{staticClass:"notifyItem__text--small notifyItem__center__bottom__right",class:{ notifyItem__text: _vm.paidNotify }},[(_vm.title)?_c('span',{staticClass:"notifyItem__title"},[_vm._v("\n            "+_vm._s(_vm.$utils.decodeEntities(_vm.title))+"\n          ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"notifyItem__content",attrs:{"noGutters":""}},[_c('span',{staticClass:"notifyItem__content__inside",class:[{ 'notifyItem__content__inside--font': _vm.title }],attrs:{"noGutters":""}},[(_vm.commentOrReply)?_c('span',{staticClass:"notifyItem__content--margin"},[_vm._v("「")]):_vm._e(),_vm._v("\n              "+_vm._s(_vm.$utils.filterAllHtmlTags(_vm.content, _vm.content.length))+"\n              "),(_vm.commentOrReply)?_c('span',[_vm._v("」")]):_vm._e()])])])])],1),_vm._v(" "),_c('b-col',{staticClass:"notifyItem__right pr-1 text-right pt-1",attrs:{"cols":"auto"}},[_c('span',{attrs:{"id":("" + _vm.menuTime + _vm.timeSeconds)}},[_vm._v("\n        "+_vm._s(_vm.timeAgo)+"\n      ")]),_vm._v(" "),_c('b-tooltip',{attrs:{"target":("" + _vm.menuTime + _vm.timeSeconds),"placement":"bottom"}},[_vm._v("\n        "+_vm._s(_vm.time)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"notifyItem__isRead align-items-center",class:[
          {
            notifyItem__isRead__margin:
              _vm.paidNotify ||
              _vm.notify.kind === 'freeActivity' ||
              _vm.notify.kind === 'subscription',
          },
          { 'notifyItem__isRead--isTitle': _vm.title } ],on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleMark($event)}}},[_c('div',{staticClass:"ml-auto",class:[
            { notifyItem__isRead__read: _vm.notify.read },
            { notifyItem__isRead__unread: !_vm.notify.read },
            _vm.notifyClass ]}),_vm._v(" "),_c('div',{staticClass:"pt-1 ml-1"},[_vm._v("\n          "+_vm._s(_vm.notify.read ? '已讀' : '未讀')+"\n        ")])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }