import Vue from 'vue'
import VueMonthlyPicker from 'vue-monthly-picker'

const customizeVueMonthlyPicker = {
  ...VueMonthlyPicker,
  mounted () {
    const wrapper = this.$el.querySelector('.month-picker-wrapper')
    const prevButton = document.createElement('button')
    const nextButton = document.createElement('button')
    prevButton.className = 'st_background_gray_lighter position-absolute h-100 px-3'
    nextButton.className = 'st_background_gray_lighter position-absolute h-100 px-3'
    prevButton.style.border = '1px solid #b8bcc3'
    prevButton.style.top = '0'
    prevButton.style.left = '0'
    nextButton.style.top = '0'
    nextButton.style.right = '0'
    nextButton.style.border = '1px solid #b8bcc3'
    prevButton.innerHTML = '<i class="fas fa-caret-left st_color_gray_light" />'
    nextButton.innerHTML = '<i class="fas fa-caret-right st_color_gray_light" />'
    prevButton.addEventListener('click', () => {
      const newMonth = parseInt(this.month) - 1
      this.month = newMonth.toString()
      if (newMonth < 1) {
        const newYear = parseInt(this.year) - 1
        this.year = newYear.toString()
        this.month = '12'
      }
      this.selectPicker()
    })
    nextButton.addEventListener('click', () => {
      const newMonth = parseInt(this.month) + 1
      this.month = newMonth.toString()
      if (newMonth > 12) {
        const newYear = parseInt(this.year) + 1
        this.year = newYear.toString()
        this.month = '1'
      }
      this.selectPicker()
    })
    wrapper.appendChild(prevButton)
    wrapper.appendChild(nextButton)
  },
}

Vue.component('VueMonthlyPicker', customizeVueMonthlyPicker)
