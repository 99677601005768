import apiUtils from '~/utils/api'

export const actions = {
  getArticle ({}, option) {
    // option = {
    //   identity: <String>,
    //   hidden  : <Boolean>,
    //   drafted : <Boolean>,
    //   scheduling:  <Boolean>,
    //   kind  : <Array>
    //   fields  : <Array>,
    // }
    const { identity } = option
    const hidden = _.isUndefined(option.hidden) ? false : option.hidden
    const drafted = _.isUndefined(option.drafted) ? false : option.drafted
    const scheduling = _.isUndefined(option.scheduling) ? false : option.scheduling
    const kind = option.kind || null
    const fields = option.fields || ['identity', 'channels', 'kind', 'cover', 'link', 'categories.identity', 'categories.name', 'account.identity', 'account.avatar', 'account.name', 'account.serial', 'account.belongOrg', 'account.belongOrgInfo', 'published', 'sticky', 'title', 'summary', 'content', 'useInternalVideo', 'video', 'tags.identity', 'tags.name', 'forcePublic', 'history', 'reactions', 'comments', 'collected']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          value:    identity,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
    }
    if (!_.isNull(hidden)) {
      query.filter.push({
        field:    'hidden',
        value:    hidden,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(drafted)) {
      query.filter.push({
        field:    'drafted',
        value:    drafted,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(scheduling)) {
      query.filter.push({
        field:    'scheduling',
        value:    scheduling,
        operator: '=',
        logic:    0,
      })
    }
    if (kind) {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: _.isArray(kind) ? 'In' : '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/article${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getArticles ({}, option) {
    // option = {
    //   search  : <String>,
    //   identity  : <Array>,
    //   kind    : <String>, all || allWithStockPost|| text || audio || video || stockNews || stockPost
    //   hidden  : <Boolean>,
    //   drafted : <Boolean>,
    //   scheduling:  <Boolean>,
    //   channels: <String> || <Array>, service, all
    //   sticky  : <String>, home
    //   tags    : <Array>,
    //   marketTags : <Array> <String>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const search = option.search || null
    const identity = option.identity || null
    const kind = option.kind || 'all'
    const hidden = _.isUndefined(option.hidden) ? false : option.hidden
    const drafted = _.isUndefined(option.drafted) ? false : option.drafted
    const scheduling = _.isUndefined(option.scheduling) ? false : option.scheduling
    const channels = option.channels || null
    const sticky = option.sticky || null
    const tags = option.tags || null
    const marketTags = option.marketTags || null
    const fields = option.fields || ['identity', 'title']
    // ['identity', 'channels.identity', 'channels.title', 'haveChannel', 'channelsIdentity', 'kind', 'cover', 'categories.identity', 'categories.name', 'account.identity', 'account.avatar',
    // 'account.name', 'account.serial','account.service','account.followed', 'account.articles', 'account.collection', 'published', 'sticky', 'title', 'summary', 'content', 'tags.identity',
    // 'tags.name', 'useInternalVideo', 'video', 'forcePublic', 'read', 'collection','like','view','likes', 'collected']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (
      page - 1)
    const sort = option.sort || {
      published:        -1,
      'status.created': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(search)) {
      query.filter.push({
        field:    'title',
        value:    search,
        operator: 'Like',
        logic:    0,
      })
    }
    if (!_.isNull(identity)) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: 'In',
        logic:    0,
      })
    }
    if (kind === 'allWithStockPost') {
      query.filter.push({
        field:    'kind',
        value:    ['text', 'audio', 'video', 'stockPost', 'news'],
        operator: 'In',
        logic:    0,
      })
    } else if (kind === 'all') {
      query.filter.push({
        field:    'kind',
        value:    ['text', 'audio', 'video'],
        operator: 'In',
        logic:    0,
      })
    } else {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: _.isArray(kind) ? 'In' : '=',
        logic:    0,
      })
    }
    if (!_.isNull(hidden)) {
      query.filter.push({
        field:    'hidden',
        value:    hidden,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(drafted)) {
      query.filter.push({
        field:    'drafted',
        value:    drafted,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(scheduling)) {
      query.filter.push({
        field:    'scheduling',
        value:    scheduling,
        operator: '=',
        logic:    0,
      })
    }
    if (_.isNull(channels)) {
      query.filter.push({
        field:    'channels',
        value:    [],
        operator: '=',
        logic:    0,
      })
    } else if (channels === 'service') {
      query.filter.push({
        field:    'channels',
        value:    [],
        operator: '<>',
        logic:    0,
      })
    } else if (channels !== 'all') {
      query.filter.push({
        field:    'channels',
        value:    channels,
        operator: 'In',
        logic:    0,
      })
    }
    if (!_.isNull(sticky)) {
      query.filter.push({
        field:    `sticky.${sticky}`,
        value:    null,
        operator: '<>',
        logic:    0,
      })
    }
    if (!_.isNull(tags)) {
      query.filter.push({
        field:    'tags',
        value:    tags,
        operator: 'In',
        logic:    0,
      })
    }
    if (!_.isNull(marketTags)) {
      query.filter.push({
        field:    'marketTags',
        value:    marketTags,
        operator: _.isArray(marketTags) ? 'In' : '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/articles${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getArticlesOnlyService ({}, option) {
    // option = {
    //   search  : <String>,
    //   kind    : <String>, all || text || audio || video
    //   hidden  : <Boolean>,
    //   drafted : <Boolean>,
    //   scheduling:  <Boolean>,
    //   channels: <String> || <Array>, service, all
    //   sticky  : <String>, home
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const search = option.search || null
    const kind = option.kind || 'all'
    const hidden = option.hidden || false
    const drafted = option.drafted || false
    const scheduling = option.scheduling || false
    const channels = option.channels || null
    const sticky = option.sticky || null
    const fields = option.fields || ['identity', 'title']
    // ['identity', 'channels.identity', 'channels.title', 'haveChannel', 'channelsIdentity', 'kind', 'cover', 'categories.identity', 'categories.name', 'account.identity', 'account.avatar',
    // 'account.name', 'account.serial','account.service','account.followed', 'account.articles', 'account.collection', 'published', 'sticky', 'title', 'summary', 'content', 'tags.identity',
    // 'tags.name', 'useInternalVideo', 'video', 'forcePublic', 'read', 'collection','like','view','likes', 'collected']
    const page = option.page || 1
    const limit = option.limit || 10
    const offset = limit * (
      page - 1)
    const sort = option.sort || {
      published:        -1,
      'status.created': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'hidden',
          value:    hidden,
          operator: '=',
          logic:    0,
        },
        {
          field:    'drafted',
          value:    drafted,
          operator: '=',
          logic:    0,
        },
        {
          field:    'scheduling',
          value:    scheduling,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(search)) {
      query.filter.push({
        field:    'title',
        value:    search,
        operator: 'Like',
        logic:    0,
      })
    }
    if (kind === 'all') {
      query.filter.push({
        field:    'kind',
        value:    ['text', 'audio', 'video'],
        operator: 'In',
        logic:    0,
      })
    } else {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: '=',
        logic:    0,
      })
    }
    if (_.isNull(channels)) {
      query.filter.push({
        field:    'channels',
        value:    [],
        operator: '=',
        logic:    0,
      })
    } else if (channels === 'service') {
      query.filter.push({
        field:    'channels',
        value:    [],
        operator: '<>',
        logic:    0,
      })
    } else if (channels !== 'all') {
      query.filter.push({
        field:    'channels',
        value:    channels,
        operator: 'In',
        logic:    0,
      })
    }
    if (!_.isNull(sticky)) {
      query.filter.push({
        field:    `sticky.${sticky}`,
        value:    null,
        operator: '<>',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    // &source=stFront for only one account over one article
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/articles/frees${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getArticlesByAccount ({}, option) {
    // option = {
    //   account : <Array>,
    //   kind    : <String>, all || text || audio || video
    //   hidden  : <Boolean>,
    //   drafted : <Boolean>,
    //   scheduling: <Boolean>,
    //   channels: <String> || <Array>, service
    //   tags    : <Array>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const { account } = option
    const search = option.search || null
    const kind = option.kind || 'all'
    const hidden = _.isUndefined(option.hidden) ? false : option.hidden
    const drafted = _.isUndefined(option.drafted) ? false : option.drafted
    const scheduling = _.isUndefined(option.scheduling) ? false : option.scheduling
    const channels = option.channels || null
    const tags = option.tags || null
    const fields = option.fields || ['identity', 'title']
    // ['identity', 'channels.identity', 'channels.title', 'haveChannel', 'channelsIdentity', 'kind', 'cover', 'categories.identity', 'categories.name', 'account.identity', 'account.avatar',
    // 'account.name', 'account.serial','account.service','account.followed', 'account.articles', 'account.collection', 'published', 'sticky', 'title', 'summary', 'content', 'tags.identity',
    // 'tags.name', 'useInternalVideo', 'video', 'forcePublic', 'read', 'collection','like','view','likes', 'collected']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (
      page - 1)
    const sort = option.sort || {
      'sticky.channel': -1,
      published:        -1,
      'status.created': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'account',
          value:    account,
          operator: 'In',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(search)) {
      query.filter.push({
        field:    'title',
        value:    search,
        operator: 'Like',
        logic:    0,
      })
    }
    if (kind === 'all') {
      query.filter.push({
        field:    'kind',
        value:    ['text', 'audio', 'video'],
        operator: 'In',
        logic:    0,
      })
    } else {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(hidden)) {
      query.filter.push({
        field:    'hidden',
        value:    hidden,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(drafted)) {
      query.filter.push({
        field:    'drafted',
        value:    drafted,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(scheduling)) {
      query.filter.push({
        field:    'scheduling',
        value:    scheduling,
        operator: '=',
        logic:    0,
      })
    }
    if (_.isNull(channels)) {
      query.filter.push({
        field:    'channels',
        value:    [],
        operator: '=',
        logic:    0,
      })
    } else if (channels === 'service') {
      query.filter.push({
        field:    'channels',
        value:    [],
        operator: '<>',
        logic:    0,
      })
    } else if (channels !== 'all') {
      query.filter.push({
        field:    'channels',
        value:    channels,
        operator: 'In',
        logic:    0,
      })
    }
    if (!_.isNull(tags)) {
      query.filter.push({
        field:    'tags',
        value:    tags,
        operator: 'In',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/articles${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getArticleTags ({}, option) {
    // use articles query to get tags data.
    // option = {
    //   search  : <String>,
    //   account : <Array>,
    //   identity  : <Array>,
    //   kind    : <String>, all || text || audio || video
    //   hidden  : <Boolean>,
    //   drafted : <Boolean>,
    //   scheduling: <Boolean>,
    //   channels: <String> || <Array>, service, all
    //   sticky  : <String>, home
    //   tags    : <Array>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const search = option.search || null
    const account = option.account || null
    const identity = option.identity || null
    const kind = option.kind || 'all'
    const hidden = option.hidden || false
    const drafted = option.drafted || false
    const scheduling = option.scheduling || false
    const channels = option.channels || null
    const sticky = option.sticky || null
    const tags = option.tags || null
    const fields = option.fields || ['identity', 'name', 'count', 'status.created']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const sort = option.sort || { count: -1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'hidden',
          value:    hidden,
          operator: '=',
          logic:    0,
        },
        {
          field:    'drafted',
          value:    drafted,
          operator: '=',
          logic:    0,
        },
        {
          field:    'scheduling',
          value:    scheduling,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(search)) {
      query.filter.push({
        field:    'title',
        value:    search,
        operator: 'Like',
        logic:    0,
      })
    }
    if (!_.isNull(account)) {
      query.filter.push({
        field:    'account',
        value:    account,
        operator: 'In',
        logic:    0,
      })
    }
    if (!_.isNull(identity)) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: 'In',
        logic:    0,
      })
    }
    if (kind === 'all') {
      query.filter.push({
        field:    'kind',
        value:    ['text', 'audio', 'video'],
        operator: 'In',
        logic:    0,
      })
    } else {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: '=',
        logic:    0,
      })
    }
    if (_.isNull(channels)) {
      query.filter.push({
        field:    'channels',
        value:    [],
        operator: '=',
        logic:    0,
      })
    } else if (channels === 'service') {
      query.filter.push({
        field:    'channels',
        value:    [],
        operator: '<>',
        logic:    0,
      })
    } else if (channels !== 'all') {
      query.filter.push({
        field:    'channels',
        value:    channels,
        operator: 'In',
        logic:    0,
      })
    }
    if (!_.isNull(sticky)) {
      query.filter.push({
        field:    `sticky.${sticky}`,
        value:    null,
        operator: '<>',
        logic:    0,
      })
    }
    if (!_.isNull(tags)) {
      query.filter.push({
        field:    'tags',
        value:    tags,
        operator: 'In',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/article/tags${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createArticle ({}, article) {
    // article = {
    //   kind       : <String>, text || audio || video
    //   title      : <String>,
    //   link       : <Url>,
    //   content    : <String: Html>,
    //   summary    : <String: Longtext>,
    //   marketTags : <Array>,
    //   cover      : <Url>,
    //   tags       : <Array>,
    //   categories : <Array>,
    //   sticky     : <Object>, { home: <Timestamp>, channel: <Timestamp> }
    //   hidden     : <Boolean>,
    //   drafted    : <Boolean>,
    //   forcePublic: <Boolean>,
    //   useInternalVideo : <Boolean>,
    //   video    : <String>,
    //   useMultiVideos : true,
    //   multiVideos    : <Array>,
    //   account    : <String>,
    //   published  : <Number: Timestamp>,
    //   scheduling : <Boolean>,
    //   notify     : <Boolean>,
    // }
    const body = {
      data: {
        type:       'article',
        attributes: article,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/article`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateArticle ({ commit }, article) {
    // article = {
    //   identity  : <String>
    //   kind      : <String>
    //   title     : <String>
    //   link      : <Url>
    //   content   : <String: Html>
    //   summary   : <String: LongText>
    //   marketTags: <Array>,
    //   cover     : <Url>
    //   tags      : <Array>
    //   categories: <Array>
    //   channels  : <Array>
    //   sticky    : <Boolean>
    //   hidden    : <Boolean>
    //   drafted   : <Boolean>
    //   forcePublic   : <Boolean>
    //   useInternalVideo : <Boolean>,
    //   useMultiVideos   : <Boolean>,
    //   useWatermark     : <Boolean>,
    //   video    : <String>,
    //   published : <Number: Date>,
    //   scheduling : <Boolean>,
    //   notify     : <Boolean>,
    // }
    const {
      identity, kind, title, link,
      content, summary, marketTags, cover,
      tags, categories, channels, sticky,
      hidden, drafted, forcePublic,
      useInternalVideo, video, useMultiVideos, multiVideos, useWatermark = false,
      published, scheduling, notify,
    } = article

    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    const body = {
      data: {
        type:       'article',
        id:         identity,
        attributes: {
          kind,
          title,
          link,
          content,
          summary,
          marketTags,
          cover,
          tags,
          categories,
          channels,
          sticky,
          hidden,
          drafted,
          forcePublic,
          useInternalVideo,
          useMultiVideos,
          useWatermark,
          multiVideos,
          video,
          published,
          scheduling,
          notify,
        },
      },
    }

    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/article${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateArticleState ({ commit }, article) {
    // article = {
    //   identity  : <String>
    //   sticky    : <Boolean>
    //   hidden    : <Boolean>
    //   drafted   : <Boolean>
    //   forcePublic   : <Boolean>
    //   scheduling : <Boolean>,
    //   notify     : <Boolean>,
    // }
    const {
      identity, sticky, hidden,
      drafted, forcePublic, scheduling, notify,
    } = article
    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    const body = {
      data: {
        type:       'article',
        id:         identity,
        attributes: {
          sticky,
          hidden,
          drafted,
          forcePublic,
          scheduling,
          notify,
        },
      },
    }
    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/article${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateArticlesWithStickyChannel ({ commit }, option) {
    const { identity, stickyChannel } = option
    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    const body = {
      data: {
        type:       'article',
        id:         identity,
        attributes: {
          'sticky.channel': stickyChannel,
        },
      },
    }
    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/article${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateArticlesWithStickyHome ({ commit }, option) {
    // option = {
    //   identity: <Array>
    // }
    const { identity } = option
    const query = {
      filter: [
        {
          field:    'identity',
          operator: 'In',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    const body = {
      data: {
        type:       'article',
        attributes: {
          'sticky.home': 1,
        },
      },
    }
    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/article/special${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteArticle ({ dispatch }, option) {
    // option = {
    //   identity : <String>,
    // }
    const { identity } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
      // fields: ['identity']
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/article${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
