import apiUtils from '~/utils/api'

export const actions = {
  getCategory ({}, option) {
    // option = {
    //   identity: <String>,
    // }
    const { identity } = option
    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
      fields: ['identity', 'name'],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/category${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getCategoriesByKind ({}, option) {
    // option = {
    //   kind : <String>, article || service
    //   fields : <Array>,
    //   page : <Number>,
    //   limit: <Number>,
    //   sort : <Object>,
    // }
    const { kind } = option
    const fields = option.fields || ['identity', 'name']
    const page = option.page || 1
    const limit = option.limit || 15
    const offset = limit * (
      page - 1)
    const sort = option.sort || { name: 1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      sort:   sort,
      fields: fields,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/categories${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getCategoriesByIdentity ({}, option) {
    // option = {
    //   kind : <Array>,
    //   page : <Number>,
    //   limit: <Number>,
    //   sort : <Object>,
    // }
    const { identity } = option
    const page = option.page || 1
    const limit = option.limit || 15
    const offset = limit * (
      page - 1)
    const sort = option.sort || { name: 1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          operator: 'In',
          value:    identity,
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      sort:   sort,
      fields: ['identity', 'name'],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/categories${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
