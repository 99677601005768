import apiUtils from '~/utils/api'

export const actions = {
  searchChannels ({}, option) {
    // option = {
    //   search: <String>,
    //   fields  : <Array>
    //   page: <Number>,
    //   limit: <Number>,
    // }
    const search = option.search || ''
    const fields = option.fields || ['identity', 'cover', 'title', 'content', 'price', 'unitWord', 'trial', 'autoRenewal', 'expired', 'subscriptions', 'maximum', 'enabled', 'apply', 'hidden', 'outsideUrl', 'offShelf', 'convertAutoRenewalToSingle', 'account.identity', 'account.serial', 'account.name', 'account.avatar', 'account.kind', 'account.organization.kind', 'account.organization.alias', 'account.service', 'account.ownRoles', 'account.belongOrg', 'account.belongOrgInfo', 'categories.identity', 'categories.name']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (
      page - 1
    )
    const sort = option.sort || { price: 1 }

    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'hidden.list',
          value:    true,
          operator: '<>',
          logic:    0,
        },
        {
          field:    'title',
          value:    search,
          operator: 'Like',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/channels${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getChannel ({}, option) {
    // option = {
    //   identity: <String>,
    //   fields  : <Array>
    // }
    const { identity } = option
    const fields = option.fields || ['identity', 'cover', 'title', 'content', 'price', 'unitWord', 'trial', 'autoRenewal', 'expired', 'published', 'subscriptions', 'maximum', 'enabled', 'apply', 'unavaliableBuyOption', 'convertAutoRenewalToSingle', 'hidden', 'outsideUrl', 'offShelf', 'account.identity', 'account.serial', 'account.name', 'account.avatar', 'account.ownRoles', 'account.belongOrg', 'account.belongOrgInfo', 'categories.identity', 'categories.name']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          value:    identity,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
    }

    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/channel${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getChannels ({}, option) {
    // option = {
    //   withDeleted: <Boolean>,
    //   identity: <Array>,
    //   account: <Array>,
    //   fields  : <Array>
    //   page: <Number>,
    //   limit: <Number>,
    // }
    const withDeleted = option.withDeleted || false
    const identity = option.identity || null
    const account = option.account || null
    const fields = option.fields || ['identity', 'cover', 'title', 'content', 'price', 'unitWord', 'trial', 'autoRenewal', 'expired', 'published', 'subscriptions', 'maximum', 'enabled', 'apply', 'hidden', 'convertAutoRenewalToSingle', 'outsideUrl', 'offShelf', 'account.kind', 'account.organization.kind', 'account.organization.alias', 'account.service', 'account.identity', 'account.serial', 'account.name', 'account.avatar', 'account.ownRoles', 'account.belongOrg', 'account.belongOrgInfo', 'categories.identity', 'categories.name']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (
      page - 1
    )
    const sort = option.sort || { price: 1 }

    const checkHiddenList = option.checkHiddenList || null
    const query = {
      filter: [],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!withDeleted) {
      query.filter.push({
        field:    'status.deleted',
        value:    null,
        operator: '=',
        logic:    0,
      })
    }
    if (identity) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: 'In',
        logic:    0,
      })
    } else if (account) {
      query.filter.push({
        field:    'account',
        value:    account,
        operator: 'In',
        logic:    0,
      })
    }
    if (checkHiddenList) {
      query.filter.push({
        field:    'hidden.list',
        value:    true,
        operator: '<>',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/channels${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createChannel ({}, channel) {
    // channel = {
    //   account: <String>,
    //   cover: <Url>,
    //   title: <String>,
    //   content: <Html>,
    //   categories: <Array>,
    //   unitWord: <String>,
    //   price: <Number>,
    //   autoRenewal: <Number>,
    //   expired: <Number>,
    //   enabled: <Boolean>,
    //   convertAutoRenewalToSingle: <Boolean>,
    //   maximum: <Number>,
    //   trial :{
    //     time: <Number>,
    //     price: <Number>
    //   }
    //   unavaliableBuyOption: { // 不允許的付款方式
    //     months: [<Number>], 1 || 3 || 6 || 12
    //     paymentMethod: [<String>], LINEPAY/PREAPPROVED || TAPPAY/PREAPPROVED || ECPAY/CVS || ECPAY/ATM
    //   },
    //   starterInfo: <Html>,
    //   apply: <Number>,
    //   published: <Number>,
    //   hidden: { <Object>
    //     subscriptions: <Boolean>,
    //     list: <Boolean>,
    //   },
    //   outsideUrl: <String>,
    //   offShelf: <Boolean>,
    // }
    const body = {
      data: {
        type:       'channel',
        attributes: channel,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/channel`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateChannel ({}, option) {
    // option = {
    //   identity : <String>,
    //   account: <String>,
    //   cover: <Url>,
    //   title: <String>,
    //   content: <Html>,
    //   categories: <Array>,
    //   unitWord: <String>,
    //   price: <Number>,
    //   autoRenewal: <Number>,
    //   expired: <Number>,
    //   enable: <Boolean>,
    //   convertAutoRenewalToSingle: <Boolean>,
    //   maximum: <Number>,
    //   trial :{
    //     time: <Number>,
    //     price: <Number>
    //   },
    //   unavaliableBuyOption: { // 不允許的付款方式
    //     months: [<Number>], 1 || 3 || 6 || 12
    //     paymentMethod: [<String>], LINEPAY/PREAPPROVED || TAPPAY/PREAPPROVED || ECPAY/CVS || ECPAY/ATM
    //   },
    //   starterInfo: <Html>,
    //   apply: <Number>,
    //   published: <Number>,
    //   hidden: { <Object>
    //     subscriptions: <Boolean>,
    //     list: <Boolean>,
    //   },
    //   outsideUrl: <String>,
    //   offShelf: <Boolean>,
    // }
    const {
      identity, account, cover, title, content, price,
      unitWord, autoRenewal, expired, enabled, maximum,
      trial, apply, unavaliableBuyOption, published, hidden,
      convertAutoRenewalToSingle, categories, starterInfo,
      outsideUrl, offShelf,
    } = option

    const body = {
      data: {
        type:       'channel',
        id:         identity,
        attributes: {
          account,
          cover,
          title,
          content,
          unitWord,
          price,
          autoRenewal,
          expired,
          enabled,
          maximum,
          trial,
          apply,
          unavaliableBuyOption,
          published,
          hidden,
          convertAutoRenewalToSingle,
          categories,
          starterInfo,
          outsideUrl,
          offShelf,
        },
      },
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    option.identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/channel${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteChannel ({}, option) {
    // option = {
    //   identity : <String>
    // }
    const { identity } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
      fields: ['identity'],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/channel${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
