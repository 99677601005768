<template>
  <ul class="menu" v-if="items.length">
    <template v-for="(item,index) in items">
      <li v-if="!item.role || (item.role && item.role==user.role)" :key="index" :class="(item.class?item.class+' ':'')+'leaf-'+index">
        <div :class="{footer:isFooter}">
          <span v-if="item.icon">
            <img :src="item.icon.src">
          </span>
          <nuxt-link v-if="item.link" :to="item.link">
            {{ item.title }}
          </nuxt-link>
          <span v-else>
            {{ item.title }}
          </span>
          <rec-menu v-if="item.submenu" :items="item.submenu" />
        </div>
      </li>
    </template>
  </ul>
</template>
<script>
  export default {
    name:  'RecMenu',
    props: {
      items: {
        type:    Array,
        default: () => [],
      },
      isFooter: {
        type:    Boolean,
        default: false,
      },
    },
    data () {
      return {
        user: { role: [0] },
      }
    },
  }
</script>

<style lang="scss" scoped>
  li {
    display: inline-block;
    padding: 0 10px;

    @important {
      color: white;
    }

    .footer {
      a {
        opacity: 0.5;

        @important {
          color: white;
        }
      }
    }

    + li {
      position: relative;

      &:before {
        content: '|';
        display: inline-block;
        left: -2px;
        position: absolute;
        font-size: 14px;
      }
    }
  }
</style>
