import apiUtils from '~/utils/api'

export const actions = {
  getAccount ({ commit }, option) {
    // option = {
    //   kind: <String>, 'personal' or 'organization'
    //   serial: <String>,
    //   organizationAlias: <String>,
    //   fields: <Array>
    // }
    const serial = Number(option.serial) || null
    const organizationAlias = option.organizationAlias || (_.isNaN(Number(option.serial)) && option.serial) || null
    const kind = option.kind || null
    const { identity } = option
    const fields = option.fields || [
      'identity',
      'kind',
      'serial',
      'organization',
      'roles',
      'avatar',
      'name',
      'service',
      'followed',
      'followTo',
      'collections',
      'subscriptions',
      'firstTimeShowLineNotify',
      'ownRoles',
      'blockList',
      'belongOrg',
      'belongOrgInfo',
    ]
    // ref: let fields = option.fields || ['identity', 'kind', 'serial', 'avatar', 'share', 'name', 'name.real', 'birthday', 'description', 'email', 'phone', 'notify.line', 'service.approved', 'articles.identity', 'followed', 'followTo', 'collections', 'subscriptions']
    const query = {
      filter: [],
      fields: fields,
    }
    if (serial) {
      query.filter.push({
        field:    'serial',
        value:    serial,
        operator: '=',
        logic:    0,
      })
    } else if (organizationAlias) {
      query.filter.push({
        field:    'organization.alias',
        value:    organizationAlias,
        operator: '=',
        logic:    0,
      })
    } else {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: '=',
        logic:    0,
      })
    }
    if (kind) {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios
      .get(`${this.state.setting.apiUrlSocial}/account${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getAccountsByIdentity ({ commit }, option) {
    // option = {
    //   identity: <Array>,
    //   page  : <Number>,
    //   limit : <Number>.
    //   fields: <Array>
    // }
    const { identity } = option
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const fields = option.fields || [
      'identity',
      'kind',
      'serial',
      'organization',
      'avatar',
      'name',
      'service',
      'followed',
      'followTo',
      'collections',
      'subscriptions',
      'ownRoles',
      'blockList',
      'belongOrg',
      'belongOrgInfo',
    ]
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          value:    identity,
          operator: 'In',
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      fields: fields,
      sort:   { 'status.created': 1 },
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios
      .get(`${this.state.setting.apiUrlSocial}/accounts${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getAccounts ({ commit }, option) {
    // option = {
    //   kind: <String>,
    //   identity: <Array>,
    //   ownRolesField: <String>,
    //   ownRolesId: <String>,
    //   page  : <Number>,
    //   limit : <Number>.
    //   fields: <Array>
    // }
    const {
      kind, identity, ownRolesField, ownRolesId,
    } = option
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const fields = option.fields || [
      'identity',
      'kind',
      'serial',
      'organization.kind',
      'organization.alias',
      'organization.owner',
      'avatar',
      'name',
      'service',
      'ownRoles',
      'belongOrg',
      'belongOrgInfo',
    ]
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      fields: fields,
      sort:   { 'status.created': 1 },
    }
    if (kind) {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: _.isArray(kind) ? 'In' : '=',
        logic:    0,
      })
    }
    if (identity) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: _.isArray(identity) ? 'In' : '=',
        logic:    0,
      })
    }
    if (ownRolesField) {
      query.filter.push({
        field:    'ownRolesField',
        value:    ownRolesField,
        operator: '=',
        logic:    0,
      })
    }
    if (ownRolesId) {
      query.filter.push({
        field:    'ownRolesId',
        value:    ownRolesId,
        operator: '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios
      .get(`${this.state.setting.apiUrlSocial}/accounts${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateAccount ({ commit }, account) {
    // account = {
    //   identity   : <String>,
    //   avatar     : <Url>,
    //   name       : { <Object>
    //     nick: <String>,
    //     real: <String>
    //   },
    //   birthday   : <Number: Date>,
    //   description: <String: LongText>,
    //   email      : <String: Email>,
    //   phone      : <String>,
    //   notify     : { <Object>
    //     line: <Boolean>
    //   },
    //   styles     : <Array>,
    //   lastKYC    : <Number: Timestamp>,
    //   firstTimeShowLineNotify    : <Boolean>,
    //   ownRoles     : { <Object>
    //     admin: <Array by account identity>,
    //     contentFreeCreator: <Array by account identity>,
    //     contentFreeEditor: <Array by account identity>,
    //     contentPaidCreator: <Array by account identity>,
    //     contentPaidEditor: <Array by account identity>,
    //     contentPaidViewer: <Array by account identity>,
    //     contentComment: <Array by account identity>,
    //     contentAdmin: <Array by account identity>,
    //     accountant: <Array by account identity>,
    //     userList: <Array by account identity>,
    //   },
    //   blockList     : <Array by account identity>,
    //   belongOrg     : <account identity>,
    // }

    const {
      identity,
      avatar,
      name,
      birthday,
      description,
      email,
      phone,
      notify,
      experience,
      interests,
      investmentBudget,
      tradingFrequency,
      styles,
      lastKYC,
      firstTimeShowKYC,
      firstTimeShowLineNotify,
      ownRoles,
      blockList,
      belongOrg,
    } = account

    // axios will use JSON.stringify to remove value undefined on data.attributes before request
    // So don't worry about data.attributes.xxx is undefined will send by request.
    const body = {
      data: {
        type:       'account',
        id:         identity,
        attributes: {
          avatar,
          name,
          birthday,
          description,
          email,
          phone,
          notify,
          interests,
          experience,
          investmentBudget,
          tradingFrequency,
          styles,
          lastKYC,
          firstTimeShowKYC,
          firstTimeShowLineNotify,
          ownRoles,
          blockList,
          belongOrg,
          'organization.title':         account['organization.title'],
          'organization.categories':    account['organization.categories'],
          'organization.description':   account['organization.description'],
          'organization.link.facebook': account['organization.link.facebook'],
          'organization.link.line':     account['organization.link.line'],
          'organization.link.youtube':  account['organization.link.youtube'],
          'organization.link.web':      account['organization.link.web'],
          'organization.content':       account['organization.content'],
          'organization.cover':         account['organization.cover'],
          'organization.alias':         account['organization.alias'],
          'organization.kind':          account['organization.kind'],
          'organization.owner':         account['organization.owner'],
        },
      },
    }
    return this.$axios
      .patch(`${this.state.setting.apiUrlSocial}/account`, body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  searchAccounts ({ commit }, option) {
    // option = {
    //   search: <String>,
    //   page  : <Number>,
    //   limit : <Number>.
    //   fields: <Array>
    // }
    const search = option.search || ''
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const fields = option.fields || [
      'identity',
      'kind',
      'serial',
      'organization',
      'avatar',
      'name',
      'service',
      'followed',
      'followTo',
      'collections',
      'subscriptions',
      'ownRoles',
      'blockList',
      'belongOrg',
      'belongOrgInfo',
    ]
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'name.nick',
          value:    search,
          operator: 'Like',
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      fields: fields,
      sort:   { 'status.created': 1 },
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios
      .get(`${this.state.setting.apiUrlSocial}/accounts${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
