<template>
  <img
    class="rounded-circle" v-bind="$attrs"
    :src="userAvatar || defaultAvatar" alt="avatar"
    v-observe-visibility="observeVisibility" :data-disablevviewer="true">
</template>

<script>
  import avatar from '~/static/images/png_icon/mdpi/icon_profile_default.png'

  export default {
    name:  'ScanTraderAvatar',
    props: {
      src: {
        type:    String,
        default: null,
      },
      lazy: {
        type:    Boolean,
        default: false,
      },
      offset: {
        type:    Number,
        default: 160,
      },
    },
    data () {
      return {
        defaultAvatar:     avatar,
        userAvatar:        '',
        observeVisibility: false,
      }
    },
    watch: {
      src: 'visibilityChanged',
    },
    methods: {
      visibilityChanged (isVisible) {
        this.$emit('isVisible', !!isVisible)
        if (!isVisible || _.isEmpty(this.src)) return
        const img = new Image()
        img.src = this.src
        img.onload = e => {
          if (img.complete) {
            this.userAvatar = this.src
            this.$emit('load', e)
          }
        }
        img.onerror = e => {
          this.userAvatar = ''
          this.$emit('error', e)
        }
      },
    },
    mounted () {
      if (this.lazy) {
        this.observeVisibility = {
          callback:     this.visibilityChanged,
          intersection: {
            rootMargin: `0px 0px ${this.offset}px 0px`,
          },
          once: true,
        }
      } else {
        this.visibilityChanged(true)
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
