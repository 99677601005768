<template>
  <section class="pt-md-3">
    <b-container class="not-found">
      <b-row class="mb-5">
        <b-col
          class="text-center"
          md="6">
          <h1>404</h1>
        </b-col>

        <b-col md="6">
          <h3>PAGE NOT FOUND</h3>
          <h5 class="mb-3">
            Sorry we can’t flind that page
          </h5>

          <b-link
            class="mb-3"
            @click.prevent="$router.go(-1)">
            返回上一頁
          </b-link>
          <b-link :to="{ name:'index' }">
            回到網站首頁
          </b-link>
        </b-col>
      </b-row>

      <b-row>
        <p>提醒您可能是因為輸入錯誤的網址、該網頁已被移除、過期、被移動或者不存在如果錯誤問題持續發生，請您聯絡我們。</p>
      </b-row>
    </b-container>
  </section>
</template>

<script>
  export default {
    props: {
      error: {
        type:    [String, Object],
        default: null,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .not-found {
    padding-top: 130px;
    padding-bottom: 75px;
    background-color: $st_color_white;

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    .row {
      max-width: 600px;
      margin: 0 auto;
    }

    h1 {
      font-size: 80px;
      color: $st_color_main;
    }

    h3 {
      font-size: $st_font-size-biggest;
      color: $st_color_main;
    }

    h5 {
      font-size: $st_font-size-small;
      color: $st_color_main;
      font-weight: $st_font-weight-light;
    }

    a {
      font-size: $st_font-size-small;
      display: block;
    }

    p {
      font-size: $st_font-size-small;
      color: $st_color_gray;
    }
  }
</style>
