<template>
  <div class="navigation position-relative">
    <span
      :class="{
        'position-absolute': !$userCheck.is('organization'),
        'px-3 py-2 st_background_white d-block': $userCheck.is('organization')
      }"
      class="navigation__avatar">
      <nuxt-link
        :to="$userRoute({ name:'u-uid' })">
        <ScanTraderAvatar width="40" height="40" :src="user.user_info.avatar" />
      </nuxt-link>
    </span>
    <md-tabs
      v-if="!$userCheck.is('organization')"
      :md-active-tab="$userRouteActive('-follow-followed') ? 'tab-followed' : 'tab-following'"
      md-alignment="centered"
      md-dynamic-height
      class="st-tab-bar navigation__tabs flex-fill">
      <md-tab
        :to="$userRoute({ name:'u-uid-follow-followed' })"
        id="tab-followed"
        md-label="粉絲" />
      <md-tab
        :to="$userRoute({ name:'u-uid-follow-followTo' })"
        id="tab-following"
        md-label="關注" />
    </md-tabs>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters(['user']),
    },
  }
</script>

<style lang="scss" scoped>
  .navigation {
    z-index: 10;
    border-bottom: 1px solid $st_color_gray_light;

    &__avatar {
      left: 15px;
      top: 8px;
      z-index: 1;
    }

    &__tabs {
      /deep/ {
        .md-tabs-navigation {
          padding-left: 16px;

          .md-button {
            height: 55px;

            &:first-of-type {
              margin-right: 20px;
            }

            &.md-theme-default {
              border: none;
              color: $st_color_gray_dark;

              &.md-active {
                color: $st_color_main;
              }
            }

            .md-ripple {
              padding: 0 32px;
              font-size: $st_font-size-big;
              font-weight: normal;
            }
          }

          .md-tabs-indicator.md-theme-default {
            background-color: $st_color_main_light;
            height: 4px;
          }
        }
      }
    }
  }
</style>
