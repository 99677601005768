import apiUtils from '~/utils/api'

export const actions = {
  getNotifiesByAccount ({}, option) {
    // option = {
    //   account         : <String>,
    //   identity        : <String>,
    //   identityOperator: <operator>,
    //   hidden          : <Boolean>,
    //   fields          : <Array>,
    //   page            : <Number>,
    //   limit           : <Number>,
    //   sort            : <Object>,
    // }
    const { account } = option
    const identity = option.identity || null
    const identityOperator = option.identityOperator || '>'
    const hidden = option.hidden || false
    const read = _.isBoolean(option.read) ? option.read : null
    const fields = option.fields || ['identity', 'account', 'kind', 'who', 'target', 'parent', 'comment', 'reply', 'text', 'hidden', 'read', 'status']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (
      page - 1)
    const sort = option.sort || { 'status.created': -1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'account',
          value:    account,
          operator: 'In',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (identity) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: identityOperator,
        logic:    0,
      })
    }
    if (_.isBoolean(hidden)) {
      query.filter.push({
        field:    'hidden',
        value:    null,
        operator: hidden ? '<>' : '=',
        logic:    0,
      })
    }
    if (_.isBoolean(read)) {
      query.filter.push({
        field:    'read',
        value:    null,
        operator: read ? '<>' : '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/notifies${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateNotify ({}, option) {
    // option = {
    //   identity : <String>,
    //   hidden   : <Boolean>,
    //   read     : <Boolean>,
    // }
    const { identity } = option
    const hidden = option.hidden || false
    const read = _.isUndefined(option.read) || option.read

    const body = {
      data: {
        type:       'notify',
        id:         identity,
        attributes: {
          hidden: hidden ? new Date().valueOf() : null,
          read:   read ? new Date().valueOf() : null,
        },
      },
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    option.identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/notify${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
