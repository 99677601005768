import apiUtils from '~/utils/api'

export const actions = {
  getCoupon ({}, option) {
    // option = {
    //   identity: <String>,
    //   fields  : <Array>
    // }
    const identity = option.identity || null
    const fields = option.fields || ['identity', 'usedAt', 'inputCode', 'couponType.title', 'couponType.content', 'couponType.discount', 'account.serial', 'account.identity', 'status.created']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
      fields: fields,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/coupon${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  searchCouponByCode ({}, option) {
    // option = {
    //   search: <String>,
    //   fields  : <Array>
    // }
    const { search } = option
    const fields = option.fields || ['identity', 'inputCode', 'couponType.title', 'couponType.identity']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
        {
          field:    'inputCode.type',
          operator: '=',
          value:    'random',
          logic:    0,
        },
        {
          field:    'inputCode.value',
          operator: '=',
          value:    search,
          logic:    0,
        },
      ],
      fields: fields,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/coupon${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getCoupons ({}, option) {
    // option = {
    //   identity: <String> <Array>,
    //   couponType: <String> <Array>,
    //   reCreatedId: <Boolean>,
    //   usedAt: <Boolean>,
    //   search: <String>,
    //   fields  : <Array>
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const identity = option.identity || null
    const couponType = option.couponType || null
    const reCreatedId = _.isUndefined(option.reCreatedId) ? null : option.reCreatedId
    const usedAt = _.isUndefined(option.usedAt) ? null : option.usedAt
    const lockedUntil = _.isUndefined(option.lockedUntil) ? null : option.lockedUntil
    const lockedUntilOperator = option.lockedUntilOperator || '>='
    const account = _.isUndefined(option.account) ? null : option.account
    const search = option.search || null
    const fields = option.fields || ['identity', 'couponType.identity', 'account.serial', 'account.name', 'inputCode', 'kind', 'index', 'orderNumber', 'discount', 'lockedUntil', 'usedAt', 'bound', 'reCreatedId', 'usePeriod', 'whereConditions', 'status.created', 'status.updated']
    const page = option.page || 1
    const limit = option.limit || 50
    const offset = limit * (page - 1)
    const sort = option.sort || {
      'status.updated': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (identity) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: _.isArray(identity) ? 'In' : '=',
        logic:    0,
      })
    }
    if (couponType) {
      query.filter.push({
        field:    'couponType',
        value:    couponType,
        operator: _.isArray(couponType) ? 'In' : '=',
        logic:    0,
      })
    }
    if (search) {
      query.filter.push({
        field:    'inputCode.type',
        operator: '=',
        value:    'random',
        logic:    0,
      })
      query.filter.push({
        field:    'inputCode.value',
        operator: '=',
        value:    search,
        logic:    0,
      })
    }
    if (!_.isNull(reCreatedId)) {
      const reCreatedIdOperator = _.isArray(reCreatedId) ? 'In' : '='
      query.filter.push({
        field:    'reCreatedId',
        value:    _.isBoolean(reCreatedId) ? null : reCreatedId,
        operator: _.isBoolean(reCreatedId) && reCreatedId === true ? '<>' : reCreatedIdOperator,
        logic:    0,
      })
    }
    if (!_.isNull(usedAt)) {
      const usedAtOperator = _.isArray(usedAt) ? 'In' : '='
      query.filter.push({
        field:    'usedAt',
        value:    _.isBoolean(usedAt) ? null : usedAt,
        operator: _.isBoolean(usedAt) && usedAt === true ? '<>' : usedAtOperator,
        logic:    0,
      })
    }
    if (!_.isNull(lockedUntil)) {
      if (lockedUntil === 'now') {
        query.filter.push({
          field:    'lockedUntil',
          value:    new Date().valueOf(),
          operator: lockedUntilOperator,
          logic:    0,
        })
      } else {
        query.filter.push({
          field:    'lockedUntil',
          value:    lockedUntil,
          operator: lockedUntilOperator,
          logic:    0,
        })
      }
    }
    if (!_.isNull(account)) {
      const accountOperator = _.isArray(account) ? 'In' : '='
      query.filter.push({
        field:    'account',
        value:    _.isBoolean(account) ? null : account,
        operator: _.isBoolean(account) && account === true ? '<>' : accountOperator,
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/coupons${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  assignCoupon ({ commit }, option) {
    const { identity, account } = option
    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    const body = {
      data: {
        type:       'coupon',
        id:         identity,
        attributes: {
          account,
        },
      },
    }

    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/coupon/assign${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createCoupon ({ commit }, option) {
    const body = {
      data: {
        type:       'couponType',
        attributes: {
          ...option,
        },
      },
    }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/couponType`, body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  defrostCoupon ({ commit }, identity) {
    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const body = {
      data: {
        type:       'coupon',
        attributes: {
          lockedUntil: 0,
        },
      },
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/coupon${params}`, body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  releaseCoupon ({ commit }, identity) {
    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)

    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/coupon/release${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
