<template>
  <b-navbar-nav class="main-menu">
    <li class="nav-item d-block d-lg-inline-block">
      <V3Link link="/" class="nav-link">
        研究室
      </V3Link>
    </li>
    <li class="nav-item d-block d-lg-inline-block">
      <V3Link link="/college" class="nav-link">
        微股力學院
      </V3Link>
    </li>
    <b-nav-item
      :active="$route.name == item.link.name"
      v-for="(item, index) in items"
      :key="index"
      class="d-block d-lg-inline-block"
      :to="'href' in item.link ? undefined : item.link"
      :href="item.link.href"
      :target="item.link.target">
      {{ item.title }}
    </b-nav-item>

    <li class="nav-item d-block d-lg-inline-block">
      <V2Link link="/news-timeline" class="nav-link">
        <span class="st_background_sub align-middle badge-new-feature font-weight-bold d-inline-block">NEW</span>
        快訊
      </V2Link>
    </li>
  </b-navbar-nav>
</template>

<script>
  import { mapGetters } from 'vuex'
  import V2Link from '@/components/V2Link'
  import V3Link from '@/components/V3Link'

  export default {
    components: { V2Link, V3Link },
    data () {
      return {}
    },
    computed: {
      ...mapGetters(['user']),
      items () {
        return [
          // {
          //   title: '探索微股力',
          //   link:  { name: 'explore-type' },
          // },
          {
            title: '我的訂閱',
            link:  { name: 'my-subscriptions' },
          },
        // {
        //   title: '動態消息',
        //   link:  { name: 'my-activity' },
        // },
        ]
      },
    },
  }
</script>

<style lang="scss" scoped>
.main-menu {
  text-align: right;

  .badge-new-feature {
    border-radius: 3px;
    padding: 0.25rem;
    font-size: 10px;
    letter-spacing: 0.025em;
    line-height: 1;
  }

  li.nav-item {
    display: inline-block;
    margin: 0;

    a.nav-link {
      color: $st_color_white;
      font-weight: 100;

      &.active {
        font-weight: 400;
      }
    }

    &.st_color_sub_light__a {
      a.nav-link {
        color: $st_color_sub_light;
      }
    }
  }
}
</style>
