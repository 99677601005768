import apiUtils from '~/utils/api'

export const actions = {
  getAdvertisement ({}, option) {
    // option = {
    //   identity       : <String>,
    //   kind           : <String>,  indexSlide || indexBanner || articleBanner
    //   start          : <Number>, || 'nowTimestamp'
    //   startOperator  : <operator>,
    //   end            : <Number>, || 'nowTimestamp'
    //   endOperator    : <operator>,
    //   random         : <Boolean>, 隨機排序
    // }
    const identity = option.identity || null
    const { kind } = option
    const start = option.start || null
    const startOperator = option.startOperator || '<='
    const end = option.end || null
    const endOperator = option.endOperator || '>='
    const fields = option.fields || ['identity', 'kind', 'title', 'text', 'image', 'isInternalLink', 'link', 'start', 'end', 'order', 'providerName', 'limitedByServiceAccounts', 'status']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: fields,
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
    } else {
      query.filter.push(
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
      )
      if (start) {
        query.filter.push({
          field:    'start',
          value:    start,
          operator: startOperator,
          logic:    0,
        })
      }
      if (end) {
        query.filter.push({
          field:    'end',
          value:    end,
          operator: endOperator,
          logic:    0,
        })
      }
      if (option.random) {
        query.sort = { random: 1 }
      }
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/promote${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getAdvertisements ({}, option) {
    // option = {
    //   kind           : <String> <Array>,  indexSlide || indexBanner || articleBanner
    //   start          : <Number>, || 'nowTimestamp'
    //   startOperator  : <operator>,
    //   end            : <Number>, || 'nowTimestamp'
    //   endOperator    : <operator>,
    //   fields          : <Array>,
    //   page           : <Number>,
    //   limit          : <Number>,
    //   sort           : <Object>,
    //   random         : <Boolean>, 隨機排序
    // }
    const kind = option.kind || null
    const start = option.start || null
    const startOperator = option.startOperator || '<='
    const end = option.end || null
    const endOperator = option.endOperator || '>='
    const fields = option.fields || ['identity', 'kind', 'title', 'text', 'image', 'isInternalLink', 'link', 'start', 'end', 'order', 'providerName', 'limitedByServiceAccounts', 'status']
    const page = option.page || 1
    const limit = option.limit || 50
    const offset = limit * (page - 1)
    const sort = option.sort || { 'status.updated': -1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      sort:   sort,
      fields: fields,
    }
    if (kind) {
      query.filter.push(
        {
          field:    'kind',
          operator: _.isArray(kind) ? 'In' : '=',
          value:    kind,
          logic:    0,
        },
      )
    }
    if (start) {
      query.filter.push({
        field:    'start',
        value:    start,
        operator: startOperator,
        logic:    0,
      })
    }
    if (end) {
      query.filter.push({
        field:    'end',
        value:    end,
        operator: endOperator,
        logic:    0,
      })
    }
    if (option.random) {
      query.sort = { random: 1 }
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/promotes${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createAdvertisement ({}, advertisement) {
    // advertisement = {
    //   kind              : <String>,  indexSlide 首頁輪播圖 || indexBanner 首頁右下角廣告圖 || articleBanner 文章下方原生管告
    //   title             : <String>, || null   目前僅 kind=indexSlide 使用
    //   text              : <String>, || null   目前僅 kind=indexSlide 使用
    //   image             : <Url>,
    //   isInternalLink    : <Boolean>,   是否為網頁內部連結，是的話不會開啟新視窗
    //   link              : <Url>,
    //   start             : <Number: Timestamp>,
    //   end               : <Number: Timestamp>,
    //   order             : <Number:>,
    //   providerName      : <String>,  廣告提供者名稱，目前僅 kind=articleBanner 使用
    //   limitedByServiceAccounts: <Array String>,   限制只出現某達人的文章內，輸入帳號的serial，目前僅 kind=articleBanner 有使用
    // }
    const body = {
      data: {
        type:       'advertisement',
        attributes: advertisement,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/promote`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateAdvertisement ({}, option) {
    // option = {
    //   identity          : <String>,
    //   kind              : <String>,  indexSlide 首頁輪播圖 || indexBanner 首頁右下角廣告圖 || articleBanner 文章下方原生管告
    //   title             : <String>, || null   目前僅 kind=indexSlide 使用
    //   text              : <String>, || null   目前僅 kind=indexSlide 使用
    //   image             : <Url>,
    //   isInternalLink    : <Boolean>,   是否為網頁內部連結，是的話不會開啟新視窗
    //   link              : <Url>,
    //   start             : <Number: Timestamp>,
    //   end               : <Number: Timestamp>,
    //   order             : <Number:>,
    //   providerName      : <String>,  廣告提供者名稱，目前僅 kind=articleBanner 使用
    //   limitedByServiceAccounts: <Array String>,   限制只出現某達人的文章內，輸入帳號的serial，目前僅 kind=articleBanner 有使用
    // }
    const {
      identity, kind, title, text, image,
      isInternalLink, link, start, end, order,
      providerName, limitedByServiceAccounts,
    } = option

    const body = {
      data: {
        type:       'advertisement',
        id:         identity,
        attributes: {
          kind,
          title,
          text,
          image,
          isInternalLink,
          link,
          start,
          end,
          order,
          providerName,
          limitedByServiceAccounts,
        },
      },
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/promote${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteAdvertisement ({}, option) {
    // option = {
    //   identity : <String>,
    // }
    const { identity } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
      fields: ['identity'],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/promote${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
