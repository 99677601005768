import '~/node_modules/vue-material/dist/theme/default.css'
import '~/node_modules/vue-material/dist/vue-material.min.css'
import Vue from 'vue'
import VueMaterial from 'vue-material'

// https://github.com/vuematerial/vue-material/issues/1526#issuecomment-368615441
// Polyfills required for vue-material.

// Object.values
const reduce = Function.bind.call(Function.call, Array.prototype.reduce)
const isEnumerable = Function.bind.call(Function.call, Object.prototype.propertyIsEnumerable)
const concat = Function.bind.call(Function.call, Array.prototype.concat)

if (!Object.values) {
  Object.values = function values (O) {
    return reduce(Object.getOwnPropertyNames(O), (v, k) => concat(v, typeof k === 'string' && isEnumerable(O, k) ? [O[k]] : []), [])
  }
}

// Array.includes()
// if (!Array.prototype.includes) {
//   Object.defineProperty(Array.prototype, 'includes', {
//     value: function(searchElement, fromIndex) {
//       if (this == null) {
//         throw new TypeError('"this" is null or not defined')
//       }

//       const o = Object(this)
//       const len = o.length >>> 0
//       if (len === 0) {
//         return false
//       }
//       const n = fromIndex | 0
//       let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0)

//       function sameValueZero (x, y) {
//         return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y))
//       }
//       while (k < len) {
//         if (sameValueZero(o[k], searchElement)) {
//           return true
//         }
//         k++
//       }
//       return false
//     },
//   })
// }

Vue.use(VueMaterial)
