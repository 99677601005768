<template>
  <div id="page-header" class="top-bar st_background_main">
    <!--mobile-->
    <b-navbar type="dark" fixed="top" class="menu">
      <b-collapse id="nav_collapse-mobile" isNav class="nav-collapse--mobile nav-collapse">
        <template v-if="!view.isSearching">
          <div class="col flex-fill p-0">
            <nuxt-link :to="{ name: 'index' }">
              <img
                height="31"
                src="~/static/images/png_icon/mdpi/nav_logo_quants_white.png"
                srcset="
                  ~/static/images/png_icon/xhdpi/nav_logo_quants_white.png  2x,
                  ~/static/images/png_icon/xxhdpi/nav_logo_quants_white.png 3x
                ">
            </nuxt-link>
          </div>
          <div class="col flex-fill text-right p-0">
            <b-row class="row align-items-center justify-content-end">
              <!-- login icons -->
              <template v-if="$userCheck.isLogin()">
                <b-col cols="3" class="text-right">
                  <span @click.prevent="onSearch" class="icon-search">
                    <i class="fas fa-search align-middle" />
                  </span>
                </b-col>
                <b-col cols="3">
                  <V2Link link="/news-timeline" class="link-white">
                    <img class="align-middle icon-activity" src="/images/svg_icon/icon-clipboard.svg" alt="">
                  </V2Link>
                </b-col>
                <b-col cols="3">
                  <nuxt-link :to="{ name: 'my-notify' }" class="notifyButton link-white">
                    <i
                      class="fa fa-bell icon-bell align-middle"
                      :class="{ active: user.notifyCount > 0 || $route.name === 'my-notify' }" />
                    <client-only>
                      <div class="notifyCount" v-if="user.notifyCount">
                        {{ formatNotifyCount(user.notifyCount) }}
                      </div>
                    </client-only>
                  </nuxt-link>
                </b-col>
                <b-col cols="3">
                  <button
                    class="btn-no-style"
                    :class="{ active: view.isShowMenu }"
                    @click.prevent="view.isShowMenu = !view.isShowMenu">
                    <i class="fas align-middle fa-bars" style="font-size: 24px;" />
                  </button>
                </b-col>
              </template>
              <!-- not login -->
              <template v-if="!$userCheck.isLogin()">
                <b-col cols="3" class="text-right">
                  <span @click.prevent="onSearch" class="icon-search">
                    <svg-icon name="icon_search_white" :width="24" :height="24" />
                  </span>
                </b-col>

                <b-col cols="3" class="text-center">
                  <V2Link link="/news-timeline" class="text-white">
                    <img class="align-middle icon-activity" src="/images/svg_icon/icon-clipboard.svg" alt="">
                  </V2Link>
                </b-col>
                <div class="px-3 text-right">
                  <a class="st_color_white" :href="user.loginUrl"> 登入 </a>
                </div>
              </template>
            </b-row>
          </div>
        </template>

        <!-- mobile search mode -->
        <template v-if="view.isSearching">
          <div class="col flex-fill p-0">
            <SearchForm id="search--mobile" />
          </div>
          <div class="col col-auto text-right p-0">
            <b-button class="btn-pure mobile-text-close" @click.prevent="onSearch">
              取消
            </b-button>
          </div>
        </template>
      </b-collapse>
    </b-navbar>

    <!-- desktop -->
    <b-navbar toggleable="md" type="dark" fixed="top" class="menu">
      <b-collapse isNav id="nav_collapse" class="container nav-collapse">
        <div id="logo" class="col-auto pl-0">
          <nuxt-link :to="{ name: 'index' }">
            <img
              height="31"
              src="~/static/images/png_icon/mdpi/nav_logo_quants_white.png"
              srcset="
                ~/static/images/png_icon/xhdpi/nav_logo_quants_white.png  2x,
                ~/static/images/png_icon/xxhdpi/nav_logo_quants_white.png 3x
              "
              alt="logo">
          </nuxt-link>
        </div>
        <SearchForm id="search" class="col-lg-3 col-xl-3 p-0 mr-auto" />
        <MainMenu id="MainMenu" class="col d-none d-lg-block p-0 ml-auto" v-if="$userCheck.isLogin()" />
        <NotifyMenu id="NotifyMenu" class="col-auto p-0" v-if="$userCheck.isLogin()" />
        <UserMenu id="UserMenu" class="col-auto p-0" v-if="$userCheck.isLogin()" />
        <div class="col flex-grow-0 d-flex align-items-center text-nowrap" v-else>
          <!-- <nuxt-link
            class="py-0 px-3 fw-300 st_color_white d-inline-block no-hover"
            :to="{ name: 'explore-type' }">
            探索微股力
          </nuxt-link> -->
          <V3Link class="py-0 px-3 fw-300 st_color_white border-right st_border_white d-inline-block no-hover" link="/">
            研究室
          </V3Link>
          <V3Link
            class="py-0 px-3 fw-300 st_color_white border-right st_border_white d-inline-block no-hover"
            link="/college">
            微股力學院
          </V3Link>
          <V2Link
            class="py-0 px-3 fw-300 st_color_white border-right st_border_white d-inline-block no-hover"
            link="/news-timeline">
            <span class="st_background_sub badge-new-feature align-middle font-weight-bold">NEW</span>
            快訊
          </V2Link>
          <a class="py-0 px-3 st_color_white d-inline-block no-hover" :href="user.loginUrl"> 登入 </a>
        </div>
      </b-collapse>
    </b-navbar>

    <!-- mobile hamburger menu -->
    <div class="menu-hamburger d-md-none" :class="{ active: view.isShowMenu }" @click.prevent="view.isShowMenu = false">
      <!-- user area -->
      <div class="frame-user">
        <nuxt-link :to="{ name: 'my' }">
          <div class="d-flex justify-align-top">
            <div class="flex-auto">
              <ScanTraderAvatar class="mr-3" width="40" height="40" :src="user.my_info.avatar" />
            </div>
            <div class="flex-auto mr-2">
              <svg-icon
                name="icon_menu_expert_blue"
                width="16.6"
                height="18.7"
                v-if="$userCheck.is('service', user.my_info)" />
            </div>
            <div class="flex-fill">
              <div class="mb-2">
                <div><span v-text="user.my_info.name ? user.my_info.name.nick : ''" class="frame-user-name" /></div>
                <div class="text-user-code">
                  u{{ user.my_info.serial || '' }}
                </div>
              </div>
            </div>
          </div>
        </nuxt-link>
      </div>

      <div class="border-top" />
      <!-- menu -->
      <nuxt-link :to="{ name: 'my-subscriptions' }" class="link">
        我的訂閱
      </nuxt-link>
      <nuxt-link :to="{ name: 'my-events' }" class="link">
        我的活動
      </nuxt-link>
      <nuxt-link :to="{ name: 'my-portfolios' }" class="link">
        關注清單
      </nuxt-link>
      <V3Link class="link" link="/">
        研究室
      </V3Link>
      <V3Link class="link" link="/college">
        微股力學院
      </V3Link>
      <nuxt-link :to="{ name: 'my-ownRoles' }" class="link" v-if="_.get(user, 'myOwnRoles.original.length', 0)">
        小編列表
      </nuxt-link>

      <div class="frame-link-post py-2 my-2">
        <nuxt-link :to="{ name: 'u-uid-article-add', params: { uid: user.serial } }" class="link">
          發布觀點
        </nuxt-link>
        <nuxt-link
          :to="{ name: 'u-uid-service-article-add', params: { uid: user.serial } }"
          class="link"
          v-if="$userCheck.is('service', user.my_info)">
          發佈付費觀點
        </nuxt-link>
        <nuxt-link :to="{ name: 'article-manage' }" class="link">
          觀點管理
        </nuxt-link>
      </div>

      <nuxt-link
        :to="{ name: 'u-uid-service-analytics', params: { uid: user.serial } }"
        class="link"
        v-if="$userCheck.is('service', user.my_info)">
        銷售分析
      </nuxt-link>

      <nuxt-link :to="{ name: 'my-order' }" class="link">
        訂單紀錄
      </nuxt-link>
      <nuxt-link :to="{ name: 'my-settings' }" class="link">
        我的設定
      </nuxt-link>
      <nuxt-link :to="{ name: 'my-securitiesFirms' }" class="link">
        金融服務
      </nuxt-link>
      <nuxt-link :to="{ name: 'expert-manage' }" class="link" v-if="$userCheck.isLogin() && $userCheck.isAdmin()">
        達人管理列表
      </nuxt-link>
      <nuxt-link
        :to="{ name: 'admin-orders' }"
        class="link"
        v-if="$userCheck.isLogin() && $userCheck.haveRoles(['admin', 'orderAdminView'])">
        訂單管理列表
      </nuxt-link>
      <nuxt-link
        :to="{ name: 'admin-coupon' }"
        class="link"
        v-if="$userCheck.isLogin() && $userCheck.haveRoles(['admin', 'orderAdminView'])">
        優惠券管理列表
      </nuxt-link>
      <div class="border-bottom" />
      <!-- logout -->
      <nuxt-link :to="{ name: 'my-logout' }" class="link">
        登出
      </nuxt-link>
    </div>
  </div>
</template>

<script>
  import MainMenu from '~/components/menus/MainMenu'
  import NotifyMenu from '~/components/menus/NotifyMenu'
  import SearchForm from '~/components/forms/SearchForm'
  import UserMenu from '~/components/menus/UserMenu'
  import V2Link from '~/components/V2Link'
  import V3Link from '~/components/V3Link'
  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {
    components: {
      MainMenu,
      UserMenu,
      NotifyMenu,
      SearchForm,
      V2Link,
      V3Link,
    },
    data () {
      return {
        view: {
          enabled:          false, // top-bar
          back:             false, // 返回
          isSearching:      false,
          search:           true,
          logo:             true,
          isLogoInPosition: false,
          isShowMenu:       false,
        },
      }
    },
    computed: {
      ...mapState(['setting']),
      ...mapGetters(['user']),
    },
    watch: {
      $route () {
        this.changeLoginUrl()
      },
    },
    methods: {
      ...mapMutations('user', ['changeLoginUrl']),
      onSearch () {
        this.view.isSearching = !this.view.isSearching
      },
      goBack () {
        this.$router.go(-1)
      },
      formatNotifyCount (count) {
        return count > 999 ? '999+' : count
      },
      onScroll () {
        const { scrollY } = window
        if (scrollY > 5) {
          this.view.isLogoInPosition = false
        } else {
          this.view.isLogoInPosition = true
        }
      },
    },
    mounted () {
      this.changeLoginUrl()
      window.addEventListener('scroll', this.onScroll)
      this.onScroll()
    },
  }
</script>

<style lang="scss" scoped>
.link-white {
  color: #797c91;
}

.menu-hamburger {
  position: fixed;
  background-color: $st_color_white;
  height: calc(100% - 49px);
  left: 0;
  top: 0;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  z-index: 99;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &.active {
    top: 49px;
    opacity: 1;
    pointer-events: auto;
  }

  .border-top {
    height: 2px;
    border: solid 0.5px $st_color_gray_light;
  }

  .border-bottom {
    height: 2px;
    border: solid 1px #979797;
    background-color: #d8d8d8;
  }
}

.fa-search {
  font-size: 22px;
}

.icon-bell {
  font-size: 22px;

  &.active {
    color: $st_color_white;
  }

  @media only screen and (max-width: 768px) {
    opacity: 0.5;
  }
}

.icon-search,
.icon-activity {
  @media only screen and (max-width: 768px) {
    opacity: 0.5;
  }

  &.active {
    opacity: 1;
  }
}

.icon-activity {
  display: inline-block;
  width: 24px;
}

.top-bar {
  ul {
    display: block;
  }

  li {
    list-style: none;
  }

  .navbar {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #202648;
  }

  .menu--mobile {
    position: fixed;
    bottom: 0;
    z-index: 99;
    background-color: white;

    a {
      color: #142a2d;
      width: 100%;

      &.nuxt-link-exact-active {
        color: #4c84f8;
      }
    }
  }
}

.badge-new-feature {
  display: inline-block;
  border-radius: 3px;
  padding: 0.25rem;
  font-size: 10px;
  letter-spacing: 0.025em;
  line-height: 1;
}

body.not-logged-in {
  .top-bar {
    .menu--mobile {
      background-color: #4c84f8;

      a {
        color: white;
      }
    }
  }
}

.notifyButton {
  position: relative;
  display: inline-block;

  .notifyCount {
    position: absolute;
    top: -3px;
    right: -10px;
    padding: 0 2px;
    background: #ff2b00;
    color: white;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    min-width: 16px;
    border-radius: 5px;
  }
}

#logo {
  height: 31px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  opacity: 1;

  .logo-text {
    display: none;
  }
}

body.page-index {
  #logo.out-position {
    overflow: inherit;
    opacity: 0;

    .logo-text {
      color: white;
      font-size: 16px;
      display: block;
    }
  }
}

.nav-collapse--mobile {
  padding: 2px 0;
  height: 40px;

  @important {
    display: flex;
  }
}

.mobile-text-close {
  color: white;
  font-size: 14px;

  @important {
    border: none;
    background-color: transparent;
  }
}

.frame-user {
  padding: 20px 20px 10px;
}

.frame-user-name {
  font-size: $st_font-size-small;
  letter-spacing: 0.5px;
  color: $st_color_main_light;
}

.text-user-code {
  font-size: $st_font-size-smaller;
  font-weight: $st_font-weight-light;
  line-height: 1.5;
  letter-spacing: $st_font_letter-spacing;
  color: $st_color_gray_dark;
}

.link {
  display: block;
  font-size: $st_font-size-base;
  letter-spacing: $st_font_letter-spacing;
  color: $st_color_main_light;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px 20px;
}

.frame-link-post {
  border-top: solid 0.5px $st_color_gray_light;
  border-bottom: solid 0.5px $st_color_gray_light;

  .link:last-child {
    margin-bottom: 0;
  }
}

.btn-no-style {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  color: white;
  opacity: 0.5;
  cursor: pointer;

  &.active {
    opacity: 1;
    color: $st_color_sub;
  }
}

.st_border_white {
  border-color: $st_color_white;
}

.no-hover {
  &:hover {
    @important {
      color: $st_color_white;
    }
  }
}

.fw-300 {
  font-weight: 300;
}
</style>
