<template>
  <div
    :class="{
      'st_background_main is-expert': onExpertPage,
    }">
    <!-- mobile -->
    <b-container class="expert-dashboard py-3 d-md-none" v-if="user.user_info && user.user_info.name">
      <div class="d-flex">
        <span class="mr-3">
          <nuxt-link :to="$userRoute({ name: 'u-uid' })">
            <ScanTraderAvatar class="avatar__img" width="60" height="60" :src="user.user_info.avatar" />
          </nuxt-link>
        </span>
        <span class="d-inline-block w-100">
          <span class="d-flex align-items-end mb-2">
            <span class="d-flex align-items-center">
              <svg-icon
                v-if="$userCheck.are(['service', 'organization'])"
                class="mr-2"
                :width="$userBadge(user.user_info, 'width')"
                :height="$userBadge(user.user_info, 'height')"
                :name="$userBadge(user.user_info, 'name')" />
              <nuxt-link v-if="$userCheck.is('service') && _.get(user, 'user_info.belongOrgInfo.name.nick', 0)" :to="$userRoute({ name: 'o-oas' }, user.user_info.belongOrgInfo)">
                <svg-icon
                  v-b-tooltip.hover.focus.top="user.user_info.belongOrgInfo.name.nick"
                  class="mr-2"
                  :width="$userBadge(user.user_info.belongOrgInfo, 'width')"
                  :height="$userBadge(user.user_info.belongOrgInfo, 'height')"
                  :name="$userBadge(user.user_info.belongOrgInfo, 'name')" />
              </nuxt-link>
              <nuxt-link
                :to="$userRoute({ name:'u-uid' })"
                :class="{
                  'st_highlight-none-big-nlh': onExpertPage,
                  'st_main-none-big-nlh': !onExpertPage,
                }"
                class="expert-nick">
                {{ user.user_info.name.nick }}{{ (user.user_info.belongOrg && user.user_info.name.real) ? `．${ user.user_info.name.real || ''}` : '' }}
              </nuxt-link>
            </span>
            <div
              :class="{
                'st_color_gray': onExpertPage,
                'st_color_gray_light': !onExpertPage
              }"
              class="ml-2 st_none-none-smaller">
              {{ $userSerial(user.user_info, 'U') }}
            </div>
          </span>
          <div class="mt-1">
            <div class="userCollection">
              <span
                v-if="onExpertPage && user.user_info.service.haveChannel && (!user.user_info.service.hidden || !user.user_info.service.hidden.subscriptions)"
                :class="{
                  'st_color_white st_none-light-small': onExpertPage,
                  'st_color_gray st_none-none-small': !onExpertPage,
                }"
                class="d-inline-flex userCollection__item">
                <span
                  :class="{
                    'st_accent-medium-small': onExpertPage,
                    'st_title-medium-small': !onExpertPage,
                  }"
                  class="mr-1">
                  {{ user.user_info.subscriptions || 0 }}
                </span>
                支持
              </span>

              <component
                :class="{
                  'st_color_white st_none-light-small': onExpertPage,
                  'st_color_gray st_none-none-small': !onExpertPage,
                  'userCollection__item--active': $userRouteActive('-follow-followed'),
                }"
                class="d-inline-flex userCollection__item text-nowrap"
                :is="canEnterUserList ? 'nuxt-link' : 'span'"
                :to="$userRoute({ name: 'u-uid-follow-followed' })">
                <span
                  :class="{
                    'st_accent-medium-small': onExpertPage,
                    'st_title-medium-small': !onExpertPage,
                  }"
                  class="mr-1">
                  {{ user.user_info.followed || 0 }}
                </span>
                粉絲
              </component>

              <component
                :class="{
                  'st_color_white st_none-light-small': onExpertPage,
                  'st_color_gray st_none-none-small': !onExpertPage,
                  'userCollection__item--active': $userRouteActive('-follow-followTo'),
                }"
                class="d-inline-flex userCollection__item text-nowrap"
                :is="canEnterUserList ? 'nuxt-link' : 'span'"
                :to="$userRoute({ name: 'u-uid-follow-followTo' })"
                v-if="$userCheck.isSame()">
                <span
                  :class="{
                    'st_accent-medium-small': onExpertPage,
                    'st_title-medium-small': !onExpertPage,
                  }"
                  class="mr-1">
                  {{ user.user_info.followTo || 0 }}
                </span>
                關注
              </component>

              <component
                :class="{
                  'st_color_white st_none-light-small': onExpertPage,
                  'st_color_gray st_none-none-small': !onExpertPage,
                  'userCollection__item--active': $userRouteActive(['-collection-article', '-collection-service'])
                }"
                class="d-inline-flex userCollection__item text-nowrap"
                :is="canEnterUserList ? 'nuxt-link' : 'span'"
                :to="$userRoute({ name: 'u-uid-collection-article' })"
                v-if="$userCheck.isSame()">
                <span
                  :class="{
                    'st_accent-medium-small': onExpertPage,
                    'st_title-medium-small': !onExpertPage,
                  }"
                  class="mr-1">
                  {{ collectionsNum?collectionsNum:user.user_info.collections || 0 }}
                </span>
                收藏
              </component>
            </div>
          </div>
          <div
            v-if="!$userCheck.isSame() && !hideCollectionAccountButton"
            class="d-flex justify-content-end mt-4 buttonArea">
            <b-button
              @click.prevent="showPopupOfLineSetting"
              v-if="collectionAccountState && onExpertPage && !hideCollectionAccountButton"
              :class="{
                'actived': lineNotifyRelationState
              }"
              class="mr-2 ml-auto notifyLine w-100 h-100 d-flex align-items-center justify-content-center">
              <span id="lineNotifyButtonMobile">
                <b-spinner v-if="disableLineNotifyRelationButton || typeof collectionAccount === 'boolean'" class="lineNotifyRelationSpinner mr-1" label="Spinning" />
                <svg-icon
                  v-else
                  class="mr-1"
                  width="22"
                  height="19"
                  :name="lineNotifyRelationState ? 'icon_line-on-green' :'icon_line-off-gray'" />
                LINE
              </span>
            </b-button>
            <b-button
              :disabled="disableCollectionAccountButton"
              :class="collectionAccountState ? 'actived' : '' " @click.prevent="updateCollectionAccountState()"
              class="btn-follow position-static w-100 h-100 d-flex align-items-center justify-content-center">
              <i class="material-icons align-middle">
                favorite
              </i>
              <span class="align-middle">
                關注
              </span>
            </b-button>
          </div>
          <template v-if="$userCheck.isSame() && onExpertPage">
            <span class="d-flex align-items-center mt-4">
              <span
                @click.prevent="copyFollowedLink"
                class="cursor-pointer st_disable-medium-small d-flex align-items-center">
                <svg-icon class="mr-1" width="22" height="22" name="icon_link" />
                關注連結
              </span>
              <span id="followedLinkForMobile" class="question-mark st_background_gray_dark st_color_main">
                ?
              </span>
            </span>
            <b-tooltip placement="bottom" triggers="click" target="followedLinkForMobile">
              分享自己的關注連結，獲取更多粉絲
            </b-tooltip>
          </template>
        </span>
      </div>
    </b-container>

    <!-- desktop -->
    <b-container
      :class="{ 'non-expert-header': !onExpertPage }" class="expert-dashboard py-4 d-none d-md-block"
      v-if="user.user_info && user.user_info.name">
      <b-row>
        <b-col class="d-flex text-center align-items-end justify-content-between" cols="12" lg>
          <div
            :class="{
              'align-items-center': !$userCheck.isSame(),
              'align-items-end': $userCheck.isSame() && onExpertPage,
            }"
            class="d-flex w-100">
            <div class="mr-3">
              <nuxt-link :to="$userRoute({ name: 'u-uid' })">
                <ScanTraderAvatar class="avatar__img" width="75" height="75" :src="user.user_info.avatar" />
              </nuxt-link>
            </div>
            <div class="d-flex flex-column justify-content-center ml-1 text-left w-100">
              <span
                :class="{
                  'mb-1': $userCheck.isSame() && onExpertPage,
                }"
                class="d-flex align-items-end">
                <span class="d-flex align-items-center mb-1">
                  <svg-icon
                    v-if="$userCheck.are(['service', 'organization'])"
                    class="mr-2"
                    :width="$userBadge(user.user_info, 'width')"
                    :height="$userBadge(user.user_info, 'height')"
                    :name="$userBadge(user.user_info, 'name')" />
                  <nuxt-link v-if="$userCheck.is('service') && _.get(user, 'user_info.belongOrgInfo.name.nick', 0)" :to="$userRoute({ name: 'o-oas' }, user.user_info.belongOrgInfo)">
                    <svg-icon
                      v-b-tooltip.hover.focus.top="user.user_info.belongOrgInfo.name.nick"
                      class="mr-2"
                      :width="$userBadge(user.user_info.belongOrgInfo, 'width')"
                      :height="$userBadge(user.user_info.belongOrgInfo, 'height')"
                      :name="$userBadge(user.user_info.belongOrgInfo, 'name')" />
                  </nuxt-link>
                  <nuxt-link
                    :to="$userRoute({ name:'u-uid' })"
                    :class="{
                      'st_highlight-none-bigger-nlh': onExpertPage,
                      'st_main-none-bigger-nlh': !onExpertPage,
                    }"
                    class="expert-nick">
                    {{ user.user_info.name.nick }}{{ (user.user_info.belongOrg && user.user_info.name.real) ? `．${ user.user_info.name.real || ''}` : '' }}
                  </nuxt-link>
                </span>
                <span
                  :class="{
                    'st_color_gray': onExpertPage,
                    'st_color_gray_light': !onExpertPage
                  }"
                  class="st_none-none-smaller ml-2">
                  {{ $userSerial(user.user_info, 'U') }}
                </span>
              </span>
              <div
                v-if="!$userCheck.isSame() && !hideCollectionAccountButton"
                class="flex-0-auto d-flex mt-2">
                <b-button
                  :disabled="disableCollectionAccountButton"
                  :class="collectionAccountState ? 'actived' : '' " @click.prevent="updateCollectionAccountState()"
                  class="btn-follow w-100 h-100 d-flex align-items-center justify-content-center">
                  <i class="material-icons align-middle">
                    favorite
                  </i>
                  <span class="align-middle">
                    關注
                  </span>
                </b-button>
                <b-button
                  id="lineNotifyButtonDesktop"
                  @click.prevent="showPopupOfLineSetting"
                  v-if="collectionAccountState && onExpertPage && !hideCollectionAccountButton"
                  :class="{
                    'actived': lineNotifyRelationState
                  }"
                  class="ml-2 notifyLine w-100 h-100 d-flex align-items-center justify-content-center">
                  <b-spinner v-if="disableLineNotifyRelationButton || typeof collectionAccount === 'boolean'" class="lineNotifyRelationSpinner mr-1" label="Spinning" />
                  <svg-icon
                    v-else
                    class="mr-1"
                    width="16.6"
                    height="18.7"
                    :name="lineNotifyRelationState ? 'icon_line-on-green' :'icon_line-off-gray'" />
                  LINE
                </b-button>
              </div>
              <template v-if="$userCheck.isSame() && onExpertPage">
                <span class="d-flex align-items-center mt-2">
                  <span
                    @click.prevent="copyFollowedLink"
                    class="cursor-pointer st_disable-medium-small d-flex align-items-center">
                    <svg-icon class="mr-1" width="22" height="22" name="icon_link" />
                    關注連結
                  </span>
                  <span id="followLink" class="question-mark st_background_gray_dark st_color_main">
                    ?
                  </span>
                </span>
                <b-tooltip placement="bottom" target="followLink">
                  分享自己的關注連結，獲取更多粉絲
                </b-tooltip>
              </template>
            </div>
          </div>
          <div class="flex-0-auto text-right expert-data">
            <div class="d-inline-block  text-center ">
              <div
                class="subscribers data-item px-4 d-inline-block"
                v-if="onExpertPage && user.user_info.service.haveChannel && (!user.user_info.service.hidden || !user.user_info.service.hidden.subscriptions)">
                <div class="num">
                  {{ user.user_info.subscriptions || 0 }}
                </div>
                <div class="label">
                  支持
                </div>
              </div>
              <component
                :is="canEnterUserList ? 'nuxt-link' : 'div'"
                :to="$userRoute({ name: 'u-uid-follow-followed' })"
                class="followTo data-item d-inline-block px-4"
                :class="{
                  'expert-data--border': $userRouteActive('-follow-followed')
                }">
                <div class="num">
                  {{ user.user_info.followed || 0 }}
                </div>
                <div
                  class="label"
                  :class="{
                    'label--selected': $userRouteActive('-follow-followed')
                  }">
                  粉絲
                </div>
              </component>
              <component
                :is="canEnterUserList ? 'nuxt-link' : 'div'"
                :to="$userRoute({ name: 'u-uid-follow-followTo' })"
                class="followed data-item d-inline-block px-4"
                :class="{
                  'expert-data--border': $userRouteActive('-follow-followTo')
                }"
                v-if="$userCheck.isSame()">
                <div class="num">
                  {{ user.user_info.followTo || 0 }}
                </div>
                <div
                  class="label"
                  :class="{
                    'label--selected': $userRouteActive('-follow-followTo')
                  }">
                  關注
                </div>
              </component>
              <component
                :is="canEnterUserList ? 'nuxt-link' : 'div'"
                :to="$userRoute({ name: 'u-uid-collection-article' })"
                class="collections data-item d-inline-block px-4"
                :class="{
                  'expert-data--border': $userRouteActive(['-collection-article', '-collection-service'])
                }"
                v-if="$userCheck.isSame()">
                <div class="num">
                  {{ collectionsNum?collectionsNum:user.user_info.collections || 0 }}
                </div>
                <div
                  class="label"
                  :class="{
                    'label--selected': $userRouteActive(['-collection-article', '-collection-service'])
                  }">
                  收藏
                </div>
              </component>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <LineNotificationSettingsModal
      :expert="user.user_info"
      :lineNotifyRelation="lineNotifyRelation"
      @updated="updateLineNotifyRelationState" />
    <IntroductionOfLineNotificationModal />
  </div>
</template>

<script>
  import IntroductionOfLineNotificationModal from '~/components/modal/IntroductionOfLineNotificationModal'
  import LineNotificationSettingsModal from '~/components/modal/LineNotificationSettingsModal'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      LineNotificationSettingsModal,
      IntroductionOfLineNotificationModal,
    },
    data () {
      return {
        collectionsNum:     null,
        collectionAccount:  null,
        lineNotifyRelation: {
          identity: null,
          mode:     'close',
        },
        hideCollectionAccountButton:     true,
        disableCollectionAccountButton:  false,
        disableLineNotifyRelationButton: false,
      }
    },
    computed: {
      ...mapGetters(['user']),
      collectionAccountState () {
        return !_.isNil(this.collectionAccount) || false
      },
      onExpertPage () {
        return this.$userCheck.are(['service', 'organization'])
      },
      lineNotifyRelationState () {
        return (!_.isNil(this.lineNotifyRelation.identity) && this.lineNotifyRelation.mode !== 'close') || false
      },
      canEnterUserList () {
        return this.$userCheck.isSame()
          || this.$userCheck.isAdmin()
          || this.$userCheck.haveOwnRules(['admin', 'userList'])
      },
    },
    watch: {
      'user.user_info.identity': 'getCollectionAccountState',
    },
    methods: {
      ...mapActions('api/account', ['getAccount']),
      ...mapActions('api/collection', ['getCollection', 'createCollection', 'deleteCollection', 'getCollectionsNum']),
      ...mapActions('api/lineNotifyRelation', ['getLineNotifyRelation', 'updateLineNotifyRelation']),
      async getCollectionAccountState () {
        const collectionsNumAccountOption = {
          kind:    ['article', 'channel'],
          account: this.user.my_info.identity,
        }
        this.collectionsNum = await this.getCollectionsNum(collectionsNumAccountOption)
          .then(async data => (!data.error ? data.collections : null))
        if (this.$userCheck.isLogin() && !this.$userCheck.isSame()) {
          this.hideCollectionAccountButton = true
          const collectionAccountOption = {
            kind:    'account',
            index:   this.user.user_info.identity,
            account: this.user.my_info.identity,
          }
          this.collectionAccount = await this.getCollection(collectionAccountOption)
            .then(async data => {
              if (this.onExpertPage && !data.error && !_.isNil(data.identity)) {
                await this.getLineNotifyRelationState()
              }
              return (!data.error ? data.identity : null)
            })
          if (this.$userRouteActive('') && !_.isUndefined(this.$route.query.follow) && this.onExpertPage) {
            await this.updateCollectionAccountState(true)
            const query = _.cloneDeep(this.$route.query)
            delete query.follow
            const routerReplace = {
              name:   this.$route.name,
              params: this.$route.params,
            }
            if (this.$route.hash) {
              routerReplace.hash = this.$route.hash
            }
            if (Object.keys(query).length) {
              routerReplace.query = query
            }
            this.$router.replace(routerReplace, () => {}, () => {})
          }
        } else {
          this.resetLineNotifyRelation()
        }
        this.hideCollectionAccountButton = false
      },
      updateCollectionAccountState (autoFollow = false) {
        if (this.$userCheck.isLogin() && !this.disableCollectionAccountButton) {
          this.disableCollectionAccountButton = true
          const { collectionAccount } = this
          if (_.isNil(collectionAccount)) {
            this.collectionAccount = true
            const collection = {
              kind:    'account',
              index:   this.user.user_info.identity,
              account: this.user.my_info.identity,
            }
            return this.createCollection(collection)
              .then(async data => {
                if (this.onExpertPage && !data.error && !_.isNil(data.identity)) {
                  await this.getLineNotifyRelationState()
                }
                this.collectionAccount = _.isNil(data.error) ? data.identity : null
                await this.$store.dispatch('user/getUserInfo', this.user.user_info.serial)
                if (autoFollow) {
                  this.$toast.show('開啟 LINE 通知成功', {
                    action: {
                      text:    '設定',
                      onClick: () => {
                        this.showPopupOfLineSetting()
                      },
                    },
                  }).goAway(3000)
                }
                this.disableCollectionAccountButton = false
              })
          }
          if (autoFollow) {
            if (this.lineNotifyRelationState) {
              this.$toast.show('你已經開啟 LINE 通知了', {
                action: {
                  text:    '設定',
                  onClick: () => {
                    this.showPopupOfLineSetting()
                  },
                },
              }).goAway(3000)
            } else {
              this.updateLineNotifyRelationState()
            }
            this.disableCollectionAccountButton = false
            return false
          }
          this.collectionAccount = null
          const collectionOption = {
            identity: collectionAccount,
          }
          return this.deleteCollection(collectionOption)
            .then(async () => {
              await this.$store.dispatch('user/getUserInfo', this.user.user_info.serial)
              this.resetLineNotifyRelation()
              this.disableCollectionAccountButton = false
            })
        }
        this.$toast.show('登入後即可關注用戶').goAway(1500)
        return false
      },
      getLineNotifyRelationState () {
        const lineNotifyRelationOption = {
          index:   this.user.user_info.identity,
          account: this.user.my_info.identity,
        }
        return this.getLineNotifyRelation(lineNotifyRelationOption).then(data => {
          if (!data.error && !_.isNil(data.identity)) {
            this.lineNotifyRelation.mode = data.mode
            this.lineNotifyRelation.identity = data.identity
          }
        })
      },
      async updateLineNotifyRelationState (newMode = this.lineNotifyRelation.mode === 'close' ? 'all' : 'close') {
        if (!this.lineNotifyRelation.identity || this.disableLineNotifyRelationButton) return
        this.disableLineNotifyRelationButton = true
        const now = this.$moment()
        const lineNotifyRelationMode = this.lineNotifyRelation.mode
        const lineNotifyRelationOption = {
          identity: this.lineNotifyRelation.identity,
          mode:     newMode,
        }
        this.lineNotifyRelation.mode = await this.updateLineNotifyRelation(lineNotifyRelationOption).then(async data => {
          const diffSecond = this.$moment().diff(now)
          if (diffSecond < 300) {
            await new Promise(resolve => setTimeout(resolve, 300 - diffSecond))
          }
          if (data.error || _.isNil(data.identity)) {
            return lineNotifyRelationMode
          }
          return data.mode
        })
        const newModeMessage = newMode === 'all' ? '開啟 LINE 通知成功' : '已關閉 LINE 通知'
        const toastMessage = (this.lineNotifyRelation.mode === newMode)
          ? newModeMessage
          : '開啟 LINE 通知失敗，請再試一次'
        this.$toast.show(toastMessage, {
          action: {
            text:    '設定',
            onClick: () => {
              this.$root.$emit('bv::show::modal', `${this.user.user_info.identity}-lineNotifyModal`)
            },
          },
        }).goAway(3000)
        this.disableLineNotifyRelationButton = false
      },
      copyFollowedLink () {
        this.$copyText(`${window.location.origin}/u/${this.$route.params.uid}?follow`)
        this.$toast.show('複製關注連結成功').goAway(3000)
      },
      resetLineNotifyRelation () {
        this.lineNotifyRelation = {
          identity: null,
          mode:     'close',
        }
      },
      showPopupOfLineSetting () {
        if (!this.user.my_info.firstTimeShowLineNotify) {
          this.$root.$emit('bv::show::modal', `${this.user.user_info.identity}-lineNotifyModal`)
          this.$root.$emit('bv::show::modal', 'introductionOfLineNotificationModal')
        } else {
          this.$root.$emit('bv::show::modal', `${this.user.user_info.identity}-lineNotifyModal`)
        }
      },
    },
    mounted () {
      this.getCollectionAccountState()
    },
  }
</script>

<style lang="scss" scoped>
  .userCollection {
    margin: -6px -12px;

    &__item {
      padding: 6px 12px;

      &--active,
      &:hover {
        color: $st_color_main_light;
        font-weight: unset;
      }
    }
  }

  .flex-0-auto {
    flex: 0 0 auto;
  }

  .buttonArea {
    margin-left: -60px;
  }

  .btn-follow {
    border-radius: 4px;
    background-color: transparent;
    border: solid 2px rgba($st_color_gray_light, 0.5);
    color: $st_color_gray_dark;
    padding: 3px 0;
    max-width: 120px;
    max-height: 34px;

    i {
      color: $st_color_gray_light;
    }

    &:hover {
      background-color: rgba($st_color_gray_lighter, 0.3);
    }

    &:focus, &:active {
      background: inherit;
      color: $st_color_gray_dark;
      border: solid 2px rgba($st_color_gray_light, 0.5);
    }

    &.actived, &.actived i {
      color: $st_color_sub;

      &:hover {
        color: $st_color_sub;
      }
    }

    &.mobile {
      position: absolute;
      right: 10px;
      top: 0;
    }
  }

  .notifyLine {
    border-radius: 4px;
    background-color: rgba(91, 120, 169, 0.3);
    border: solid 2px $st_color_transparent;
    color: $st_color_gray_light;
    max-width: 120px;
    max-height: 34px;
    padding: 3px 0;

    &.actived {
      color: #00c43e;

      &:hover {
        color: #00c43e;
      }
    }

    &:hover {
      background-color: rgba(91, 120, 169, 0.15);
      color: $st_color_gray_light;
    }
  }

  .expert-dashboard {
    position: relative;
    background: white;
    border: solid 2px $st_color_gray_lighter;

    .expert-data {
      box-sizing: border-box;

      .followed:hover, .followTo:hover, .collections:hover {
        .label, .num {
          color: $st_color_main_lighter;
        }
      }

      &--border:before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: calc(0% - 24px);
        width: 100%;
        border-bottom: 4px solid $st_color_main;
      }

      .data-item {
        position: relative;
        font-weight: bold;

        .num {
          font-size: $st_font-size-biggest;
          font-weight: $st_font-weight-medium;
          line-height: 1.33;
          letter-spacing: 0.9px;
          text-align: center;
          margin-bottom: 3px;
          color: $st_color_main_light;

          @media only screen and (max-width: 480px) {
            font-size: $st_font-size-big;
          }

          i {
            margin-left: 5px;
            color: #bbb;
            font-size: $st_font-size-small;
            vertical-align: middle;
          }
        }

        .label {
          font-size: $st_font-size-small;
          font-weight: $st_font-weight-medium;
          letter-spacing: 0.6px;
          text-align: center;
          color: $st_color_gray;

          &--selected {
            color: $st_color_main_light;
          }

          @media only screen and (max-width: 480px) {
            font-size: $st_font-size-smaller;
          }
        }
      }
    }

    .expert-nick {
      display: inline-block;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      word-break: break-all;
      line-height: 23px;

      &:hover {
        color: $st_color_main;
      }
    }
  }

  .non-expert-header {
    max-width: 1027px;
    margin-top: 12px;
    padding-left: 46px;
    padding-right: 44px;
  }

  .is-expert {
    .btn-follow {
      background-color: rgba(91, 120, 169, 0.3);
      border-color: $st_color_transparent;
      color: $white;

      &.actived {
        color: $st_color_sub;

        &:hover {
          color: $st_color_sub;
        }
      }

      &:hover {
        background-color: rgba(91, 120, 169, 0.15);
        border-color: $st_color_transparent;
        color: $white;
      }
    }

    .expert-dashboard {
      background-color: transparent;
      border: none;

      .expert-data {
        .data-item {
          .label {
            color: white;

            &--selected {
              color: $st_color_sub;
            }
          }

          .num {
            color: $st_color_sub;
          }
        }

        .followed:hover, .followTo:hover, .collections:hover {
          .label, .num {
            color: $st_color_sub_light;
          }
        }

        .followed {
          box-sizing: border-box;
        }

        &--border:before {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: calc(0% - 24px);
          width: 100%;
          border-bottom: 4px solid $st_color_sub;
          box-sizing: border-box;
        }
      }

      .expert-nick {
        &:hover {
          color: $white;
        }
      }
    }

    .userCollection {
      &__item {
        &--active,
        &:hover {
          color: $st_color_sub;
        }
      }
    }
  }

  .lineNotifyRelationSpinner {
    color: $st_color_gray;
    width: 22px;
    height: 22px;

    @include media-breakpoint-up(md) {
      width: 16.6px;
      height: 16.6px;
    }
  }
</style>
