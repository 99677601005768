import apiUtils from '~/utils/api'

export const actions = {
  // file = input file tag files, like <DOM>.files[0]
  uploadFile ({ commit }, file) {
    const data = new FormData()
    data.append('file', file)
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/upload`,
      data)
      .then(res => {
        let data = ''
        if (apiUtils.checkDataAttributesImageUrl(res)) {
          data = res.data.data.attributes.imageUrl
        }
        return data
      })
      .catch(apiUtils.errorHandler)
  },
  uploadFileContract ({ commit }, file) {
    const data = new FormData()
    data.append('file', file)
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/upload/contract`,
      data)
      .then(res => {
        let data = ''
        if (apiUtils.checkDataAttributesPdfUrl(res)) {
          data = res.data.data.attributes.fileName
        }
        return data
      })
      .catch(apiUtils.errorHandler)
  },
}
