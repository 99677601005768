// 因 rich menu 上寫死了特定幾頁的連結，目前暫時無法直接導向到 liff-redirect 的路由。
// 因此這支檔案會將將寫死連結做重新導向到 liff-redirect 來 init LIFF
// 之後如果 rick menu 更新連結後，這支檔案就可以刪除
export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    const { query, hash, name } = to
    const { liffId } = query
    const canLiffRedirect = !!(liffId && hash && hash.includes('access_token') && name !== 'liff-redirect')

    // 初次進入頁面才執行，否則都要略過
    if (from.name) {
      return next()
    }

    if (canLiffRedirect) {
      if (app.$userCheck.isLogin()) {
        const newRoute = {
          name:   to.name,
          params: to.params,
          query:  _.cloneDeep(to.query),
        }
        delete newRoute.query.liffId
        next(newRoute)
      } else {
        next({
          name:  'liff-redirect',
          query: {
            liffId,
            fromOtherPage: true,
            path:          encodeURIComponent(to.fullPath.split('#')[0]),
          },
          hash,
        })
      }
    } else {
      next()
    }

    return true
  })
}
