import apiUtils from '~/utils/api'

export const actions = {
  getTag ({}, option) {
    // option = {
    //   identity: <String>,
    //   name: <String>,
    // }
    const identity = option.identity || null
    const name = option.name || null
    const query = {
      filter: [],
      fields: ['identity', 'name'],
    }
    if (identity) {
      query.filter.push({
        field:    'identity',
        operator: '=',
        value:    identity,
        logic:    0,
      })
    }
    if (name) {
      query.filter.push({
        field:    'name',
        operator: '=',
        value:    name,
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/tag${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getTagList ({}, option) {
    // option = {
    //   search  : <String>,
    //   identity: <String>,
    //   sort    : <Object>,
    //   page    : <Number>,
    //   limit   : <Number>,
    // }
    const search = option.search || null
    const identity = option.identity || null
    const page = option.page || 1
    const limit = option.limit || 30
    const name = option.name || null
    const offset = limit * (
      page - 1)
    const sort = option.sort || { 'status.created': 1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      sort:   sort,
      fields: ['identity', 'name'],
    }
    if (!_.isNull(search)) {
      query.filter.push({
        field:    'name',
        operator: 'Like',
        value:    search,
        logic:    0,
      })
    }
    if (!_.isNull(identity) && _.isArray(identity)) {
      query.filter.push({
        field:    'identity',
        operator: 'In',
        value:    identity,
        logic:    0,
      })
    }
    if (!_.isNull(name) && _.isArray(name)) {
      query.filter.push({
        field:    'name',
        operator: 'In',
        value:    name,
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/tags${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createTag ({}, tag) {
    // tag = {
    //   name: <String>
    // }
    const body = {
      data: {
        type:       'tag',
        attributes: tag,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/tag`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
