import apiUtils from '~/utils/api'

export const actions = {
  getBulletin ({}, option) {
    // option = {
    //   identity: <String>,
    //   kind  : <String>, event
    //   index  : <String>,
    //   fields  : <Array>
    // }
    const { identity } = option
    const kind = option.kind || null
    const index = option.index || null
    const fields = option.fields || ['identity', 'kind', 'index', 'title', 'content', 'account', 'published', 'status']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          value:    identity,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
    }
    if (kind) {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: '=',
        logic:    0,
      })
    }
    if (index) {
      query.filter.push({
        field:    'index',
        value:    index,
        operator: '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/bulletin${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getBulletins ({}, option) {
    // option = {
    //   identity  : <Array>,
    //   kind    : <String>, event
    //   index   : <String>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const identity = option.identity || null
    const { kind, index } = option
    const fields = option.fields || ['identity', 'kind', 'index', 'title', 'content', 'account', 'published', 'status']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const sort = option.sort || {
      published:        -1,
      'status.created': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(identity)) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: 'In',
        logic:    0,
      })
    }
    if (kind) {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: '=',
        logic:    0,
      })
    }
    if (index) {
      query.filter.push({
        field:    'index',
        value:    index,
        operator: '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/bulletins${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createBulletin ({}, bulletin) {
    // bulletin = {
    //   kind        : <String>, event // 類型
    //   index       : <String>, // 類型對應identity
    //   title       : <String>, // 標題
    //   content     : <String>: LongText, // 內容
    //   account     : <String>,
    //   published    : <Number: Timestamp>, // 發布時間
    // }
    const body = {
      data: {
        type:       'bulletin',
        attributes: bulletin,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/bulletin`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateBulletin ({ commit }, bulletin) {
    // bulletin = {
    //   identity    : <String>
    //   kind        : <String>, event // 類型
    //   index       : <String>, // 類型對應identity
    //   title       : <String>, // 標題
    //   content     : <String>: LongText, // 內容
    //   published    : <Number: Timestamp>, // 發布時間
    // }
    const {
      identity, kind, index, title, content, published,
    } = bulletin
    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    const body = {
      data: {
        type:       'bulletin',
        id:         identity,
        attributes: {
          kind,
          index,
          title,
          content,
          published,
        },
      },
    }

    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/bulletin${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteBulletin ({}, option) {
    // option = {
    //   identity : <String>,
    // }
    const { identity } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
      fields: ['identity'],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/bulletin${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
