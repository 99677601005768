const getCookie = function(cname, req) {
  const name = `${cname}=`
  let decodedCookie
  if (typeof window === 'undefined') decodedCookie = decodeURIComponent(req.headers.cookie)
  else decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export default ({
  $axios, req, store, query,
}) => {
  let token = getCookie('token', req)
  if (!req && 'token' in query) {
    // eslint-disable-next-line prefer-destructuring
    token = query.token
  }
  $axios.setToken(false)
  if (token !== '') {
    $axios.setToken(token, 'Bearer')
  }
  const notifyRead = getCookie('notifyRead', req)
  if (notifyRead !== '') {
    store.commit('user/setNotifyRead', notifyRead)
  }
  const articleFontSize = getCookie('articleFontSize', req)
  if (articleFontSize !== '' && articleFontSize !== 'null') {
    store.commit('user/setArticleFontSize', articleFontSize)
  }
}
