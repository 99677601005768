<template>
  <div class="user-menu position-relative text-right">
    <b-dropdown
      ref="dropdown" @show="isShow = true" @hide="isShow = false" id="userdown" right
      noCaret>
      <template slot="button-content">
        <div class="d-flex align-items-center">
          <nuxt-link :to="{name: 'my'}">
            <ScanTraderAvatar width="28" height="28" :src="user.my_info.avatar" />
          </nuxt-link>
          <span v-text="user.my_info.name ? user.my_info.name.nick : ''" class="name pl-2" />
          <i class="fas pl-2" :class="isShow ? 'fa-caret-up' : 'fa-caret-down'" />
        </div>
      </template>
      <div class="menu_item_group" v-for="(item, index) in items" :key="'item_' + index">
        <component
          v-for="(subitem, subindex) in item"
          :is="'href' in subitem.link ? 'a': 'nuxt-link'"
          :key="'item_' + index + '_' + subindex"
          :to="'href' in subitem.link ? undefined : subitem.link"
          :href="subitem.link.href"
          v-show="subitem.show"
          @click.native="hideDropdown"
          class="dropdown-item"
          :class="{
            'd-flex align-items-center': subitem.icon
          }">
          {{ subitem.title }}
          <svg-icon
            v-if="subitem.icon"
            :width="subitem.icon.width" :height="subitem.icon.height" :name="subitem.icon.name" :class="subitem.icon.class" />
        </component>
      </div>
    </b-dropdown>
  </div>
</template>
<script>
  import { mapGetters, mapState } from 'vuex'

  export default {
    data () {
      return {
        isShow: false,
      }
    },
    computed: {
      ...mapState(['setting']),
      ...mapGetters(['user']),
      checkExpertApproved () {
        return this.$userCheck.is('service', this.user.my_info)
      },
      items () {
        return [
          [
            {
              title: '個人主頁',
              link:  { name: 'my' },
              class: ['link'],
              show:  true,
              icon:  null,
            },
            {
              title: '探索微股力',
              link:  { name: 'explore-type' },
              class: ['link'],
              show:  true,
              icon:  null,
            },
            {
              title: '動態消息',
              link:  { name: 'my-activity' },
              class: ['link'],
              show:  true,
              icon:  null,
            },
            {
              title: '我的訂閱',
              link:  { name: 'my-subscriptions' },
              class: ['link'],
              show:  true,
              icon:  null,
            },
            {
              title: '我的活動',
              link:  { name: 'my-events' },
              class: ['link'],
              show:  true,
              icon:  null,
            },
            {
              title: '關注清單',
              link:  { name: 'my-portfolios' },
              class: ['link'],
              show:  true,
              icon:  null,
            },
            {
              title: '小編列表',
              link:  { name: 'my-ownRoles' },
              class: ['link'],
              show:  _.get(this.user, 'myOwnRoles.original.length', 0),
              icon:  null,
            },
          ], [
            {
              title: '發布觀點',
              link:  {
                name:   'u-uid-article-add',
                params: { uid: this.user.serial },
              },
              class: ['link'],
              show:  true,
              icon:  null,
            },
            {
              title: '發布付費觀點',
              link:  {
                name:   'u-uid-service-article-add',
                params: { uid: this.user.serial },
              },
              class: ['link'],
              show:  this.checkExpertApproved,
              icon:  null,
            },
            {
              title: '觀點管理',
              link:  {
                name: 'article-manage',
              },
              class: ['link'],
              show:  this.checkExpertApproved,
              icon:  null,
            },
          ], [
            {
              title: '銷售分析',
              link:  {
                name:   'u-uid-service-analytics',
                params: { uid: this.user.serial },
              },
              class: ['link'],
              show:  this.checkExpertApproved,
              icon:  null,
            },
            {
              title: '訂單記錄',
              link:  { name: 'my-order' },
              class: ['link'],
              show:  true,
              icon:  null,
            },
            {
              title: '我的設定',
              link:  { name: 'my-settings' },
              class: ['link'],
              show:  true,
              icon:  null,
            },
            {
              title: '金融服務',
              link:  { name: 'my-securitiesFirms' },
              class: ['link'],
              show:  true,
              icon:  null,
            },
            {
              title: '達人管理列表',
              link:  { name: 'expert-manage' },
              class: ['link'],
              show:  this.$userCheck.isLogin() && this.$userCheck.isAdmin(),
              icon:  null,
            },
            {
              title: '訂單管理列表',
              link:  { name: 'admin-orders' },
              class: ['link'],
              show:  this.$userCheck.isLogin() && this.$userCheck.haveRoles(['admin', 'orderAdminView']),
              icon:  null,
            },
            {
              title: '優惠券管理列表',
              link:  { name: 'admin-coupon' },
              class: ['link'],
              show:  this.$userCheck.isLogin() && this.$userCheck.haveRoles(['admin', 'orderAdminView']),
              icon:  null,
            },
            {
              title: '全站觀點管理',
              link:  { name: 'admin-article-manage' },
              class: ['link'],
              show:  this.$userCheck.isLogin() && this.$userCheck.haveRoles(['admin']),
              icon:  null,
            },
            {
              title: '全站個股動態列表',
              link:  { name: 'admin-article-stockPost' },
              class: ['link'],
              show:  this.$userCheck.isLogin() && this.$userCheck.haveRoles(['admin']),
              icon:  null,
            },
            {
              title: '廣告版位管理',
              link:  { name: 'admin-advertisement-manage' },
              class: ['link'],
              show:  this.$userCheck.isLogin() && this.$userCheck.haveRoles(['admin']),
              icon:  null,
            },
          ], [
            {
              title: '登出',
              link:  { name: 'my-logout' },
              class: ['link'],
              show:  true,
              icon:  null,
            },
          ],
        ]
      },
    },
    methods: {
      hideDropdown () {
        this.$refs.dropdown.hide(true)
      },
    },
  }
</script>

<style lang="scss">
  .user-menu {
    > .avatar {
      position: absolute;
      top: 10px;
      left: -5px;
    }

    .name {
      max-width: 140px;
      overflow: hidden;

      &:hover {
        opacity: 0.75;
      }

      @include media-breakpoint-up(lg) {
        max-width: 180px;
      }
    }

    #userdown {
      button {
        &:active {
          @important {
            background-color: initial;
          }
        }
      }
    }

    .menu_item_group {
      padding: 8px 0;
      border-bottom: solid 0.5px $st_color_gray_lighter;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    .dropdown-menu.show {
      max-height: 85vh;
      overflow: auto;
    }

    .dropdown {
      &,
      &.show {
        button {
          background-color: transparent;
          border: 0;

          &:focus,
          &:active,
          &:hover {
            outline: none;
            background-color: transparent;
            border: 0;
            box-shadow: none;
          }
        }
      }

      a.dropdown-item {
        font-size: $st_font-size-base;
        letter-spacing: $st_font_letter-spacing;
        color: $st_color_main_light;
        padding: 13px 36px 13px 24px;
        display: block;
        cursor: pointer;

        &:active {
          background-color: $st_color_gray_lighter;
          color: $st_color_main_light;
        }

        &:hover {
          color: $st_color_main_light;
          background-color: $st_color_gray_lighter;

          .icon {
            color: $st_color_main_light;
          }
        }

        .icon {
          color: $st_color_main_light;
          padding-right: 6px;
          width: 22px;
          overflow: hidden;
          display: inline-flex;
          justify-content: center;

          &.svg {
            padding: 0;
          }
        }
      }
    }
  }
</style>
