<template>
  <div id="page">
    <SvgIcon v-show="false" class="d-none" />
    <PageHeader />
    <PageTopMenu v-if="checkTopMenuNeedShow" />
    <UserDashBoard v-if="checkUserInfoNeedShow" />
    <client-only>
      <NavigationOfFollowedAndFollowing v-if="checkNavForFollowedPageNeedShow" />
    </client-only>
    <div
      id="page-content"
      :class="{
        'myEvent--background': $route.name === 'my-events',
        st_background_white: $route.name && $route.name.includes('demo'),
      }">
      <nuxt />
    </div>
    <PageFooter />
    <modalContract v-if="showContract" />
  </div>
</template>
<script>
  import modalContract from '~/components/forms/modalContract'
  import NavigationOfFollowedAndFollowing from '~/components/views/follow/NavigationOfFollowedAndFollowing'
  import PageFooter from '~/components/regions/PageFooter'
  import PageHeader from '~/components/regions/PageHeader'
  import PageTopMenu from '~/components/menus/PageTopMenu'
  import SvgIcon from '~/static/images/svg_icon/svg_icon.svg'
  import UserDashBoard from '~/components/views/UserDashBoard'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'Default',
    head () {
      return {
        bodyAttrs: {
          class: [`page-${this.$route.name}`, this.is_login ? 'logged-in' : 'not-logged-in', 'darkBackground'].join(' '),
        },
      }
    },
    components: {
      PageHeader,
      PageTopMenu,
      PageFooter,
      SvgIcon,
      modalContract,
      UserDashBoard,
      NavigationOfFollowedAndFollowing,
    },
    data () {
      return {
        notifyInterval: null,
      }
    },
    computed: {
      ...mapGetters(['user', 'is_login']),
      showContract () {
        return (
          this.user.serial !== 0
          && this.user.my_info.serial !== 0
          && this.user.my_info.service
          && this.user.my_info.service.contract
          && this.user.my_info.service.contract.agree
          && this.user.my_info.service.contract.agree === -1
          && this.$route.name !== 'terms-of-service'
          && this.$route.name !== 'terms-of-privacy'
        )
      },
      checkUserInfoNeedShow () {
        return (
          [
            // u-uid 或 o-oas 的路由會顯示
            /^(u-uid|o-oas)(?!-)/,
            // 只要不是 u-uid-service-product-pid-oid 的路由都會顯示
            /^(u-uid|o-oas)-(?!service-product-pid-oid)/,
          ].some(regex => regex.test(this.$route.name))
          && !(
            (this.$userCheck.isSame() && this.$userRouteActive(['-article-add', '-service-article-add']))
            || (this.windowWidth < 768
              && this.$userRouteActive(['-service-review', '-follow-followed', '-follow-followTo']))
            || (this.$userRouteActive('-service-events-manage') && !this.$userCheck.isAdmin())
          )
        )
      },
      checkTopMenuNeedShow () {
        return (
          [
            // 開頭是 my-watchlist、 my-portfolios 都要顯示
            /^my-(watchlist|portfolios)/,
            // 開頭是 demo 路由都顯示
            /^demo/,
          ].some(regex => regex.test(this.$route.name))
          && !(
            this.windowWidth < 768
            && (this.$route.name === 'my-portfolios-edit')
          )
        )
      },
      checkNavForFollowedPageNeedShow () {
        return this.windowWidth < 768 && this.$userRouteActive(['-follow-followed', '-follow-followTo'])
      },
    },
    methods: {
      ...mapActions('user', ['getMyUserNotifyCount', 'loginWithToken']),
    },
    beforeMount () {
      this.$root.$on('default-lineplugin', () => {
        this.$nextTick(() => {
          if (_.get(window, 'LineIt.loadButton', null)) {
            window.LineIt.loadButton()
          }
        })
      })
    },
    mounted () {
      this.notifyInterval = setInterval(() => {
        this.getMyUserNotifyCount(this.user.my_info.identity)
      }, 60000)
      this.loginWithToken()
    },
    destroyed () {
      if (window && this.notifyInterval) {
        clearInterval(this.notifyInterval)
      }
    },
  }
</script>

<style lang="scss">
.myEvent--background {
  background: #f6f6f6;

  @include media-breakpoint-up(md) {
    background: $st_color_white;
  }
}

#page {
  padding-top: 50px;
}

body {
  &.page-about,
  &.page-become-expert {
    #page-content {
      max-width: 100%; // override static pages
    }
  }

  #page-content {
    min-height: 65vh;
  }

  &.page-event-eid-ticket,
  &.page-event-eid-ticket-refund,
  &.page-event-eid-ticket-signup {
    min-width: 1200px;
  }

  &.page-liff-generate {
    min-width: 1024px;
  }

  &.page-u-uid-videos {
    min-width: 992px;
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}

.slide-enter {
  transform: translateY(100%);
}

.slide-leave-to {
  transform: translateY(200%);
}
</style>
