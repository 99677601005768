import apiUtils from '~/utils/api'

export const actions = {
  getEvent ({}, option) {
    // option = {
    //   identity: <String>,
    //   drafted : <Boolean>,
    //   kind  : <Array>
    //   fields  : <Array>
    // }
    const { identity } = option
    const drafted = _.isUndefined(option.drafted) ? false : option.drafted
    const kind = option.kind || null
    const fields = option.fields || ['identity', 'kind', 'banner', 'cover', 'account.identity', 'account.avatar', 'account.name', 'account.serial', 'title', 'summary', 'content', 'description', 'introduction', 'contact', 'period', 'attention', 'refund', 'drafted', 'approved', 'detail', 'staff', 'categories.identity', 'categories.name']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          value:    identity,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
    }
    if (!_.isNull(drafted)) {
      query.filter.push({
        field:    'drafted',
        value:    drafted,
        operator: '=',
        logic:    0,
      })
    }
    if (kind) {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: 'In',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/event${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getEvents ({}, option) {
    // option = {
    //   search  : <String>,
    //   identity  : <Array>,
    //   kind    : <String>, all || online || offline || game
    //   drafted : <Boolean>,
    //   categories: <Array>,
    //   account: <Array>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const search = option.search || null
    const identity = option.identity || null
    const account = option.account || null
    const kind = option.kind || 'all'
    const categories = option.categories || null
    const drafted = _.isUndefined(option.drafted) ? false : option.drafted
    const fields = option.fields || ['identity', 'kind', 'cover', 'categories.identity', 'categories.name', 'account.identity', 'account.avatar', 'account.name', 'account.serial', 'account.service', 'account.organization.kind', 'account.kind', 'period', 'title', 'summary', 'description']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const sort = option.sort || {
      approved:         -1,
      'status.created': -1,
    }
    const periodEnd = option.periodEnd || null
    const periodEndOperator = option.periodEndOperator || '>'
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (periodEnd) {
      query.filter.push({
        field:    'period.end',
        value:    periodEnd,
        operator: periodEndOperator,
        logic:    0,
      })
    }
    if (!_.isNull(drafted)) {
      query.filter.push({
        field:    'drafted',
        value:    drafted,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(search)) {
      query.filter.push({
        field:    'title',
        value:    search,
        operator: 'Like',
        logic:    0,
      })
    }
    if (!_.isNull(identity)) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: 'In',
        logic:    0,
      })
    }
    if (!_.isNull(account)) {
      query.filter.push({
        field:    'account',
        value:    account,
        operator: '=',
        logic:    0,
      })
    }
    if (kind === 'all' || Array.isArray(kind)) {
      query.filter.push({
        field:    'kind',
        value:    kind === 'all' ? ['online', 'offline', 'game'] : kind,
        operator: 'In',
        logic:    0,
      })
    } else {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(categories)) {
      query.filter.push({
        field:    'categories',
        value:    categories,
        operator: 'In',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/events${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getEventMyList ({}, option) {
    // option = {
    //   account  : <String>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const { account } = option
    const fields = option.fields || ['identity', 'title', 'cover', 'period', 'detail.address']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const sort = option.sort || {
      'status.created': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'account',
          value:    account,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/event/my/list${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getEventMyOne ({}, option) {
    // option = {
    //   identity: <String>,
    //   account  : <String>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const { account, identity } = option
    const fields = option.fields || ['identity', 'kind', 'title', 'cover', 'description', 'categories.identity', 'categories.name', 'account.identity', 'period', 'detail.address', 'detail.addressInfo', 'orderManifests']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const sort = option.sort || {
      'status.created': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'account',
          value:    account,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          value:    identity,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/event/my/one${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createEvent ({}, event) {
    // event = {
    //   kind        : <String>, online || offline || game // 類型
    //   banner      : <Url>, // 活動橫幅(暫未使用)
    //   cover       : <Url>, // 封面圖片
    //   categories  : <Array>, // 活動投資領域
    //   title       : <String>,
    //   introduction: <String>, // 活動副標題
    //   description : <String: Longtext>, // 活動簡介
    //   content     : <String: Html>, // 活動說明
    //   summary     : <String: Longtext>, // 活動說明(擷取文字)
    //   attention   : <String: Longtext>, // 活動注意事項說明
    //   refund      : <String>, none || scantrader // 活動退款方式
    //   staff       : <Array>, // 工作人員
    //   drafted     : <Boolean>, // 草稿
    //   account     : <String>,
    //   approved    : <Number: Timestamp>, // 活動上架時間
    //   period      : { // 活動期間
    //     start : <Number: Timestamp>, // 開始
    //     end   : <Number: Timestamp> // 結束
    //   },
    //   contact: { // 主辦者資料
    //     phone    : <String>, // 聯絡電話
    //     email    : <String Email>, // 電子信箱
    //     lineAt   : <String Link>, // LINE@
    //     fbFanPage: <String Link>, // Facebook 粉絲專頁
    //   },
    //   detail: { // kind = game 競技場詳細資料
    //     path      : <String>, // 競技場子路徑
    //     stockGame : <String>, // 競技場對應編號
    //     playing   : <Boolean>, // 開放中
    //     logos     : [ // Array 廠商圖片
    //       {
    //         title   : <String>, // 圖片 alt 文字
    //         content : <String Link>, // 圖片網址
    //         link    : <String Link>, // 圖片連結
    //         maxWidth: <String> // 圖片最大寬度 default:100%
    //       }
    //     ],
    //     background: { // 背景資訊
    //       color: <String Color>, // 全頁背景顏色，色碼
    //       md   : <String Link>, // 桌機板>=768背景圖片網址
    //       sm   : <String Link>, // 手機板背景圖片網址
    //     },
    //     cover: { // 封面圖片
    //       sm: [ // Array 手機板封面圖片
    //         {
    //           title   : <String>, // 圖片 alt 文字
    //           content : <String Link>, // 圖片網址
    //           link    : <String Link>, // 圖片連結
    //           maxWidth: <String> // 圖片最大寬度 default:100%
    //         }
    //       ],
    //       md: [ // Array 桌機板>=768封面圖片
    //         {
    //           title   : <String>, // 圖片 alt 文字
    //           content : <String Link>, // 圖片網址
    //           link    : <String Link>, // 圖片連結
    //           maxWidth: <String> // 圖片最大寬度 default:100%
    //         }
    //       ],
    //       sections: [ // 區域內容
    //         title   : <String>, // 區域標題
    //         subTitle: <String>, // 區域副標題
    //         kind    : <String>, text || prize || timeline || reason // 區域顯示類型
    //         content : [ // 區域內容資訊
    //           <String Html>, // kind = text 內容
    //           {//  kind = prize 獎勵
    //             tabName    : <String>, // 獎勵名稱
    //             description: <String Longtext>, // 獎勵說明
    //             ranks      : [ // Array 獎勵名次
    //               label   : <String>, // 名稱
    //               kind    : <String>, text || money // 類型
    //               content : <String> // 內容
    //             ],
    //             tabLogo: {
    //               normal : <String Link>, // 正常分頁圖片網址
    //               hover  : <String Link>, // hover分頁圖片網址
    //               active : <String Link>, // active分頁圖片網址
    //             },
    //             logo: {
    //               sm: <String Link>, // 手機板圖片網址
    //               md: <String Link>, // 桌機板>=768圖片網址
    //             }
    //           },
    //           { // kind = timeline 時程表
    //             year  : <String>, // 年分
    //             date  : <String>, //  日期
    //             motion: <String>, // 內容
    //           },
    //           { // kind = reason 原因
    //             logo: { // 圖片
    //               sm: <String Link>, // 手機板封面圖片
    //               md: <String Link>, // 桌機板>=768封面圖片
    //             },
    //             title      : <String>, // 標題
    //             description: <String Longtext>, // 說明
    //           }
    //         ]
    //       ]
    //     }
    //   },
    //   detail: { // kind = offline 實體活動詳細資料
    //     address    : <String>, // 地址
    //     addressInfo: <String>, // 地址補充說明
    //     location   : {
    //       type        : <String>, Point // 詳細地址類型
    //       coordinates: [<Number>] // 座標
    //     }
    //   },
    //   detail: { // kind = online 線上活動詳細資料
    //     externalLink: <String Link> // 活動網址
    //   }
    // }
    const body = {
      data: {
        type:       'event',
        attributes: event,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/event`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateEvent ({ commit }, event) {
    // event = {
    //   identity    : <String>
    //   kind        : <String>, online || offline || game // 類型
    //   banner      : <Url>, // 活動橫幅(暫未使用)
    //   cover       : <Url>, // 封面圖片
    //   categories  : <Array>, // 活動投資領域
    //   title       : <String>,
    //   introduction: <String>, // 活動副標題
    //   description : <String: Longtext>, // 活動簡介
    //   content     : <String: Html>, // 活動說明
    //   summary     : <String: Longtext>, // 活動說明(擷取文字)
    //   attention   : <String: Longtext>, // 活動注意事項說明
    //   refund      : <String>, none || scantrader // 活動退款方式
    //   staff       : <Array>, // 工作人員
    //   drafted     : <Boolean>, // 草稿
    //   approved    : <Number: Timestamp>, // 活動上架時間
    //   period      : {}, <Object>
    //   contact     : {}, <Object>
    //   detail      : {}, <Object>
    // }
    const {
      identity, kind, banner, cover,
      categories, title, introduction,
      description, content, summary, attention,
      refund, staff, drafted, approved, period, contact, detail,
    } = event

    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    const body = {
      data: {
        type:       'event',
        id:         identity,
        attributes: {
          kind,
          banner,
          cover,
          categories,
          title,
          introduction,
          description,
          content,
          summary,
          attention,
          refund,
          staff,
          drafted,
          approved,
          period,
          contact,
          detail,
        },
      },
    }

    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/event${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteEvent ({}, option) {
    // option = {
    //   identity : <String>,
    // }
    const { identity } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
      fields: ['identity'],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/event${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
