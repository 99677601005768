import '~/node_modules/viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'

Vue.use(Viewer, {
  defaultOptions: {
    zIndex:        9999,
    inline:        false,
    button:        true,
    navbar:        true,
    title:         false,
    tooltip:       true,
    movable:       true,
    zoomable:      true,
    zoomOnTouch:   true,
    zoomOnWheel:   false,
    rotatable:     false,
    scalable:      true,
    transition:    false,
    fullscreen:    true,
    keyboard:      true,
    limitMovement: true,
    swipeable:     true,
    zoomTo:        2,
    moveTo:        1100,
    toolbar:       {
      zoomIn:   4,
      zoomOut:  4,
      oneToOne: 0,
      reset:    0,
      prev:     4,
      play:     {
        show: 0,
        size: 'large',
      },
      next:           4,
      rotateLeft:     0,
      rotateRight:    0,
      flipHorizontal: 0,
      flipVertical:   0,
    },
    className:    'vviewer',
    loading:      true,
    slideOnTouch: true,
    filter (image) {
      return !image.dataset.disablevviewer
    },
  },
})
