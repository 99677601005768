import apiUtils from '~/utils/api'

export const actions = {
  getPortfolios ({}, option) {
    // option = {
    //   identity  : <Array>,
    //   tickerSymbol  : <String> || <Array>,
    //   buyType   : <String>, long || short
    //   kindOrder  : <Number>,
    //   account: <String> || <Array>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const identity = option.identity || null
    const tickerSymbol = option.tickerSymbol || null
    const buyType = option.buyType || null
    const kindOrder = option.kindOrder || null
    const account = option.account || null
    const fields = option.fields || ['identity', 'kindOrder', 'order', 'buyType', 'price', 'amount', 'tickerSymbol.tickerSymbol', 'tickerSymbol.name', 'canPortfolio']
    // ['identity', 'accountType', 'portfolioList', 'kindOrder', 'buyType', 'price', 'amount', 'memo', 'order', 'account', 'tradeType', 'tickerSymbol', 'country',
    // 'account.identity', 'account.avatar', 'account.name', 'account.serial', 'account.kind', 'account.organization.alias',
    // 'tickerSymbol.name', 'tickerSymbol.tickerSymbol',
    // 'lastTick', 'pTicks', 'canPortfolio', 'cost', 'realTotal', 'profit', 'roi']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const sort = option.sort || {
      kindOrder: -1,
      order:     1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(identity)) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: _.isArray(identity) ? 'In' : '=',
        logic:    0,
      })
    }
    if (!_.isNull(tickerSymbol)) {
      query.filter.push({
        field:    'tickerSymbol',
        value:    tickerSymbol,
        operator: _.isArray(tickerSymbol) ? 'In' : '=',
        logic:    0,
      })
    }
    if (!_.isNull(buyType)) {
      query.filter.push({
        field:    'buyType',
        value:    buyType,
        operator: _.isArray(buyType) ? 'In' : '=',
        logic:    0,
      })
    }
    if (!_.isNull(kindOrder)) {
      query.filter.push({
        field:    'kindOrder',
        value:    kindOrder,
        operator: _.isArray(kindOrder) ? 'In' : '=',
        logic:    0,
      })
    }
    if (!_.isNull(account)) {
      query.filter.push({
        field:    'account',
        value:    account,
        operator: _.isArray(account) ? 'In' : '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/portfolios${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createPortfolio ({}, portfolio) {
    // portfolio = {
    //   tickerSymbol : <String>,
    //   tradeType    : <String>,
    //   country      : <String>,
    //   account      : <String>,
    //   price        : <Number:>,
    // }
    const body = {
      data: {
        type:       'portfolio',
        attributes: portfolio,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/portfolio`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createPortfolioWithAmount ({ dispatch }, portfolio) {
    // portfolio = {
    //   tickerSymbol : <String>,
    //   tradeType    : <String>,
    //   country      : <String>,
    //   account      : <String>,
    //   buyType      : <String>,
    //   price        : <Number>,
    //   amount       : <Number>,
    // }
    const add = {
      tickerSymbol: portfolio.tickerSymbol,
      tradeType:    portfolio.tradeType,
      country:      portfolio.country,
      account:      portfolio.account,
      price:        portfolio.price,
    }
    const patch = {
      identity: null,
      buyType:  portfolio.buyType,
      amount:   portfolio.amount,
    }
    return this.dispatch('api/portfolio/createPortfolio', add)
      .then(data => {
        if (data.error) return data
        patch.identity = data.identity
        return this.dispatch('api/portfolio/updatePortfolio', patch)
      })
      .catch(apiUtils.errorHandler)
  },
  updatePortfolio ({}, portfolio) {
    // portfolio = {
    //   identity  : <String>,
    //   buyType   : <String>,
    //   price     : <Number>,
    //   amount    : <Number>,
    //   order     : <Number>,
    //   memo      : <String: LongText>,
    // }
    const {
      identity, buyType, price, amount, order, memo,
    } = portfolio

    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    const body = {
      data: {
        type:       'portfolio',
        id:         identity,
        attributes: {
          buyType,
          price,
          amount,
          order,
          memo,
        },
      },
    }

    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/portfolio${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deletePortfolio ({}, option) {
    // option = {
    //   identity : <String>,
    // }
    const { identity } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
      // fields: ['identity']
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/portfolio${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deletePortfolios ({}, option) {
    // option = {
    //   account :     <String>,
    //   tickerSymbol: <String>,
    // }
    const { account, tickerSymbol } = option
    const body = {
      data: {
        account,
        tickerSymbol,
      },
    }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/portfolio/bulk/remove`, body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  addCost ({}, costData) {
    // costData = {
    //   tickerSymbol   : <String>,
    //   buyType        : <String>,
    //   account        : <String>,
    //   price          : <Number>,
    //   amount         : <Number>,
    //   historyIndex   : <String>,
    //   userFingerprint: <String>,
    // }
    const body = {
      data: {
        type:       'portfolio',
        attributes: costData,
      },
    }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/portfolio/addCost`, body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
