<template>
  <a v-bind="$attrs" :href="v3Url">
    <slot />
  </a>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      link: {
        type:    String,
        default: '/',
      },
    },
    computed: {
      ...mapGetters(['siteSetting']),
      v3Url () {
        return `${process.env.scantraderUrlV3}${this.link}`
      },
    },
  }
</script>

<style scoped>

</style>
