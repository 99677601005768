import apiUtils from '~/utils/api'

export const actions = {
  getProduct ({}, option) {
    // option = {
    //   identity: <String>,
    //   drafted : <Boolean>,
    //   kind  : <Array>
    //   fields  : <Array>
    // }
    const { identity } = option
    const drafted = _.isUndefined(option.drafted) ? false : option.drafted
    const kind = option.kind || null
    const fields = option.fields || ['identity', 'kind', 'account.identity', 'account.avatar', 'account.name', 'account.serial', 'title', 'content', 'price', 'maximum', 'buyPeriod', 'buyConditions', 'unavailableBuyOption', 'detail', 'drafted']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          value:    identity,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
    }
    if (!_.isNull(drafted)) {
      query.filter.push({
        field:    'drafted',
        value:    drafted,
        operator: '=',
        logic:    0,
      })
    }
    if (kind) {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: 'In',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/product${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getProducts ({}, option) {
    // option = {
    //   search  : <String>,
    //   identity  : <Array>,
    //   kind    : <String>, all || online || offline || game
    //   drafted : <Boolean>,
    //   detailKind: <String>,
    //   detailEvent: <String>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const search = option.search || null
    const identity = option.identity || null
    const kind = option.kind || 'all'
    const drafted = _.isUndefined(option.drafted) ? false : option.drafted
    const detailKind = option.detailKind || null
    const detailEvent = option.detailEvent || null
    const fields = option.fields || ['identity', 'kind', 'account.identity', 'account.avatar', 'account.name', 'account.serial', 'title', 'content', 'price', 'maximum', 'buyPeriod', 'buyConditions', 'unavailableBuyOption', 'detail', 'drafted']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const sort = option.sort || {
      approved:         -1,
      'status.created': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(drafted)) {
      query.filter.push({
        field:    'drafted',
        value:    drafted,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(search)) {
      query.filter.push({
        field:    'title',
        value:    search,
        operator: 'Like',
        logic:    0,
      })
    }
    if (!_.isNull(identity)) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: 'In',
        logic:    0,
      })
    }
    if (!_.isNull(detailKind)) {
      query.filter.push({
        field:    'detail.kind',
        value:    detailKind,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(detailEvent)) {
      query.filter.push({
        field:    'detail.event',
        value:    detailEvent,
        operator: _.isArray(detailEvent) ? 'In' : '=',
        logic:    0,
      })
    }
    if (kind === 'all') {
      query.filter.push({
        field:    'kind',
        value:    ['ticket'],
        operator: 'In',
        logic:    0,
      })
    } else {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/products${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createProduct ({}, product) {
    // product = {
    //   kind        : <String>, ticket // 類型
    //   title       : <String>, // 名稱
    //   price       : <Number>, // 價格
    //   maximum     : <Number>, // 可購買數量
    //   content     : <String: Html>, // 說明
    //   drafted     : <Boolean>, // 草稿
    //   account     : <String>,
    //   buyPeriod   : { // 購買期間
    //     start : <Number: Timestamp>, // 開始
    //     end   : <Number: Timestamp> // 結束
    //   },
    //   buyConditions: { // 票券購買身分限制
    //     operator: <String>. all(適用所有人) || channel&&event(服務項目和活動) || channel||event(服務項目或活動) // 限制模式
    //     channel: { // 限制服務項目
    //       type: <String>, all(不限制) || atLeastOne(全部訂閱者) || whitelist(指定方案訂閱者) // 服務項目購買身分限制
    //       list: [<String Channel identity>] // Array type=whitelist 須至少帶一個服務項目
    //     }
    //     event: { // 限制活動
    //       type: <String>, all(不限制)|| whitelist(指定活動之報名者) // 活動購買身分限制
    //       list: [<String Event identity>] // Array type=whitelist 須至少帶一個活動
    //     }
    //   },
    //   unavailableBuyOption: { // 不允許的購買選項
    //     paymentMethod: <Array>, 'LINEPAY/NORMAL' || 'TAPPAY/CREDIT' || 'ECPAY/CVS' || 'ECPAY/ATM' // 不允許的付款方式
    //   }
    //   detail: { // kind = ticket 票券詳細資料
    //     kind      : <String>, event // 票券類型
    //     event     : <String>, // 票券活動 identity
    //     usePeriod : { // 票券使用期間
    //       start : <Number: Timestamp>, // 開始
    //       end   : <Number: Timestamp> // 結束
    //     },
    //   },
    // }
    const body = {
      data: {
        type:       'product',
        attributes: product,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/product`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateProduct ({ commit }, product) {
    // product = {
    //   identity    : <String>
    //   kind        : <String>, ticket // 類型
    //   title       : <String>, // 名稱
    //   price       : <Number>, // 價格
    //   maximum     : <Number>, // 可購買數量
    //   content     : <String: Html>, // 說明
    //   drafted     : <Boolean>, // 草稿
    //   buyPeriod   : {}, <Object>
    //   buyConditions: {}, <Object>
    //   unavailableBuyOption: {}, <Object>
    //   detail     : {}, <Object>
    // }
    const {
      identity, kind, title, price,
      maximum, content, drafted, buyPeriod,
      buyConditions, unavailableBuyOption, detail,
    } = product

    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    const body = {
      data: {
        type:       'product',
        id:         identity,
        attributes: {
          kind,
          title,
          price,
          maximum,
          content,
          drafted,
          buyPeriod,
          detail,
          buyConditions,
          unavailableBuyOption,
        },
      },
    }

    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/product${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteProduct ({}, option) {
    // option = {
    //   identity : <String>,
    // }
    const { identity } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
      fields: ['identity'],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/product${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
