import apiUtils from '~/utils/api'

export const actions = {
  getSubscription ({}, option) {
    // option = {
    //   identity        : <String>,
    //   account         : <String>,
    //   expired         : <Number>,
    //   expiredOperator : <operator>,
    //   trial           : <Number>,
    //   channel         : <String>
    //   withDeleted     : <Boolean>
    // }
    const identity = option.identity || null
    const account = option.account || null
    const expired = option.expired || null
    const expiredOperator = option.expiredOperator || '>='
    const trial = option.trial || null
    const channel = option.channel || null
    const withDeleted = option.withDeleted || false
    const query = {
      filter: [],
    }
    if (!withDeleted) {
      query.filter.push({
        field:    'status.deleted',
        value:    null,
        operator: '=',
        logic:    0,
      })
    }
    if (account) {
      query.filter.push({
        field:    'account',
        value:    account,
        operator: '=',
        logic:    0,
      })
    }
    if (channel) {
      query.filter.push({
        field:    'channel',
        value:    channel,
        operator: '=',
        logic:    0,
      })
    }
    if (expired) {
      if (expired === 'now') {
        query.filter.push({
          field:    'expired',
          value:    new Date().valueOf(),
          operator: expiredOperator,
          logic:    0,
        })
      } else {
        query.filter.push({
          field:    'expired',
          value:    expired,
          operator: expiredOperator,
          logic:    0,
        })
      }
    } else if (identity) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: '=',
        logic:    0,
      })
    } else if (trial) {
      query.filter.push({
        field:    'trial',
        value:    null,
        operator: trial === 1 ? '<>' : '=',
        logic:    0,
      })
    } else {
      return false
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/subscription${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getSubscriptions ({}, option) {
    // option = {
    //   account         : <String>,
    //   channel         : <Array>,
    //   expired         : <Number>,
    //   expiredOperator : <operator>,
    //   fields          : <Array>,
    //   page            : <Number>,
    //   limit           : <Number>,
    //   sort            : <Object>,
    // }
    const { account } = option
    const channel = option.channel || null
    const expired = option.expired || null
    const expiredOperator = option.expiredOperator || '>='
    const fields = option.fields || ['identity', 'account.identity', 'account.avatar', 'account.serial', 'channel', 'expired']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (
      page - 1)
    const sort = option.sort || { expired: -1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'account',
          value:    account,
          operator: 'In',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (channel) {
      query.filter.push({
        field:    'channel',
        value:    channel,
        operator: 'In',
        logic:    0,
      })
    }
    if (expired) {
      if (expired === 'now') {
        query.filter.push({
          field:    'expired',
          value:    new Date().valueOf(),
          operator: expiredOperator,
          logic:    0,
        })
      } else {
        query.filter.push({
          field:    'expired',
          value:    expired,
          operator: expiredOperator,
          logic:    0,
        })
      }
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/subscriptions${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
