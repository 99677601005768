import apiUtils from '~/utils/api'

export const actions = {
  getSupport ({}, option) {
    // option = {
    //   identity : <String>,
    //   account  : <String>,
    //   question : <String>,
    //   answer   : <String>,
    //   fields   : <Array>
    // }
    const identity = option.identity || null
    const { account, question, answer } = option
    const fields = option.fields || ['identity', 'question', 'answer', 'order', 'account.identity', 'status.created']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: fields,
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
    } else {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
        {
          field:    'question',
          operator: '=',
          value:    question,
          logic:    0,
        },
        {
          field:    'answer',
          operator: '=',
          value:    answer,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/support${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getSupports ({}, option) {
    // option = {
    //   account : <String>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const { account } = option
    const fields = option.fields || ['identity', 'question', 'answer', 'order', 'account.identity', 'status.created']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (
      page - 1)
    const sort = option.sort || { 'status.created': 1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      sort:   sort,
      fields: fields,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/supports${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createSupport ({}, support) {
    // support = {
    //   account  : <String>,
    //   question : <String>,
    //   answer   : <String>,
    //   order   : <Number>,
    // }
    const body = {
      data: {
        type:       'support',
        attributes: support,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/support`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateSupport ({}, option) {
    // option = {
    //   identity : <String>,
    //   question : <String>,
    //   answer   : <String>,
    //   order    :<Number>,
    // }
    const {
      identity, question, answer, order,
    } = option

    const body = {
      data: {
        type:       'support',
        id:         identity,
        attributes: {
          question: question,
          answer:   answer,
          order:    order,
        },
      },
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    option.identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/support${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteSupport ({}, option) {
    // option = {
    //   identity : <String>,
    //   account  : <String>,
    //   question : <String>,
    //   answer   : <String>,
    // }
    const identity = option.identity || null
    const { account, question, answer } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: ['identity'],
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
    } else {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
        {
          field:    'question',
          operator: '=',
          value:    question,
          logic:    0,
        },
        {
          field:    'answer',
          operator: '=',
          value:    answer,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/support${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
