<template>
  <div class="page-footer st_background_gray_darker">
    <b-container>
      <b-row class="align-items-center">
        <b-col cols="12" md="3" class="mb-3 mb-md-0">
          <div class="text-left">
            <h3 class="mb-2">
              {{ siteName }}
            </h3>
            <h5 class="footer-copyright-text">
              © 2018 Quants AI. All rights reserved.
            </h5>
            <h5 class="business-operator-text">
              營業人名稱：曠世智能股份有限公司｜統一編號：55860334
            </h5>
          </div>
          <div>
            <span class="mb-2 mr-2 text-contact"> 聯繫我們 </span>

            <b-link :href="`mailto:${setting.mail}`" class="link mr-2 link p-0 align-middle">
              <i class="fas fa-envelope" />
            </b-link>

            <b-link :href="setting.line.friend_url" class="p-0 link align-middle" target="_blank">
              <i class="fab fa-line" />
            </b-link>

            <div class="align-middle mt-2">
              <b-link :href="`https://line.me/R/ti/p/${setting.line.id}`" target="_blank">
                <svg-icon name="icon_line_addfriend" width="86" height="20" class="d-block" />
              </b-link>
            </div>
            <div class="text-center text-md-left">
              <h6 class="mb-2" />
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="9" class="text-center mb-4 footer-menu-frame">
          <FooterMenu id="footer-menu" class="justify-content-center align-self-center" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import FooterMenu from '~/components/menus/FooterMenu'
  import { mapState } from 'vuex'

  export default {
    components: {
      FooterMenu,
    },
    props: {
      logo: {
        type:    Object,
        default: null,
      },
    },
    data () {
      return {
        siteName: process.env.SEO.title,
      }
    },
    computed: {
      ...mapState(['setting']),
    },
    mounted () {
      this.$root.$emit('default-lineplugin')
    },
  }
</script>
<style lang="scss" scoped>
.page-footer {
  padding: 36px 10px;
  overflow: hidden;
  color: white;

  .link {
    color: white;
  }

  .footer-menu-frame {
    top: -21px;

    @media (max-width: 765px) {
      top: 0;
    }

    @media (max-width: 365px) {
      top: 0;
    }
  }

  .footer-menu-frame a {
    color: white;
    opacity: 0.5;
    top: -21px;

    @media (max-width: 765px) {
      top: 0;
    }

    @media (max-width: 365px) {
      top: 0;
    }
  }

  .footer-copyright-text {
    white-space: nowrap;
    font-size: $st_font-size-small;
    font-weight: $st_font-weight-light;
    letter-spacing: 0.7px;
    color: white;
  }

  .business-operator-text {
    white-space: nowrap;
    font-size: $st_font-size-small;
    font-weight: $st_font-weight-light;
    letter-spacing: 0.7px;
    color: white;

    @media (max-width: 765px) {
      white-space: normal;
    }

    @media (max-width: 365px) {
      white-space: normal;
    }
  }

  .text-contact {
    color: white;
    font-size: $st_font-size-base;
    font-weight: $st_font-weight-light;
  }
}

</style>
