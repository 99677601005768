<template>
  <div class="search-form">
    <b-form @submit.prevent="onSubmit">
      <div class="form-inner d-flex align-items-center relative">
        <b-form-input class="search-keyword" type="text" placeholder="搜尋 ScanTrader" v-model="keyword" />
        <b-button type="submit" value="搜尋" class="d-none d-sm-inline-block">
          <svg-icon name="icon_search_black" :width="20" :height="20" />
        </b-button>
        <!-- mobile only -->
        <svg-icon name="icon_search_white" :width="22" :height="22" class="icon-mobile-search d-sm-none" />
      </div>
    </b-form>
  </div>
</template>

<script>
  export default {
    components: {},
    data () {
      return {
        showSearchFund: false,
        searchFundUrl:  '',
        keyword:        '',
        type:           this.$route.query.type || 'all',
        options:        [
          {
            value: 'fund',
            text:  '標的',
          },
          {
            value: 'u',
            text:  '用戶',
          },
          {
            value: 'article',
            text:  '觀點',
          },
          {
            value: 'service',
            text:  '達人',
          },
        ],
      }
    },
    computed: {},
    watch:    {
      type:           'changeSearchType',
      '$route.query': 'changeSearchValue',
    },
    methods: {
      changeSearchType () {
        if (this.$route.name === 'search') {
          this.onSubmit()
        }
      },
      changeSearchValue () {
        if (!this.$userRouteActive(['-service-articles', '-articles'])) {
          this.keyword = this.$route.query.q || ''
        }
        this.type = this.$route.query.type || 'all'
      },
      onSubmit () {
        if (this.keyword !== '') {
          this.$router.push({
            name:  'search',
            query: {
              type: this.type,
              q:    this.keyword,
            },
          })
        }
      },
    },
    created () {
      this.keyword = !this.$userRouteActive(['-service-articles', '-articles']) ? this.$route.query.q || '' : ''
    },
  }
</script>

<style lang="scss" scoped>
.search-form {
  input[type='text'] {
    background-color: white;
    border-radius: 2px;
    border: 0;
    color: $st_color_gray_darker;
    padding: 0 0 0 20px;
    box-sizing: border-box;
    line-height: 2;
    margin: 3px 0;
    font-size: $st_font-size-base;
    font-weight: $st_font-weight-light;
    letter-spacing: $st_font_letter-spacing;

    &::-webkit-input-placeholder {
      color: $st_color_gray_dark;
    }

    &:focus::-webkit-input-placeholder {
      color: $st_color_gray_light;
    }

    @media only screen and (max-width: 480px) {
      padding-left: 34px;
      padding-top: 3px;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 30px #124cc4 inset;

      @important {
        -webkit-text-fill-color: rgba(#fff, 0.6);
      }
    }
  }

  .form-inner {
    position: relative;
  }

  .select-wrapper {
    position: relative;

    &:after {
      content: '▼';
      padding: 12px 8px;
      position: absolute;
      right: 12px;
      top: -8px;
      z-index: 1;
      text-align: center;
      width: 10%;
      height: 100%;
      pointer-events: none;
      color: white;
      font-size: $st_font-size-smaller;
    }
  }

  select.search-type.custom-select {
    font-size: $st_font-size-small;
    width: 61px;
    height: 24px;
    background-color: #4c84f8;
    color: white;
    border: 0;
    padding: 0 8px;
    background-image: none;
  }

  .search-keyword {
    padding: 6px 12px 6px 80px;
    height: calc(2rem + 2px);
    outline: none;

    @media only screen and (max-width: 480px) {
      padding-top: 1px;

      @important {
        color: white;
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }

  button.btn {
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 1px;
    right: 0;
    z-index: 1;

    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active {
      background-color: transparent;
      box-shadow: none;
    }

    i {
      opacity: 0.62;
    }
  }

  .icon-mobile-search {
    position: absolute;
    left: 5px;
    top: 10px;
  }
}
</style>
