import apiUtils from '~/utils/api'

const avatar = '/images/png_icon/mdpi/icon_profile_default.png'

export const state = () => ({
  default: {
    avatar: avatar,
  },
  my_info: {
    avatar: avatar,
    name:   {
      real: '',
      nick: '',
    },
    identity:                null,
    kind:                    'personal',
    serial:                  null,
    organization:            null,
    roles:                   [],
    subscriptions:           0,
    followTo:                0,
    followed:                0,
    collections:             0,
    firstTimeShowLineNotify: false,
  },
  user_info: {
    avatar: avatar,
    name:   {
      real: '',
      nick: '',
    },
    identity:      null,
    kind:          'personal',
    serial:        null,
    organization:  null,
    roles:         [],
    subscriptions: 0,
    followTo:      0,
    followed:      0,
    collections:   0,
    service:       {},
  },
  serial:          null,
  loginUrl:        '',
  fingerprint:     null,
  notifies:        null,
  notifyCount:     0,
  notifyRead:      null,
  exchange:        null,
  inLINELIFF:      false,
  articleFontSize: null,
  myOwnRoles:      {
    original: null,
    format:   {
      owner:              [],
      admin:              [],
      contentFreeCreator: [],
      contentFreeEditor:  [],
      contentPaidViewer:  [],
      contentPaidCreator: [],
      contentPaidEditor:  [],
      contentComment:     [],
      contentAdmin:       [],
      accountant:         [],
      userList:           [],
    },
    commentReply:     [],
    lastCommentReply: null,
    freeEditor:       [],
    lastFreeEditor:   null,
  },
})

export const mutations = {
  setExchange (state, serial) {
    state.my_info.exchange = serial
  },
  changeLoginUrl (state, ref = {
    url:  undefined,
    refs: {},
  }) {
    const { url, refs } = ref
    if (url) {
      state.loginUrl = url
      return
    }
    let currentUrl = ''
    if (location) {
      currentUrl = location.href
    }
    state.loginUrl = `https://access.line.me/oauth2/v2.1/authorize${
      apiUtils.getQueryStringByOptions(
        {
          scope:         refs.scope || 'profile%20openid',
          response_type: refs.response_type || 'code',
          state:         refs.state || encodeURIComponent(`{"redirectUrl":"${refs.redirectUrl || `${currentUrl}`}"}`),
          client_id:     refs.client_id || process.env.line.client_id,
          bot_prompt:    refs.bot_prompt || 'aggressive',
          redirect_uri:  refs.redirect_uri || `${process.env.apiUrlMypicks}/login_line`,
        },
      )
    }`
  },
  setUserSerial (state, serial) {
    if (!serial) {
      serial = 0
    }
    state.serial = Number(serial)
  },
  setMyUserInfo (state, info) {
    info.avatar = info.avatar || state.default.avatar
    if (info.service && info.service.approved && info.service.approved > this.$moment().valueOf()) {
      info.service = false
    }
    state.my_info = info
  },
  setExpertUserInfo (state, info) {
    info.avatar = info.avatar || state.default.avatar
    if (info.service && info.service.approved && info.service.approved > this.$moment().valueOf()) {
      info.service = false
    }
    state.user_info = info
  },
  setFingerprint (state, fingerprint) {
    state.fingerprint = fingerprint
  },
  setNotifies (state, result) {
    if (result.clear) {
      state.notifies = []
    }
    if (_.get(result, 'data.length', 0)) {
      for (let i = result.data.length; i--;) {
        state.notifies.unshift(result.data[i])
      }
    }
  },
  pushNotifies (state, result) {
    result.data = result.data.reverse()
    for (let i = result.data.length; i--;) {
      state.notifies.push(result.data[i])
    }
  },
  readNotify (state, data) {
    const index = _.findIndex(state.notifies, { identity: data.identity })
    if (index >= 0 && data.read) state.notifies[index].read = data.read
  },
  setNotifyCount (state, count) {
    state.notifyCount = count
  },
  setNotifyRead (state, identity) {
    if (_.isNil(identity) && !_.isEmpty(state.notifies)) {
      // eslint-disable-next-line prefer-destructuring
      identity = state.notifies[0].identity
    }
    if (!_.isNil(identity)) {
      state.notifyRead = identity
    }
    state.notifyCount = 0
  },
  setInLINELIFF (state, init) {
    state.inLINELIFF = init
  },
  setArticleFontSize (state, fontSize) {
    state.articleFontSize = fontSize
  },
  cloneUserInfo (state) {
    state.user_info = _.cloneDeep(state.my_info)
  },
  clearMyUserInfo (state) {
    state.my_info = {
      avatar: state.default.avatar,
      name:   {
        real: '',
        nick: '',
      },
      identity:                null,
      kind:                    'personal',
      serial:                  null,
      organization:            null,
      roles:                   [],
      subscriptions:           0,
      followTo:                0,
      followed:                0,
      collections:             0,
      firstTimeShowLineNotify: false,
    }
    state.serial = 0
    state.notify = null
    state.notifyCount = 0
    state.myOwnRoles = {
      original: null,
      format:   {
        owner:              [],
        admin:              [],
        contentFreeCreator: [],
        contentFreeEditor:  [],
        contentPaidViewer:  [],
        contentPaidCreator: [],
        contentPaidEditor:  [],
        contentComment:     [],
        contentAdmin:       [],
        accountant:         [],
        userList:           [],
      },
      commentReply:     [],
      lastCommentReply: null,
      freeEditor:       [],
      lastFreeEditor:   null,
    }
  },
  clearExpertUserInfo (state) {
    state.user_info = {
      avatar: state.default.avatar,
      name:   {
        real: '',
        nick: '',
      },
      identity:      null,
      kind:          'personal',
      serial:        null,
      organization:  null,
      roles:         [],
      subscriptions: 0,
      followTo:      0,
      followed:      0,
      collections:   0,
      service:       {},
    }
  },
  setMyOwnRoles (state, result) {
    state.myOwnRoles = {
      original: null,
      format:   {
        owner:              [],
        admin:              [],
        contentFreeCreator: [],
        contentFreeEditor:  [],
        contentPaidViewer:  [],
        contentPaidCreator: [],
        contentPaidEditor:  [],
        contentComment:     [],
        contentAdmin:       [],
        accountant:         [],
        userList:           [],
      },
      commentReply:     [],
      lastCommentReply: null,
      freeEditor:       [],
      lastFreeEditor:   null,
    }
    if (_.get(result, 'length', 0)) {
      state.myOwnRoles.original = result
      Object.keys(state.myOwnRoles.format).forEach(ownRole => {
        state.myOwnRoles.format[ownRole].push(
          ...result.filter(row => _.get(row, `${ownRole === 'owner' ? 'organization' : 'ownRoles'}.${ownRole}`, []).includes(state.my_info.identity)),
        )
      })
    }

    // 留言回覆權限
    const ownRolesCommentReplyList = this.$myOwnRolesList(['owner', 'admin', 'contentAdmin', 'contentComment'])
    if (ownRolesCommentReplyList.length) {
      state.myOwnRoles.commentReply = [_.cloneDeep(state.my_info), ...ownRolesCommentReplyList]
      state.myOwnRoles.lastCommentReply = state.myOwnRoles.commentReply[0].identity
    }

    // 免費文章編輯權限
    const ownRolesFreeEditorList = this.$myOwnRolesList(['owner', 'admin', 'contentAdmin', 'contentFreeEditor'])
    if (ownRolesFreeEditorList.length) {
      state.myOwnRoles.freeEditor = [_.cloneDeep(state.my_info), ...ownRolesFreeEditorList]
      state.myOwnRoles.lastFreeEditor = state.myOwnRoles.freeEditor[0].identity
    }
  },
  setMyOwnRolesLastCommentReply (state, account = {}) {
    if (_.get(account, 'identity', null)) {
      state.myOwnRoles.lastCommentReply = account.identity
    }
  },
  setMyOwnRolesLastFreeEditor (state, account = {}) {
    if (_.get(account, 'identity', null)) {
      state.myOwnRoles.lastFreeEditor = account.identity
    }
  },
}

export const actions = {
  getUserInfo ({ dispatch, commit }, arg) {
    if (_.isNil(arg)) return null
    let argOption = {
      serialOrAlias: arg,
    }
    if (_.isObject(arg)) argOption = arg
    const { serialOrAlias, isOrganizationAlias = false, retry = false } = argOption
    const option = {
      serial: serialOrAlias,
      fields: ['identity', 'kind', 'serial', 'organization', 'avatar', 'name', 'description', 'service', 'followed', 'followTo', 'collections', 'subscriptions', 'ownRoles', 'blockList', 'email', 'phone', 'belongOrg', 'belongOrgInfo'],
    }
    if (isOrganizationAlias) {
      option.kind = 'organization'
      option.organizationAlias = serialOrAlias
    } else {
      option.kind = 'personal'
      option.serial = serialOrAlias
    }
    return dispatch('api/account/getAccount', option, { root: true })
      .then(async account => {
        if (account && account.identity) {
          if (account.kind === 'organization' && _.isNil(_.get(account, 'organization.alias', null))) {
            return null
          }
          if (account.service && account.service.identity) {
            const serviceOption = {
              identity: account.service.identity,
              fields:   ['identity', 'account.identity', 'contract', 'totalPrice', 'hidden'],
            }
            const service = await dispatch('api/service/getService', serviceOption, { root: true })
            if (!service.error) {
              account.service = {
                ...account.service,
                ...service,
              }
            }
          }
          commit('setExpertUserInfo', account)
          return account
        } else if (!retry) {
          return dispatch('getUserInfo', {
            serialOrAlias,
            isOrganizationAlias: !isOrganizationAlias,
            retry:               true,
          })
        }
        return null
      })
  },
  getUserSerial ({ commit }, token) {
    const option = {}
    if (token) {
      option.headers = {
        Authorization: `Bearer ${token}`,
      }
    }
    return this.$axios.get(`${this.state.setting.apiUrlMypicks}/system/profileserial`, option)
      .then(res => {
        if (res.data.code === 2) {
          const error = {
            code:  2,
            data:  res.data.data,
            error: res.data.msg,
          }
          return error
        }

        const { data } = res.data
        let serial = 0
        if (data && data.serial) {
          // eslint-disable-next-line prefer-destructuring
          serial = data.serial
        }
        commit('setUserSerial', serial)
        return serial
      })
      .catch(() => 0)
  },
  getMyUserInfo ({ dispatch, commit, state }, token) {
    return dispatch('getUserSerial', token)
      .then(serial => {
        if (_.isObject(serial)) {
          throw serial
        }
        if (serial !== 0) {
          const option = {
            serial: serial,
          }
          return dispatch('api/account/getAccount', option, { root: true })
        }
      })
      .then(async account => {
        if (account && account.identity) {
          commit('setMyUserInfo', account)
          await dispatch('getMyUserNotifyCount', account.identity)
          const option = {
            ownRolesField: Object.keys(state.myOwnRoles.format).join(','),
            ownRolesId:    account.identity,
          }
          const myOwnRoles = await dispatch('api/account/getAccounts', option, { root: true })
          if (_.get(myOwnRoles, 'data.length', 0)) {
            commit('setMyOwnRoles', myOwnRoles.data)
          }
          return account
        }
        commit('setUserSerial', 0)
        return false
      })
      .then(async account => {
        if (account && account.service && account.service.identity) {
          const serviceOption = {
            identity: account.service.identity,
            fields:   ['identity', 'account.identity', 'company', 'code', 'rate', 'contract', 'hidden', 'totalPrice'],
          }
          const service = await dispatch('api/service/getService', serviceOption, { root: true })
          if (!service.error) {
            const newAccount = _.cloneDeep(account)
            newAccount.service = {
              ...account.service,
              ...service,
            }
            commit('setMyUserInfo', newAccount)
            return newAccount
          }
        }
        return account
      })
  },
  getMyUserNotifyCount ({ dispatch, state, commit }, account) {
    if (state.serial && state.serial !== 0) {
      const option = {
        account: [account || state.my_info.identity],
        read:    false,
        limit:   1,
      }
      if (state.notifyRead) {
        option.identity = state.notifyRead
      }
      return dispatch('api/notify/getNotifiesByAccount', option, { root: true })
        .then(data => {
          const count = !data.error ? data.page.total : 0
          commit('setNotifyCount', count)
          return count
        })
    }
  },
  logout ({ commit }) {
    return this.$axios.get(`${this.state.setting.apiUrlMypicks}/logout`)
      .then(res => {
        commit('clearMyUserInfo')
        return res.data
      })
  },
  loginWithToken () {
    /**
     * 登入 & 清除 Query
     */
    const { currentRoute } = this.$router

    if ('token' in currentRoute.query) {
      const ProcessDev = process.env.NODE_ENV !== 'production'
      const { token } = currentRoute.query
      const cookieDomain = location.hostname.split('.').length > 1 ? `.${location.hostname}` : location.hostname
      this.$cookies.set('token', token, {
        maxAge: 60 * 60 * 24 * 60,
        path:   '/',
        secure: !ProcessDev && process.env.prod,
        domain: cookieDomain,
      })
      const query = _.cloneDeep(currentRoute.query)
      !_.isUndefined(query.token) && delete query.token
      const routerReplace = {
        name:   currentRoute.name,
        params: currentRoute.params,
      }
      if (currentRoute.hash) {
        routerReplace.hash = currentRoute.hash
      }
      if (Object.keys(query).length) {
        routerReplace.query = query
      }
      this.$router.replace(routerReplace)
    }
  },
  logoutWithToken ({ dispatch }) {
    dispatch('logout')
      .then(() => {
        const ProcessDev = process.env.NODE_ENV !== 'production'
        const cookieDomain = location.hostname.split('.').length > 1 ? `.${location.hostname}` : location.hostname
        this.$cookies.remove('token', { domain: cookieDomain, path: '/', secure: !ProcessDev && process.env.prod })
        this.$cookies.remove('notifyRead', { domain: cookieDomain, path: '/' })
        this.$router.replace({ name: 'index' })
      })
  },
}
