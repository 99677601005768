<template>
  <b-modal
    @hidden="updateFirstTimeShowLineNotify"
    id="introductionOfLineNotificationModal"
    centered
    noCloseOnBackdrop
    noCloseOnEsc
    hideHeader
    size="md"
    modal-class="introductionOfLineNotificationModal"
    body-class="introductionOfLineNotificationModal__body d-flex flex-column text-center pb-0 mx-auto"
    footer-class="introductionOfLineNotificationModal__footer border-0 justify-content-center pt-3">
    <span class="st_title-medium-biggest mb-3">
      新功能上線！
    </span>
    <span class="st_caption-none-base mb-4">
      現在你可以在 LINE 上收到你關注的達人通知了！
    </span>
    <figure class="mx-3 mx-md-0">
      <b-img-lazy fluid src="/images/introduction-of-line-notification-phone.jpg" />
    </figure>
    <template slot="modal-footer">
      <b-button
        variant="close"
        @click.prevent="$root.$emit('bv::hide::modal', 'introductionOfLineNotificationModal')"
        class="st_main_xl introductionOfLineNotificationModal__close">
        我知道了
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters(['user']),
    },
    methods: {
      ...mapActions('api/account', ['updateAccount']),
      ...mapActions('user', ['getMyUserInfo']),
      updateFirstTimeShowLineNotify () {
        const data = {
          identity:                this.user.my_info.identity,
          firstTimeShowLineNotify: true,
        }
        this.updateAccount(data)
          .then(() => this.getMyUserInfo())
      },
    },
  }
</script>

<style lang="scss">
  .introductionOfLineNotificationModal {
    .modal-dialog {
      @include media-breakpoint-down(sm) {
        max-width: 375px;
        margin: 0 auto;
      }
    }

    &__close.btn {
      min-width: 200px;
    }

    &__body {
      padding-top: 50px;
      max-width: 347px;

      @include media-breakpoint-up(md) {
        max-width: 447px;
      }
    }

    &__footer {
      padding-bottom: 30px;
    }
  }
</style>
