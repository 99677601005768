import apiUtils from '~/utils/api'

export const actions = {
  getLineNotifyRelation ({}, option) {
    // option = {
    //   identity : <String>,
    //   account : <String>,
    //   kind    : <String>, article || account || channel
    //   index   : <String>,
    // }
    const identity = option.identity || null
    const { account, index } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: ['identity', 'account', 'index', 'mode'],
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
    } else {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
        {
          field:    'index',
          operator: '=',
          value:    index,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/lineNotifyRelation${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateLineNotifyRelation ({ commit }, lineNotifyRelation) {
    // lineNotifyRelation = {
    //   identity   : <String>,
    //   mode   : <String>,
    // }
    const { identity, mode } = lineNotifyRelation
    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    const body = {
      data: {
        type:       'lineNotifyRelation',
        id:         identity,
        attributes: {
          mode,
        },
      },
    }

    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/lineNotifyRelation${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
