import apiUtils from '~/utils/api'

export const actions = {
  getSecuritiesFirm ({}, option) {
    // option = {
    //   identity: <String>,
    //   pathName: <String>,
    //   hidden  : <Boolean>,
    //   fields  : <Array>
    // }
    const identity = option.identity || null
    const pathName = option.pathName || null

    const hidden = _.isUndefined(option.hidden) ? false : option.hidden
    const online = option.online || null
    const onlineOperator = option.onlineOperator || '<='
    const fields = option.fields || ['pathName', 'identity', 'kind', 'icon', 'name', 'keyWord', 'hidden', 'status', 'theme', 'kindCondition']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
    }

    if (identity) {
      query.filter.push(
        {
          field:    'identity',
          value:    identity,
          operator: '=',
          logic:    0,
        },
      )
    }

    if (pathName) {
      query.filter.push(
        {
          field:    'pathName',
          value:    pathName,
          operator: '=',
          logic:    0,
        },
      )
    }

    if (!_.isNull(hidden)) {
      query.filter.push({
        field:    'hidden',
        value:    hidden,
        operator: '=',
        logic:    0,
      })
    }
    if (online) {
      if (online === 'now') {
        query.filter.push({
          field:    'status.online',
          value:    new Date().valueOf(),
          operator: onlineOperator,
          logic:    0,
        })
      } else {
        query.filter.push({
          field:    'status.online',
          value:    online,
          operator: onlineOperator,
          logic:    0,
        })
      }
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/securitiesFirms${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getSecuritiesFirms ({}, option = {}) {
    // option = {
    //   kind    : <String> <Array>, Transaction || TransactionLink || RichMenu || Point || Link
    //   hidden  : <Boolean>,
    //   online  : <String>,
    //   onlineOperator  : <Operator>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const kind = option.kind || null
    const hidden = _.isUndefined(option.hidden) ? false : option.hidden
    const online = option.online || null
    const onlineOperator = option.onlineOperator || '<='
    const fields = option.fields || ['identity', 'kind', 'icon', 'name', 'keyWord', 'description', 'hidden', 'status', 'pathName', 'theme', 'kindCondition', 'serviceType', 'links']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const sort = option.sort || {
      'status.online': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(hidden)) {
      query.filter.push({
        field:    'hidden',
        value:    hidden,
        operator: '=',
        logic:    0,
      })
    }
    if (kind) {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: _.isArray(kind) ? 'In' : '=',
        logic:    0,
      })
    }
    if (online) {
      if (online === 'now') {
        query.filter.push({
          field:    'status.online',
          value:    new Date().valueOf(),
          operator: onlineOperator,
          logic:    0,
        })
      } else {
        query.filter.push({
          field:    'status.online',
          value:    online,
          operator: onlineOperator,
          logic:    0,
        })
      }
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/securitiesFirms${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getSecuritiesFirmTransactionLink ({}, option) {
    // option = {
    //   securitiesFirm: <String>,
    //   action: <String>,
    //   target: <String>,
    //   isDefault: <Boolean>,
    // }
    const { securitiesFirm } = option
    const action = option.action || null
    const target = option.target || null
    const isDefault = option.isDefault || false
    const query = {
      securitiesFirm: securitiesFirm,
      isDefault:      isDefault,
    }

    if (action) {
      query.action = action
    }

    if (target) {
      query.target = target
    }

    const params = apiUtils.getQueryStringByOptions(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/securitiesFirm/transactionLink${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  switchSecuritiesFirmMenu ({}, securitiesFirm) {
    const body = {
      securitiesFirm,
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/securitiesFirm/switch`,
      body)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  bindingSecuritiesFirmMenu ({}, securitiesFirm) {
    const body = {
      securitiesFirm,
      kind: 'RichMenu',
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/securitiesFirm/binding`,
      body)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  voteSecuritiesFirmMenu ({}, securitiesFirm) {
    const body = {
      securitiesFirm,
      kind: 'Vote',
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/securitiesFirm/binding`,
      body)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  switchDefaultSecuritiesFirm ({}, option) {
    // option = {
    //   kind: <String>, TransactionLink
    //   securitiesFirm: <String> null,
    // }
    const { kind } = option
    const securitiesFirm = option.securitiesFirm || null
    const body = {
      kind:           kind,
      securitiesFirm: securitiesFirm,
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/securitiesFirm/switchDefault`,
      body)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getSecuritiesFirmProducts ({}, option = {}) {
    // option = {
    //   securitiesFirm  : <String>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const { securitiesFirm } = option
    const fields = option.fields || ['identity', 'securitiesFirm', 'name', 'price', 'couponType.identity', 'couponType.discount', 'couponType.title', 'couponType.content']
    const page = option.page || 1
    const limit = option.limit || 100
    const offset = limit * (page - 1)
    const sort = option.sort || {
      'status.created': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'securitiesFirm',
          value:    securitiesFirm,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/securitiesFirmProducts${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createSecuritiesFirmProductOrder ({}, option = {}) {
    // order = {
    //   account:               <String>,
    //   securitiesFirmProduct: <String>,
    //   CustID:                <String>,
    // }
    const account = option.account || ''
    const securitiesFirmProduct = option.securitiesFirmProduct || ''
    const CustID = option.CustID || ''
    if (account === '' || securitiesFirmProduct === '' || CustID === '') {
      return { error: true }
    }
    const body = {
      data: {
        type:       'securitiesFirmProductOrder',
        attributes: {
          securitiesFirmProduct: securitiesFirmProduct,
          account:               account,
          postData:              {
            CustID: CustID,
          },
        },
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/securitiesFirmProduct/order`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
