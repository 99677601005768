<template>
  <span class="icon">
    <svg :width="`${width}px`" :height="`${height}px`">
      <use :xlink:href="`#${name}`" />
    </svg>
  </span>
</template>

<script>
  export default {
    name:  'SvgIcon',
    props: {
      name: {
        type:     String,
        required: true,
      },
      width: {
        type:    [Number, String],
        default: 16,
      },
      height: {
        type:    [Number, String],
        default: 16,
      },
    },
  }
</script>

<style scoped>
  .icon {
    color: inherit;
    display: inline-block;
    vertical-align: middle;
    fill: currentColor;
    font-size: 0;
  }
</style>
