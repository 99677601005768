import apiUtils from '~/utils/api'

export const actions = {
  getVideo ({}, option) {
    // option = {
    //   identity: <String>,
    //   fields  : <Array>
    // }
    const { identity, account } = option
    const fields = option.fields || ['identity', 'provide', 'title', 'summary', 'provideId', 'provideKey', 'state', 'cover', 'duration', 'account.identity', 'status']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          value:    identity,
          operator: '=',
          logic:    0,
        },
        {
          field:    'account',
          value:    account,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
    }

    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/video${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getVideos ({}, option) {
    // option = {
    //   identity: <Array>,
    //   account: <Array>,
    //   fields  : <Array>
    //   page: <Number>,
    //   limit: <Number>,
    // }
    const identity = option.identity || null
    const account = option.account || null
    const fields = option.fields || ['identity', 'provide', 'title', 'summary', 'provideId', 'provideKey', 'state', 'cover', 'duration', 'account.identity', 'status']
    const page = option.page || 1
    const limit = option.limit || 50
    const offset = limit * (page - 1)
    const sort = option.sort || { 'status.updated': -1 }

    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (identity) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: _.isArray(identity) ? 'In' : '=',
        logic:    0,
      })
    } else if (account) {
      query.filter.push({
        field:    'account',
        value:    account,
        operator: _.isArray(account) ? 'In' : '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/videos${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteVideo ({ dispatch }, option) {
    // option = {
    //   identity : <String>,
    // }
    const { identity } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
      // fields: ['identity']
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/video${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
