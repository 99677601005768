import '~/node_modules/vue-datetime/dist/vue-datetime.css'
import { Datetime } from 'vue-datetime'
import Vue from 'vue'

const {
  components, inheritAttrs, props, data, watch, created, computed, methods, render,
} = Datetime

const customizeDatetime = {
  components,
  inheritAttrs,
  props,
  data,
  created,
  computed,
  watch: {
    ...watch,
    isOpen: 'insertPopupToBody',
  },
  methods: {
    ...methods,
    async insertPopupToBody (isOpen) {
      if (isOpen) {
        await this.$nextTick()
        const popupWrapper = this.$el.querySelector('div:not([class])')
        popupWrapper.setAttribute('id', 'datetimePopup')
        document.body.appendChild(popupWrapper)
      } else {
        await this.$nextTick()
        const popupWrapper = document.body.querySelector('#datetimePopup')
        this.$el.appendChild(popupWrapper)
        popupWrapper.removeAttribute('id')
      }
    },
  },
  render,
}

Vue.component('Datetime', customizeDatetime)
