import apiUtils from '~/utils/api'

export const actions = {
  getRemindSetting ({}, option) {
    // option = {
    //   tickerSymbol  : <String>,
    //   account       : <String>,
    // }
    const tickerSymbol = option.tickerSymbol || null // '0050'
    const account = option.account || null // accountIdentity

    if (_.isNull(tickerSymbol)) console.error('field: "tickerSymbol" is required')
    if (_.isNull(account)) console.error('field: "account" is required')

    return this.$axios.get(`${this.state.setting.apiUrlSocial}/remind/setting?tickerSymbol=${tickerSymbol}&account=${account}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  createOrUpdateRemindSetting ({}, remindSettingData) {
    // remindSettingData = {
    //   account      : <String>,
    //   tickerSymbol : <String>,
    //   tradeType    : <String>,
    //   data: {
    //     remindIsOpened: <Boolean>,
    //     frequency     : <String>,
    //     trend         : <String>,
    //     mode          : <String>,
    //     percent       : <Number>,
    //     price         : <Number>,
    //     ma            : <Number>,
    //     source        : <String> (enum['share'], optional)
    //   }
    // }
    const {
      account, tickerSymbol, tradeType, data,
    } = remindSettingData

    const {
      remindIsOpened, frequency, trend, mode, percent, price, ma, source,
    } = data

    const body = {
      data: {
        account,
        tickerSymbol,
        tradeType,
        data: {
          remindIsOpened,
          frequency,
          trend,
          mode,
          percent,
          price,
          ma,
          source,
        },
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/remind/setting`, body)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getRemindSettings ({}, option) {
    // option = {
    //   account       : <String>,
    // }
    const account = option.account || null // accountIdentity

    return this.$axios.get(`${this.state.setting.apiUrlSocial}/remind/setting/list?account=${account}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getRemindMessages ({}, option) {
    // option = {
    //   account: <String>,
    //   page:    <Number>,
    //   limit:   <Number>
    // }
    const account = option.account || null
    const page = option.page || 1
    const limit = option.limit || 20

    return this.$axios.get(`${this.state.setting.apiUrlSocial}/remind/messages?account=${account}&page=${page}&limit=${limit}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
}
