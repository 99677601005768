import apiUtils from '~/utils/api'

export const actions = {
  getHotStocks ({ }, option) {
    // option = {
    //   fields  : <Array>,
    // }
    const fields = option.fields || ['tickerSymbol']
    const limit = option.limit || 12
    const query = {
      filter: [{
        field:    'tickerSymbol',
        value:    null,
        operator: '<>',
        logic:    0,
      }],
      fields: fields,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/hotCommandCode${params}`)
      .then(res => res.data)
    // .then(res => _.slice(res.data.data, 0, limit))
      .catch(apiUtils.errorHandler)
  },
}
