<template>
  <div class="full-page">
    <SvgIcon v-show="false" class="d-none" />
    <nuxt />
  </div>
</template>

<script>
  import SvgIcon from '~/static/images/svg_icon/svg_icon.svg'
  import { mapActions, mapMutations } from 'vuex'

  export default {
    name:       'Fullpage',
    components: {
      SvgIcon,
    },
    methods: {
      ...mapMutations('user', ['changeLoginUrl']),
      ...mapActions('user', ['loginWithToken']),
    },
    beforeMount () {
      /**
       * init LINE login url
       */
      this.changeLoginUrl()

      /**
       * 登入設定 Token & cookie
       */
      this.loginWithToken()
    },
  }
</script>
