import apiUtils from '~/utils/api'

export const actions = {
  getComment ({}, option) {
    // option = {
    //   identity: <String>,
    //   account : <String>,
    //   kind    : <String>, article || activity || account
    //   index   : <String>,
    //   content : <String>,
    //   fields  : <Array>
    // }
    const identity = option.identity || null
    const {
      account, kind, index, content, channelDeleted, channelLevel, channelQuicklyReplies,
    } = option
    const fields = option.fields || ['identity', 'kind', 'index', 'content', 'image', 'replies', 'account.identity', 'account.serial', 'account.avatar', 'account.name', 'account.service', 'account.ownRoles', 'account.belongOrg', 'account.belongOrgInfo', 'status.created']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: fields,
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
      if (account) {
        query.filter.push(
          {
            field:    'account',
            operator: '=',
            value:    account,
            logic:    0,
          },
        )
      }
      if (kind) {
        query.filter.push(
          {
            field:    'kind',
            operator: '=',
            value:    kind,
            logic:    0,
          },
        )
      }
      if (index) {
        query.filter.push(
          {
            field:    'index',
            operator: _.isArray(index) ? 'In' : '=',
            value:    index,
            logic:    0,
          },
        )
      }
    } else {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
        {
          field:    'index',
          operator: '=',
          value:    index,
          logic:    0,
        },
        {
          field:    'content',
          operator: '=',
          value:    content,
          logic:    0,
        },
      )
    }
    if (!_.isUndefined(channelDeleted)) {
      query.filter.push(
        {
          field:    'channel.deletedAt',
          operator: channelDeleted ? '<>' : '=',
          value:    null,
          logic:    0,
        },
      )
    }
    if (channelLevel) {
      query.filter.push(
        {
          field:    'channel.level',
          operator: _.isArray(channelLevel) ? 'In' : '=',
          value:    channelLevel,
          logic:    0,
        },
      )
    }
    if (channelQuicklyReplies) {
      query.filter.push(
        {
          field:    'channel.quicklyReplies',
          operator: _.isArray(channelQuicklyReplies) ? 'In' : '=',
          value:    channelQuicklyReplies,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/comment${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getComments ({}, option) {
    // option = {
    //   kind  : <String>, article || activity || account
    //   index : <String>,
    //   channelSubscription: <String>,
    //   fields: <Array>,
    //   page  : <Number>,
    //   limit : <Number>,
    //   sort  : <Object>,
    // }
    const {
      kind, index, channelInputted, channelDeleted, channelLevel, channelQuicklyReplies,
    } = option
    const channelSubscription = option.channelSubscription || null
    const fields = option.fields || ['identity', 'content', 'image', 'replies', 'likes', 'account.identity', 'account.serial', 'account.kind', 'account.organization.kind', 'account.organization.alias', 'account.avatar', 'account.name', 'account.service', 'account.ownRoles', 'account.belongOrg', 'account.belongOrgInfo', 'status.created']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (
      page - 1)
    const sort = option.sort || { 'status.created': -1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
        {
          field:    'index',
          operator: _.isArray(index) ? 'In' : '=',
          value:    index,
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      sort:   sort,
      fields: fields,
    }
    if (channelSubscription) {
      query.filter.push(
        {
          field:    'channel.subscription',
          operator: '=',
          value:    channelSubscription,
          logic:    0,
        },
      )
    }
    if (channelInputted) {
      query.filter.push(
        {
          field:    'channel.inputtedAt',
          operator: '<>',
          value:    null,
          logic:    0,
        },
      )
      if (!_.isUndefined(channelDeleted)) {
        query.filter.push(
          {
            field:    'channel.deletedAt',
            operator: channelDeleted ? '<>' : '=',
            value:    null,
            logic:    0,
          },
        )
      }
    }
    if (channelLevel) {
      query.filter.push(
        {
          field:    'channel.level',
          operator: _.isArray(channelLevel) ? 'In' : '=',
          value:    channelLevel,
          logic:    0,
        },
      )
    }
    if (channelQuicklyReplies) {
      query.filter.push(
        {
          field:    'channel.quicklyReplies',
          operator: _.isArray(channelQuicklyReplies) ? 'In' : '=',
          value:    channelQuicklyReplies,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/comments${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createComment ({}, comment) {
    // comment = {
    //   account: <String>,
    //   kind   : <String>, article || activity || account
    //   index  : <String>,
    //   content: <String>,
    //   summary: <String>,
    //   image  : <Url>,
    //   marketTags: <Array>,
    // }
    const body = {
      data: {
        type:       'comment',
        attributes: comment,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/comment`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateComment ({}, option) {
    // option = {
    //   identity : <String>,
    //   content  : <String>,
    //   summary  : <String>,
    //   image    : <Url>,
    //   marketTags: <Array>,
    // }
    const {
      identity, content, summary, image, channelDeletedAt, marketTags,
    } = option
    const channelLevel = option['channel.level']
    const channelQuicklyReplies = option['channel.quicklyReplies']

    const body = {
      data: {
        type:       'comment',
        id:         identity,
        attributes: {
          content,
          summary,
          image,
          marketTags,
        },
      },
    }
    if (channelLevel) {
      body.data.attributes['channel.level'] = channelLevel
    }
    if (channelQuicklyReplies) {
      body.data.attributes['channel.quicklyReplies'] = channelQuicklyReplies
    }
    if (!_.isUndefined(channelDeletedAt)) {
      body.data.attributes['channel.deletedAt'] = channelDeletedAt
    }

    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/comment${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteComment ({}, option) {
    // option = {
    //   identity : <String>,
    //   account : <String>,
    //   kind    : <String>, article || activity || account
    //   index   : <String>,
    //   content : <String>,
    // }
    const identity = option.identity || null
    const {
      account, kind, index, content,
    } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: ['identity'],
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
    } else {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
        {
          field:    'index',
          operator: '=',
          value:    index,
          logic:    0,
        },
        {
          field:    'content',
          operator: '=',
          value:    content,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/comment${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
