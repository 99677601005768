<template>
  <BaseMenu :items="items" :isFooter="true" class="footer-menu fs-14px p-0" />
</template>
<script>
  import BaseMenu from '~/components/templates/BaseMenu'

  export default {
    components: {
      BaseMenu,
    },
    data () {
      return {
        view:  {},
        items: [
          {
            title: '關於 Scantrader',
            link:  { name: 'about' },
            class: ['link'],
          },
          {
            title: '常見問答',
            link:  { name: 'faq' },
            class: ['link'],
          },
          {
            title: '企業合作',
            link:  { name: 'cooperation' },
            class: ['link'],
          },
          {
            title: '服務條款',
            link:  { name: 'terms-of-service' },
            class: ['link'],
          },
          {
            title: '付款政策',
            link:  { name: 'terms-of-pay' },
          },
          {
            title: '隱私權政策',
            link:  { name: 'terms-of-privacy' },
          },
        ],
      }
    },
  }
</script>
