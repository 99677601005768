<template>
  <div class="no-data p-5">
    <h5 class="title">
      {{ title }}
    </h5>
    <p class="description mb-3 mx-auto">
      {{ description }}
    </p>
    <slot name="bottom" />
    <b-img
      fluid
      :src="img" />
  </div>
</template>

<script>
  export default {
    name:  'NoData',
    props: {
      title: {
        type:    String,
        default: '目前沒有資料',
      },
      description: {
        type:    String,
        default: '',
      },
      img: {
        type:    String,
        default: '/images/png_icon/status/no-data.png',
      },
    },
  }
</script>

<style lang="scss" scoped>
  .no-data {
    width: 100%;
    text-align: center;

    h5 {
      color: $st_color_main;
      font-size: $st_font-size-bigger;
    }

    p {
      color: $st_color_gray;
      font-size: $st_font-size-small;
      max-width: 300px;
    }
  }
</style>
