import apiUtils from '~/utils/api'

export const actions = {
  getActivity ({}, option) {
    // option = {
    //   identity: <String>,
    //   fields  : <Array>
    // }
    const { identity } = option
    const fields = option.fields || ['identity', 'kind', 'index', 'summary', 'like', 'likes', 'comments', 'account.identity', 'account.kind', 'account.serial', 'account.avatar', 'account.name', 'account.organization.kind', 'account.organization.alias', 'account.service', 'account.ownRoles', 'account.belongOrg', 'account.belongOrgInfo', 'status.updated']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          value:    identity,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/activity${params}`)
      .then(async res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getActivitiesByAccount ({}, option) {
    // option = {
    //   identity: <Array>,
    //   account : <Array>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const identity = option.identity || null
    const { account } = option
    const fields = option.fields || ['identity', 'kind', 'index', 'summary', 'like', 'likes', 'comments', 'account.identity', 'account.kind', 'account.serial', 'account.avatar', 'account.name', 'account.organization.kind', 'account.organization.alias', 'account.service', 'account.ownRoles', 'account.belongOrg', 'account.belongOrgInfo', 'status.updated']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const sort = option.sort || { 'status.updated': -1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'account',
          value:    account,
          operator: 'In',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (identity) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: 'In',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/activities${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getActivitiesSharedsByAccount ({}, option) {
    // option = {
    //   account : <String>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const { account } = option
    const fields = option.fields || ['identity', 'kind', 'index', 'summary', 'like', 'likes', 'comments', 'account.identity', 'account.kind', 'account.serial', 'account.avatar', 'account.name', 'account.organization.kind', 'account.organization.alias', 'account.service', 'account.ownRoles', 'account.belongOrg', 'account.belongOrgInfo', 'status.updated']
    const page = Number(option.page) || 1
    const limit = Number(option.limit) || 500
    const offset = limit * (page - 1)
    const sort = option.sort || { 'status.created': -1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'kind',
          value:    'account',
          operator: '=',
          logic:    0,
        },
        {
          field:    'account',
          value:    account,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/activities/shareds${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getActivitiesServiceFree ({}, option) {
    // option = {
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const fields = option.fields || ['identity', 'index', 'status.updated', 'summary', 'account.belongOrgInfo']
    const page = Number(option.page) || 1
    const limit = Number(option.limit) || 500
    const offset = limit * (page - 1)
    const sort = option.sort || { 'status.updated': -1 }
    const query = {
      filter: [
        {
          field:    'kind',
          value:    'article',
          operator: '=',
          logic:    0,
        },
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/activities/serviceFrees${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createActivity ({}, activity) {
    // activity = {
    //   kind   : <String>,
    //   index  : <String>,
    //   summary: <String>,
    //   account: <String>
    // }
    const query = {
      filter: [
        {
          field:    'account',
          operator: '=',
          value:    activity.account,
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    activity.kind,
          logic:    0,
        },
        {
          field:    'index',
          operator: '=',
          value:    activity.index,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    activity.status = {
      deleted: null,
    }
    const body = {
      data: {
        type:       'activity',
        attributes: activity,
      },
    }
    return this.$axios.put(`${this.state.setting.apiUrlSocial}/activity${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteActivity ({}, option) {
    // option = {
    //   identity : <String>,
    //   account : <String>,
    //   kind    : <String>, article || activity || account
    //   index   : <String>,
    // }
    const identity = option.identity || null
    const { account, kind, index } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: ['identity'],
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
    } else {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
        {
          field:    'index',
          operator: '=',
          value:    index,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/activity${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
