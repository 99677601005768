import apiUtils from '~/utils/api'

/**
 * 2022/12 關注清單的新版 API
 * (對應的後端專案為 social_member)
 */
export const actions = {
  /* 新增關注 */
  createPortfolio ({}, option) {
    // option = {
    //   account      : <String>,
    //   tickerSymbol : <String>,
    //   country      : <String>,
    // }
    const body = {
      account:      option.account,
      tickerSymbol: option.tickerSymbol,
      country:      option.country,
    }

    return this.$axios.post(`${this.state.setting.apiGateway}/portfolio/one`, body)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  /* 取得關注數量 */
  getPortfolioCount ({}, option) {
    // option = {
    //   account      : <String>,
    // }
    const params = {
      account: option.account,
    }

    return this.$axios.get(`${this.state.setting.apiGateway}/portfolio/count`, { params })
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  /* 取得關注項目 by 股票代碼 */
  getPortfolio ({}, option) {
    // option = {
    //   account      : <String>,
    //   tickerSymbol : <String>,
    // }
    const params = {
      account:      option.account,
      tickerSymbol: option.tickerSymbol,
    }
    return this.$axios.get(`${this.state.setting.apiGateway}/portfolio/one`, { params })
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  /* 取得關注清單 */
  getPortfolios ({}, option) {
    // option = {
    //   account      : <String>,
    //   lastTick     : <Boolean>,
    //   pTicks       : <Boolean>,
    // }
    const params = {
      account: option.account,
      limit:   500,
      offset:  0,
    }

    if (option.lastTick === true) params.lastTick = true
    if (option.pTicks) params.pTicks = true

    return this.$axios.get(`${this.state.setting.apiGateway}/portfolio/list`, { params })
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  /* 取得關注清單的 count */
  async getCount ({}, option) {
    // option = {
    //   account      : <String>,
    //   tickerSymbol : <String>,
    // }
    const params = {
      account: option.account,
    }
    if (option.tickerSymbol) params.tickerSymbol = option.tickerSymbol

    return this.$axios.get(`${this.state.setting.apiGateway}/portfolio/count`, { params })
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  /**
   * 疊加成本（多單 or 空單）
   */
  async accumulateCost ({}, option) {
    // option.short 和 option.long 欄位至少要帶其中之一
    // option = {
    //   account      : <String>,
    //   tickerSymbol : <String>,
    //   "short": { "price": <Number>, "amount": <Number> },
    //   "long": { "price": <Number>, "amount": <Number> },
    // }

    const params = {
      account:      option.account,
      tickerSymbol: option.tickerSymbol,
    }
    const body = {}

    // 空單持股成本
    if (_.isObject(option.short) && option.short.price && option.short.amount) {
      body.short = option.short
    }
    // 多單持股成本
    if (_.isObject(option.long) && option.long.price && option.long.amount) {
      body.long = option.long
    }

    return this.$axios.patch(`${this.state.setting.apiGateway}/portfolio/one/cost/accumulate`, body, { params })
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  deletePortfolio ({}, option) {
    // option = {
    //   account      : <String>,
    //   tickerSymbol : <String>,
    // }
    const params = {
      account:      option.account,
      tickerSymbol: option.tickerSymbol,
    }
    return this.$axios.delete(`${this.state.setting.apiGateway}/portfolio/one`, { params })
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
}
