import apiUtils from '~/utils/api'

export const actions = {
  createHistory ({}, history) {
    // history = {
    //   account    : <String>,
    //   kind       : <String>, article || service || channel || addPortfolio // 加持股 || lookPortfolio // 加關注 || switchPortfolio // 關注轉持股
    //   index      : <String>,
    //   fingerprint: <String>,
    // }
    const body = {
      data: {
        type:       'history',
        attributes: history,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/history`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
