import apiUtils from '~/utils/api'

export const actions = {
  getService ({}, option) {
    // option = {
    //   identity: <String>,
    //   channels: <String>,
    //   fields  : <Array>
    // }
    const { identity, channels } = option
    const fields = option.fields || ['identity', 'cover', 'title', 'description', 'categories.identity', 'categories.name', 'content', 'link', 'targets', 'goods', 'backgrounds', 'softwares', 'agreement', 'account.avatar', 'account.name', 'account.belongOrg', 'account.belongOrgInfo', 'haveChannel']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'approved',
          value:    null,
          operator: '<>',
          logic:    0,
        },
      ],
      fields: fields,
    }
    if (identity) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: '=',
        logic:    0,
      })
    }
    if (channels) {
      query.filter.push({
        field:    'channels',
        value:    channels,
        operator: _.isArray(channels) ? 'In' : '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/service${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getServiceCommentOptions ({}, option) {
    // option = {
    //   identity: <String>,
    //   channels: <String>,
    //   fields  : <Array>
    // }
    const { identity, channels } = option
    const fields = option.fields || ['identity', 'account', 'channels', 'commentOption', 'totalGrade', 'sum', 'grade']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'approved',
          value:    null,
          operator: '<>',
          logic:    0,
        },
      ],
      fields: fields,
    }
    if (identity) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: '=',
        logic:    0,
      })
    }
    if (channels) {
      query.filter.push({
        field:    'channels',
        value:    channels,
        operator: _.isArray(channels) ? 'In' : '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/service/commentOptions${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getServices ({}, option = {}) {
    // option = {
    //   search  : <String>,
    //   promoted: <Boolean>,
    //   categories: <Array>,
    //   account: <Array>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const search = option.search || null
    const promoted = option.promoted || null
    const approved = _.isBoolean(option.approved) ? option.approved : true
    const categories = option.categories || null
    const account = option.account || null
    const fields = option.fields || ['identity', 'cover', 'title', 'description', 'categories.identity', 'categories.name', 'account.followed', 'subscriptions', 'account.identity', 'account.serial', 'account.name', 'account.avatar', 'account.kind', 'account.collection', 'account.organization.kind', 'account.organization.alias', 'account.organization.title', 'account.organization.description', 'account.organization.cover', 'account.organization.categories', 'account.belongOrg', 'account.belongOrgInfo', 'hidden', 'haveChannel']
    const page = option.page || 1
    const limit = option.limit || 100
    const offset = limit * (page - 1)
    const sort = option.sort || _.isNull(promoted) ? {
      published:        -1,
      'status.created': -1,
    } : {
      promoted:         -1,
      published:        -1,
      'status.created': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }

    if (approved) {
      query.filter.push({
        field:    'approved',
        value:    Date.now(),
        operator: '<=',
        logic:    0,
      })
    }
    if (!_.isNull(search)) {
      query.filter.push({
        field:    'title',
        value:    search,
        operator: 'Like',
        logic:    0,
      })
    }
    if (!_.isNull(promoted)) {
      query.filter.push({
        field:    'promoted',
        value:    0,
        operator: '<>',
        logic:    0,
      })
    }
    if (!_.isNull(categories)) {
      query.filter.push({
        field:    'categories',
        value:    categories,
        operator: 'In',
        logic:    0,
      })
    }
    if (!_.isNull(account)) {
      query.filter.push({
        field:    'account',
        value:    account,
        operator: _.isArray(account) ? 'In' : '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/services${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getServicesHots ({}, option = {}) {
    // option = {
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    // }
    const fields = option.fields || ['identity', 'cover', 'title', 'description', 'categories.identity', 'categories.name', 'account.followed', 'subscriptions', 'account.identity', 'account.serial', 'account.name', 'account.avatar', 'account.kind', 'account.belongOrg', 'account.belongOrgInfo', 'hidden', 'haveChannel']
    const page = option.page || 1
    const limit = option.limit || 10
    const offset = limit * (
      page - 1)
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'approved',
          value:    Date.now(),
          operator: '<=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/services/hots${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getServiceContact ({}, option) {
    const { account } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'account',
          value:    account,
          operator: '=',
          logic:    0,
        },
      ],
      fields: ['contract.file'],
    }
    const params = apiUtils.getQueryString(query)
    return `${this.state.setting.apiUrlSocial}/service/download${params}`
  },
  createService ({}, service) {
    // service = {
    //   account: <String>,
    //   cover: <Url>,
    //   title: <String>,
    //   categories: <Array>,
    //   description: <Html>,
    //   link: {
    //     facebook: <Url>,
    //     line: <Url>,
    //     youtube: <Url>,
    //     web: <Url>
    //   },
    //   targets: <Html>,
    //   goods: <Html>,
    //   backgrounds: <Html>,
    //   softwares: <Html>,
    //   agreement: <Html>,
    //   content: <Html>,
    //   price: {
    //     minimum: <Number>,
    //     maximum: <Number>
    //   },
    //   company: <Boolean>,
    //   code: <String>,
    //   contact: {
    //     name: <String>,
    //     email: <Email>,
    //     phone: <String>,
    //     address: <String>
    //   },
    //   rate: {
    //     grant: <Number>,
    //     tax: <Number>
    //   },
    //   transfer: {
    //     bankCode: <String>,
    //     bankName: <String>,
    //     bankBranch: <String>,
    //     bankBranchCode: <String>,
    //     remitterName: <String>,
    //     remitterAccount: <String>,
    //     memo: <String>
    //   },
    //   approved: <Number>,
    //   published: <Number>,
    //   promoted: <Number>,
    //   hidden: { <Object>
    //     subscriptions: <Boolean>
    //   }
    // }
    const body = {
      data: {
        type:       'service',
        attributes: service,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/service`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateService ({}, option) {
    // option = {
    //   identity : <String>,
    //   account: <String>,
    //   cover: <Url>,
    //   title: <String>,
    //   categories: <Array>,
    //   description: <Html>,
    //   link: {
    //     facebook: <Url>,
    //     line: <Url>,
    //     youtube: <Url>,
    //     web: <Url>
    //   },
    //   targets: <Html>,
    //   goods: <Html>,
    //   backgrounds: <Html>,
    //   softwares: <Html>,
    //   agreement: <Html>,
    //   content: <Html>,
    //   price: {
    //     minimum: <Number>,
    //     maximum: <Number>
    //   },
    //   company: <Boolean>,
    //   code: <String>,
    //   contact: {
    //     name: <String>,
    //     email: <Email>,
    //     phone: <String>,
    //     address: <String>
    //   },
    //   rate: {
    //     grant: <Number>,
    //     tax: <Number>
    //   },
    //   transfer: {
    //     bankCode: <String>,
    //     bankName: <String>,
    //     bankBranch: <String>,
    //     bankBranchCode: <String>,
    //     remitterName: <String>,
    //     remitterAccount: <String>,
    //     memo: <String>
    //   },
    //   approved: <Number>,
    //   published: <Number>,
    //   promoted: <Number>,
    //   hidden: { <Object>
    //     subscriptions: <Boolean>
    //   }
    // }
    const {
      identity, account, cover, title,
      categories, description, link, targets,
      goods, backgrounds, softwares, agreement,
      content, price, company, code, contact,
      contract, rate, transfer, approved,
      published, promoted, hidden,
    } = option

    const body = {
      data: {
        type:       'service',
        id:         identity,
        attributes: {
          account,
          cover,
          title,
          categories,
          description,
          link,
          targets,
          goods,
          backgrounds,
          softwares,
          agreement,
          content,
          price,
          company,
          code,
          contact,
          contract,
          rate,
          transfer,
          approved,
          published,
          promoted,
          hidden,
        },
      },
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    option.identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/service${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteService ({}, option) {
    // option = {
    //   identity : <String>
    // }
    const { identity } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
      fields: ['identity'],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/service${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
