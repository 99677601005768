import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue'

Vue.use(InfiniteLoading, {
  slots: {
    noMore:       '已經到底囉 :)',
    noResults:    '沒有內容喔 :)',
    error:        '發生錯誤，請稍後再試',
    errorBtnText: '重試',
  },
})
