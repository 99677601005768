// this is a client-side Plugin
export default ({ app, store }) => {
  /**
   * social_fe_2022 強制導轉
   * 將指定的頁面 (個股頁、關注清單)，導轉到 social_fe_2022。 只有 Client Side Render 才會生效
   * ex: [social_fe]/stock/k 會導到 [social_fe_2022]/stock/k
   */
  function redirectToV2 (path) {
    const isMatchV2 = /^\/stock/.test(path)
    const isMatchV3portfolios = /^\/my\/portfolios/.test(path)
    if (isMatchV2) {
      const v2Url = store.getters.siteSetting.socialUrlV2

      window.location.href = `${v2Url}${path}`
    }
    if (isMatchV3portfolios) {
      const v3Url = process.env.scantraderUrlV3

      window.location.href = `${v3Url}/portfolios`
    }
    return isMatchV2 || isMatchV3portfolios
  }

  // Every time the route changes (fired on initialization too)
  // eslint-disable-next-line consistent-return
  app.router.beforeEach(async (to, from, next) => {
    const shouldRedirectV2 = redirectToV2(to.fullPath)

    if (shouldRedirectV2) return false

    if (from.name) {
      /**
       * 判斷路由是否位於 u/_uid
       * 若是則取得對象資訊
       */
      let toChanged = false
      const nextTo = {
        name:   to.name,
        params: _.cloneDeep(to.params),
        query:  _.cloneDeep(to.query),
        hash:   to.hash,
      }
      if (to.name.match(/^u-uid/)) {
        if (app.$userCheck.serialIsOwn(to.params.uid)) {
          app.store.commit('user/clearExpertUserInfo')
          app.store.commit('user/cloneUserInfo')
        } else if (Number(from.params.uid) !== Number(to.params.uid)) {
          const uData = await app.store.dispatch('user/getUserInfo', to.params.uid)
          if (_.get(uData, 'kind', null) === 'organization') {
            toChanged = true
            nextTo.name = to.name.replace('u-uid', 'o-oas')
            nextTo.params.oas = uData.organization.alias
            delete nextTo.params.uid
          }
        }
      } else if (to.name.match(/^o-oas/)) {
        if (!app.$userCheck.oasIsSame(to.params.oas)) {
          const oData = await app.store.dispatch('user/getUserInfo', { serialOrAlias: to.params.oas, isOrganizationAlias: true })
          if (_.get(oData, 'kind', null) === 'personal') {
            toChanged = true
            nextTo.name = to.name.replace('o-oas', 'u-uid')
            nextTo.params.uid = oData.serial
            delete nextTo.params.oas
          }
        }
      } else if (app.$userCheck.isLogin() && !app.$userCheck.isSame()) {
        await app.store.dispatch('user/getUserInfo', app.store.state.user.serial)
      }

      if (toChanged) {
        next(nextTo)
      } else {
        next()
      }
    } else {
      next()
    }
  })
}
