// 因為 platform.js 使用 MutationObserver API 監聽 “document.getElementsByTagName("html")”，
// 導致 Nuxt 在初始化階段更新 DOM（如：vue-meta）時多次觸發 MutationObserver 的功能，
// 因為多次觸發的關係以至於該套件在替換內容無法找到正確的 iframe ，造成程式出錯以及白畫面。
// 下方做法要在 Nuxt 初始階段結束後再加上 platform.js 的功能
// related issue:
// 1. https://stackoverflow.com/questions/57002636/is-there-any-way-to-handle-cannot-read-property-cards-of-null-error
export default ({ app: { head } }) => {
  // onNuxtReady 的方法在 .nuxt/utils.js 可以看到。
  // 該功能並未收入在官方文件，因此在此留紀錄，方便未來套件更新後，出問題比較好找問題。
  // 原始碼： https://github.com/nuxt/nuxt.js/blob/701f2d033bbc421f85f888f9bf9100b176aac818/packages/vue-app/template/utils.js#L7
  window.onNuxtReady(root => {
    head.script.push({
      src:     'https://cdn.embedly.com/widgets/platform.js',
      body:    true,
      charset: 'UTF-8',
    })
    root.$meta().refresh()
  })
}
