export default ({ app, route }) => {
  // fix partner promo link not correct with ga
  // https://scantrader.com/o/ctee?follow=#utm_source=CTEE&utm_medium=WG&utm_campaign=WG20200423-002
  if (route.hash && route.hash.match('utm_source')) {
    try {
      app.router.replace(route.fullPath.replace('#', '&'))
    } catch (e) {
      console.error(e)
    }
  }
  // LINE 在電腦版登入後會重新導向到後台設置的 Endpoint url
  // 需要再 init 一次讓它導向到正確的路由
  // https://developers.line.biz/en/docs/liff/opening-liff-app/#redirect-flow
  if (process.browser && route.query['liff.state']) {
    if (!(
      'liff' in window
        && 'init' in liff
        && typeof liff.init === 'function'
    )) return
    const dataset = decodeURIComponent(route.query['liff.state']).split('&')
    const liffIdString = dataset.find(item => item.includes('liffId'))
    if (!liffIdString) return
    liff.init({
      liffId: _.get(liffIdString.split('='), '[1]', ''),
    })
  }
}
