import apiUtils from '~/utils/api'

export const actions = {
  getPurchases ({}, option) {
    // option = {
    //   identity  : <String> <Array>,
    //   kind    : <String>, ticket
    //   account: <String>,
    //   product: <String> <Array>,
    //   detailSerial: <String>,
    //   detailStatus: <String>, userInfo || paidInfo || pending || waitpay || error || paid || refund
    //   detailUserInfoName: <String>,
    //   detailUserInfoEmail: <String>,
    //   detailUserInfoPhone: <String>,
    //   orderState: <String>, paid || signup
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const identity = option.identity || null
    const kind = option.kind || 'all'
    const account = option.account || null
    const product = option.product || null
    const detailSerial = option.detailSerial || null
    const detailStatus = option.detailStatus || null
    const detailUserInfoName = option.detailUserInfoName || null
    const detailUserInfoEmail = option.detailUserInfoEmail || null
    const detailUserInfoPhone = option.detailUserInfoPhone || null
    const orderState = option.orderState || null
    const fields = option.fields || ['identity', 'kind', 'product', 'detail', 'status', 'account.identity', 'account.avatar', 'account.name', 'account.serial']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const sort = option.sort || {
      'status.created': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(identity)) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: _.isArray(identity) ? 'In' : '=',
        logic:    0,
      })
    }
    if (kind === 'all') {
      query.filter.push({
        field:    'kind',
        value:    ['ticket'],
        operator: 'In',
        logic:    0,
      })
    } else {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(account)) {
      query.filter.push({
        field:    'account',
        value:    account,
        operator: _.isArray(account) ? 'In' : '=',
        logic:    0,
      })
    }
    if (!_.isNull(product)) {
      query.filter.push({
        field:    'product',
        value:    product,
        operator: _.isArray(product) ? 'In' : '=',
        logic:    0,
      })
    }
    if (!_.isNull(detailSerial)) {
      query.filter.push({
        field:    'detail.serial',
        value:    detailSerial,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(detailStatus)) {
      query.filter.push({
        field:    'detail.status',
        value:    detailStatus,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(detailUserInfoName)) {
      query.filter.push({
        field:    'detail.userInfo.name',
        value:    detailUserInfoName,
        operator: 'Like',
        logic:    0,
      })
    }
    if (!_.isNull(detailUserInfoEmail)) {
      query.filter.push({
        field:    'detail.userInfo.email',
        value:    detailUserInfoEmail,
        operator: 'Like',
        logic:    0,
      })
    }
    if (!_.isNull(detailUserInfoPhone)) {
      query.filter.push({
        field:    'detail.userInfo.phone',
        value:    detailUserInfoPhone,
        operator: 'Like',
        logic:    0,
      })
    }
    if (!_.isNull(orderState)) {
      query.filter.push({
        field:    'orderState',
        value:    orderState,
        operator: '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/purchases${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getPurchaseTicketDecode ({}, option) {
    // option = {
    //   qrcode: <String>,
    // }
    const { qrcode } = option
    const body = {
      data: {
        qrcode,
      },
    }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/purchase/ticket/decode`, body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updatePurchaseTicketToUse ({ commit }, purchase) {
    // purchase = {
    //   identity    : <String>
    //   confirm     : <Boolean>,
    // }
    const { identity } = purchase
    const confirm = purchase.confirm || false
    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)

    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/purchase/ticket/use${params}&confirm=${confirm}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updatePurchaseTicketToUnUse ({ commit }, purchase) {
    // purchase = {
    //   identity    : <String>
    //   confirm     : <Boolean>,
    // }
    const { identity } = purchase
    const confirm = purchase.confirm || false
    const query = {
      filter: [
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)

    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/purchase/ticket/unuse${params}&confirm=${confirm}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
