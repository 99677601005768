<template>
  <div class="menu">
    <b-container class="menu__inner" :fluid="isMobile">
      <nuxt-link
        v-for="link in links" :key="link.to.name"
        :class="{
          'menu__item--active': link.active
        }"
        :to="link.to" class="menu__item st_none-medium-base">
        {{ link.title }}
      </nuxt-link>
    </b-container>
  </div>
</template>

<script>
  export default {
    computed: {
      links () {
        if (this.$route.name.includes('demo')) {
          return [
            {
              title:  'base',
              to:     { name: 'demo' },
              active: this.$route.name === 'demo',
            },
            {
              title:  'form',
              to:     { name: 'demo-form' },
              active: this.$route.name === 'demo-form',
            },
            {
              title:  'icon',
              to:     { name: 'demo-icon' },
              active: this.$route.name === 'demo-icon',
            },
            {
              title:  'list',
              to:     { name: 'demo-list' },
              active: this.$route.name === 'demo-list',
            },
            {
              title:  'menu',
              to:     { name: 'demo-menu' },
              active: this.$route.name === 'demo-menu',
            },
            {
              title:  'snackbar&modal',
              to:     { name: 'demo-snackbar&modal' },
              active: this.$route.name === 'demo-snackbar&modal',
            },
            {
              title:  'tabs',
              to:     { name: 'demo-tabs' },
              active: this.$route.name === 'demo-tabs',
            },
          ]
        }

        return []
      },
      isMobile () {
        return this.windowWidth < 768
      },
    },
  }
</script>

<style lang="scss" scoped>
  .darkMode {
    background-color: #0d101d;
  }

  .menu {
    background-color: $st_color_main;

    @include media-breakpoint-up(md) {
      background-color: rgba($st_color_main, 0.95);
    }

    &__inner {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include media-breakpoint-up(md) {
        justify-content: start;
        padding: 0 50px;
      }
    }

    &__item {
      color: white;
      flex: 1;
      position: relative;
      text-align: center;
      padding: 8px 0 12px 0;

      @include media-breakpoint-up(md) {
        padding: 22px 42px;
        flex: none;
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background-color: transparent;
        position: absolute;
        bottom: 0;
        left: 0;

        @include media-breakpoint-up(md) {
          height: 4px;
        }
      }

      &--active {
        color: $st_color_sub_light;

        &::after {
          background-color: $st_color_sub;
        }
      }
    }
  }
</style>
