import apiUtils from '~/utils/api'

export const actions = {
  getUserOrders ({}, option) {
    // option = {
    //   accountIds: <String>,
    //   status: <String>
    // }
    const query = {
      accountIds: option.accountIds,
    }
    if (option.status) {
      query.status = option.status
    }
    const params = apiUtils.getQueryStringByOptions(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/orders/user${params}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getExpertOrders ({}, option) {
    // option = {
    //   accountId : <String>,
    //   channelIds: <Array>,
    //   startedAt: <>,
    //   kind: <String>,
    //   limit: <Number>,
    //   offset: <Number>,
    //   count:  <> (如果 count 有值，則只回傳訂單總數)
    // }
    const query = {
      accountId:  option.accountId,
      channelIds: option.channelIds.join(','),
      limit:      option.limit || 1000,
      offset:     option.offset || 0,
    }
    if (option.startedAt) {
      query.startedAt = option.startedAt
    }
    if (option.kind) {
      query.kind = option.kind
    }

    if (option.count) {
      query.count = option.count
    }

    const params = apiUtils.getQueryStringByOptions(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/orders/expert${params}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getOrderResult ({}, identity) {
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/order/${identity}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getAdminOrders ({}, option) {
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (
      page - 1)
    const query = {
      limit:  limit,
      offset: offset,
    }
    if (option.identity) {
      query.identity = option.identity
    }
    if (option.id) {
      query.id = option.id
    }
    if (option.type) {
      query.type = option.type.join(',')
    }
    if (option.status) {
      query.status = option.status.join(',')
    }
    if (option.ChannelId) {
      query.ChannelId = option.ChannelId.join(',')
    }
    if (option.SubscriptionId) {
      query.SubscriptionId = option.SubscriptionId.join(',')
    }
    if (option.AccountId) {
      query.AccountId = option.AccountId.join(',')
    }
    if (option.startedAt) {
      query.startedAt = option.startedAt
    }
    if (option.name) {
      query.name = option.name
    }
    if (option.phone) {
      query.phone = option.phone
    }
    if (option.endedAt) {
      query.endedAt = option.endedAt
    }
    const params = apiUtils.getQueryStringByOptions(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/orders${params}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  createOrder ({}, order) {
    // order = {
    //   AccountId: <String>,
    //   ChannelId: <String>,
    //   callback: <URL>,
    //   contactEmail: <Email>, 個人/公司
    //   contactPhone: <String>, 個人/公司
    //   realName: <String>, 個人
    //   companyName: <String>, 公司
    //   taxId: <Number>, 公司
    // }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/order`, order)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  cancelSubscriptionOrders ({}, option) {
    // option = {
    //   subscriptionId: <String>,
    // }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/orders/cancel`, option)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  restoreSubscriptionOrders ({}, option) {
    // option = {
    //   subscriptionId: <String>,
    // }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/orders/restore`, option)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  refundSubscriptionOrders ({}, option) {
    // option = {
    //   subscriptionId: <String>,
    // }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/orders/refund`, option)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  // 選擇購買票券
  createOrderProductPreOrder ({}, option) {
    // option = {
    //   AccountId: <String>,
    //   kind     : <String>, event 訂單類型
    //   index    : <String Identity>, 類型編號
    //   carts    : [ // 購物車內容
    //     {
    //       product: <String Identity>, // 產品編號
    //       quantity: <Number> // 產品數量
    //     }
    //   ]
    // }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/order/product/preOrder`, option)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  // 輸入或更新票券資料
  updateOrderProductInfo ({}, option) {
    // option = {
    //   account       : <String>,
    //   order         : <String>, 訂單 identity
    //   purchaseInfos : [ // 票券內容
    //     {
    //       identity: <String Identity>, // 票券編號
    //       detail  : { // 票券額外資訊
    //         userInfo: { // 報名者資料
    //            name : <String>, // 報名者姓名
    //            email: <Email>, // 報名者電子信箱
    //            phone: <String> // 報名者聯絡電話
    //         }
    //       }
    //     }
    //   ]
    // }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/order/product/updateUserInfo`, option)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  // 成立產品訂單
  createOrderProduct ({}, option) {
    // option = {
    //   AccountId   : <String>,
    //   order       : <String>, // 訂單編號
    //   callback    : <URL>, // 回傳路徑
    //   payment     : <String>, LINEPAY || TAPPAY || ECPAY // 付款方式
    //   payType     : <String>, NORMAL(LINEPAY) || CREDIT(TAPPAY) || PREAPPROVED(LINEPAY/TAPPAY) 記憶卡片 || CVS(ECPAY) || ATM(ECPAY) // 付款類型
    //   contactEmail: <Email>, 個人/公司
    //   contactPhone: <String>, 個人/公司
    //   realName    : <String>, 個人
    //   companyName : <String>, 公司
    //   taxId       : <Number>, 公司
    // }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/order/product`, option)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getOrderProduct ({}, identity) {
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/order/product/${identity}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getUserOrdersProduct ({}, option) {
    // option = {
    //   accountIds: <String>,
    //   state: <String>, none || userInfo || paidInfo || pending || waitpay || paid || error || refund || cancel || failed  // MultiWith ,
    //   event: <String>
    // }
    const query = {
      accountIds: option.accountIds,
    }
    if (option.state) {
      query.state = option.state
    }
    if (option.event) {
      query.event = option.event
    }
    const params = apiUtils.getQueryStringByOptions(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/orders/product/user${params}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getExpertOrdersProductByEvent ({}, option) {
    // option = {
    //   index: <String>,  // 活動identity
    //   identity: <String>,  // 篩選訂單編號
    //   name: <String>,  // 篩選訂購者姓名(發票名稱)
    //   contactEmail: <String>,  // 篩選訂購者電話(發票電話)
    //   contactPhone: <String>,  // 篩選訂購者email(發票email)
    //   serial: <String>,  // 篩選訂單用戶UID
    //   state: <String>, none || userInfo || paidInfo || pending || waitpay || paid || error || refund || cancel // Custom: Success || Waitpay || Refund
    // }
    const { index } = option
    const identity = option.identity || null
    const name = option.name || null
    const contactEmail = option.contactEmail || null
    const contactPhone = option.contactPhone || null
    const serial = option.serial || null
    const state = option.state || null
    const fields = option.fields || ['identity', 'state', 'account.identity', 'account.serial', 'account.name', 'info.payment', 'info.payType', 'info.payPrice', 'info.contactEmail', 'info.contactPhone', 'info.realName', 'info.companyName', 'expired', 'purchases', 'status.created']
    const page = option.page || 1
    const limit = option.limit || 50
    const offset = limit * (page - 1)
    const sort = option.sort || { 'status.updated': -1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'index',
          value:    index,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (identity) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: 'Like',
        logic:    0,
      })
    }
    if (name) {
      query.filter.push({
        field:    'name',
        value:    name,
        operator: 'Like',
        logic:    0,
      })
    }
    if (contactEmail) {
      query.filter.push({
        field:    'info.contactEmail',
        value:    contactEmail,
        operator: 'Like',
        logic:    0,
      })
    }
    if (contactPhone) {
      query.filter.push({
        field:    'info.contactPhone',
        value:    contactPhone,
        operator: 'Like',
        logic:    0,
      })
    }
    if (serial) {
      query.filter.push({
        field:    'account.serial',
        value:    serial,
        operator: '=',
        logic:    0,
      })
    }
    if (state) {
      if (_.isString(state) && ['Success', 'Waitpay', 'Refund'].includes(state)) {
        switch (state) {
          case 'Success':
            query.filter.push({
              field:    'state',
              value:    'paid',
              operator: '=',
              logic:    0,
            })
            break
          case 'Waitpay':
            query.filter.push({
              field:    'state',
              value:    'waitpay',
              operator: '=',
              logic:    0,
            })
            query.filter.push({
              field:    'expired',
              value:    Date.now(),
              operator: '>=',
              logic:    0,
            })
            break
          case 'Refund':
            query.filter.push({
              field:    'state',
              value:    'refund',
              operator: '=',
              logic:    0,
            })
            break
        }
      } else {
        query.filter.push({
          field:    'state',
          value:    state,
          operator: _.isArray(state) ? 'In' : '=',
          logic:    0,
        })
      }
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/orders/product/expert/event${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  cancelProductOrders ({}, option) {
    // option = {
    //   identity: <String>,
    // }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/order/product/cancel`, option)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
}
