import apiUtils from '~/utils/api'

export const actions = {
  getHonsecSignup ({}, option) {
    // option = {
    //   account: <String>,
    //   fields  : <Array>,
    // }
    const { account } = option
    const fields = option.fields || ['identity', 'name', 'phone', 'email', 'selectPrize', 'account.serial', 'account.name.nick', 'account.avatar', 'status']
    const query = {
      filter: [
        {
          field:    'account',
          value:    account,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/securitiesFirm/honsec/signup${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getHonsecSignups ({}, option) {
    // option = {
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const fields = option.fields || ['identity', 'name', 'phone', 'email', 'selectPrize', 'account.serial', 'account.name.nick', 'account.avatar', 'status']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const sort = option.sort || {
      'status.created': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/securitiesFirm/honsec/signups${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createHonsecSignup ({}, signup) {
    // signup = {
    //   name    : <String>,
    //   phone   : <String>,
    //   email   : <String>,
    //   account : <String>,
    // }
    const body = {
      data: {
        type:       'honsec2021Signup',
        attributes: signup,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/securitiesFirm/honsec/signup`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },

  getHonsecPrizes ({}, option) {
    // option = {
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const account = option.account || null
    const fields = option.fields || ['identity', 'userType', 'userTypeId', 'date', 'prize', 'prizeTitle', 'selected', 'account.serial', 'account.name.nick', 'account.avatar', 'status']
    const page = option.page || 1
    const limit = option.limit || 50
    const offset = limit * (page - 1)
    const sort = option.sort || {
      selected:         -1,
      'status.created': -1,
    }
    const query = {
      filter: [
        {
          field:    'prize',
          value:    null,
          operator: '<>',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(account)) {
      query.filter.push({
        field:    'account',
        value:    account,
        operator: '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/securitiesFirm/honsec/prizes${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getHonsecPrizeSelect ({}, option) {
    // option = {
    //   identity: <String>,
    // }
    const { identity } = option
    const query = {
      filter: [
        {
          field:    'identity',
          value:    identity,
          operator: '=',
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/securitiesFirm/honsec/prize/select${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
