import Vue from 'vue';

import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue, {
  "BBadge": {
    "variant": "default"
  },
  "BButton": {
    "variant": "default"
  }
});
