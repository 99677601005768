<template>
  <nuxt-link v-if="notify" :to="nuxtTo" @click.native="markNotifyRead">
    <b-row
      noGutters
      class="justify-content-between notifyItem notify-border-bottom"
      :class="notify.read ? 'bg-read' : 'bg-unread'">
      <b-col cols="auto" class="notifyItem__left" v-if="notify.who">
        <ScanTraderAvatar
          width="40"
          height="40"
          :src="notify.who.avatar"
          lazy />
      </b-col>
      <b-col class="notifyItem__center pr-3 pl-2">
        <b-row
          class="p-0 notifyItem__center__top flex-nowrap mt-2 pt-1"
          noGutters>
          <b-row class="notifyItem--flex">
            <b-col class="notifyItem__name pr-0" v-if="notify.who">
              {{ notify.who.name.nick
              }}{{
                notify.who.belongOrg && _.get(notify.who, 'name.real', '')
                  ? `．${notify.who.name.real || ''}`
                  : ''
              }}
            </b-col>
            <b-col
              cols="auto"
              class="notifyItem__message pl-0"
              v-html="message" />
          </b-row>
        </b-row>
        <div
          class="notifyItem__center__bottom align-items-center pb-1"
          noGutters>
          <span
            class="notifyItem__center__bottom__left d-inline-block"
            :class="{ notifyItem__subscribe__bg: paidNotify }">
            <i
              class="notifyItem__icon p-1"
              :class="[
                icon,
                { notifyItem__subscribe__icon: paidNotify },
                {
                  'notifyItem__subscribe__icon--size':
                    icon === 'fas fa-bullhorn',
                },
              ]" />
            <span class="notifyItem__subscribe mr-1" v-if="paidNotify">
              訂閱
            </span>
          </span>
          <span
            class="notifyItem__text--small notifyItem__center__bottom__right"
            :class="{ notifyItem__text: paidNotify }">
            <span class="notifyItem__title" v-if="title">
              {{ $utils.decodeEntities(title) }}
            </span>
            <span class="notifyItem__content" noGutters>
              <span
                class="notifyItem__content__inside"
                :class="[{ 'notifyItem__content__inside--font': title }]"
                noGutters>
                <span
                  v-if="commentOrReply" class="notifyItem__content--margin">「</span>
                {{ $utils.filterAllHtmlTags(content, content.length) }}
                <span v-if="commentOrReply">」</span>
              </span>
            </span>
          </span>
        </div>
      </b-col>
      <b-col cols="auto" class="notifyItem__right pr-1 text-right pt-1">
        <span :id="`${menuTime}${timeSeconds}`">
          {{ timeAgo }}
        </span>
        <b-tooltip :target="`${menuTime}${timeSeconds}`" placement="bottom">
          {{ time }}
        </b-tooltip>
        <div
          class="notifyItem__isRead align-items-center"
          :class="[
            {
              notifyItem__isRead__margin:
                paidNotify ||
                notify.kind === 'freeActivity' ||
                notify.kind === 'subscription',
            },
            { 'notifyItem__isRead--isTitle': title },
          ]"
          @click.prevent.stop="toggleMark">
          <div
            class="ml-auto"
            :class="[
              { notifyItem__isRead__read: notify.read },
              { notifyItem__isRead__unread: !notify.read },
              notifyClass,
            ]" />
          <div class="pt-1 ml-1">
            {{ notify.read ? '已讀' : '未讀' }}
          </div>
        </div>
      </b-col>
    </b-row>
  </nuxt-link>
</template>
<script>
  import { mapActions } from 'vuex'

  export default {
    components: {},
    props:      {
      notify: {
        type:    Object,
        default: null,
      },
      notifyClass: {
        type:    String,
        default: null,
      },
      menuTime: {
        type:    String,
        default: '',
      },
    },
    data () {
      return {
        timeAgo:       '',
        setUpdateTime: setInterval(() => {
          if (this.notify.status) this.getTime(this.notify.status.created)
        }, 10000),
        time:        '',
        timeSeconds: '',
      }
    },
    computed: {
      nuxtTo () {
        const to = {
          name: '',
        }
        if (this.notify.parent && this.notify.parent.kind) {
          switch (this.notify.parent.kind) {
            case 'account':
              to.name = 'u-uid-service-qa'
              to.params = {
                uid: this.notify.parent.index,
              }
              break
            case 'article':
              to.name = 'article-id'
              to.params = {
                id: this.notify.parent.index,
              }
              break
            case 'activity':
              to.name = 'activity-id'
              to.params = {
                id: this.notify.parent.index,
              }
              break
            case 'subscription':
              to.name = 'u-uid-service-analytics-orders'
              to.params = {
                uid: this.notify.target.serial,
              }
              to.query = {
                month: this.$moment(this.notify.status.created).format('YYYY-MM'),
              }
              to.hash = `#subscription-${this.notify.parent.index}`
              break
          }
          if (this.notify.comment) to.hash = `#comment-${this.notify.comment}`
          if (this.notify.reply) to.hash += `_reply-${this.notify.reply}`
        }

        return to
      },
      paidNotify () {
        return (
          this.notify
          && (this.notify.kind === 'paidArticle'
            || this.notify.kind === 'paidActivity')
        )
      },
      icon () {
        let icon = ''
        switch (this.notify.kind) {
          case 'paidArticle':
          case 'freeArticle':
            icon = 'fas fa-book-open'
            break
          case 'paidActivity':
          case 'freeActivity':
            icon = 'fas fa-bullhorn'
            break
          case 'article':
          case 'activity':
          case 'account':
            icon = 'fas fa-comment-alt'
            break
          case 'comment':
            icon = 'fas fa-comments'
            break
          case 'subscription':
            icon = 'fas fa-hand-holding-usd'
            break
        }
        return icon
      },
      message () {
        let message = ''
        if (this.notify) {
          switch (this.notify.kind) {
            case 'paidArticle':
            case 'freeArticle':
              message += '發布了最新觀點!'
              break
            case 'paidActivity':
            case 'freeActivity':
              message += '發布了最新動態!'
              break
            case 'account':
              message += '在你的達人問答留言'
              break
            case 'subscription':
              message += '訂閱了你的達人服務'
              break
            case 'article':
              if (this.notify.account === this.notify.target.identity) {
                message += '在你的觀點上留言'
              } else if (
                this.notify.target.identity === this.notify.who.identity
              ) {
                message += `也在他的觀點上留言`
              } else {
                message += `也在<span class="message--black message--bold px-1">${
                  this.notify.target.name.nick
                }${
                  this.notify.target.belongOrg
                  && _.get(this.notify.target, 'name.real', '')
                    ? `．${this.notify.target.name.real || ''}`
                    : ''
                }</span>的觀點上留言`
              }
              break
            case 'activity':
              if (this.notify.account === this.notify.target.identity) {
                message = '在你的動態上留言'
              } else if (
                this.notify.target.identity === this.notify.who.identity
              ) {
                message += `也在他的動態上留言`
              } else {
                message += `也在<span class="message--black message--bold px-1">${
                  this.notify.target.name.nick
                }${
                  this.notify.target.belongOrg
                  && _.get(this.notify.target, 'name.real', '')
                    ? `．${this.notify.target.name.real || ''}`
                    : ''
                }</span>的動態上留言`
              }
              break
            case 'comment':
              let target = `<span class="message--black message--bold px-1">${
                this.notify.target.name.nick
              }${
                this.notify.target.belongOrg
                && _.get(this.notify.target, 'name.real', '')
                  ? `．${this.notify.target.name.real || ''}`
                  : ''
              }</span>`
              if (this.notify.account === this.notify.target.identity) {
                target = '你'
              } else if (
                this.notify.target.identity === this.notify.who.identity
              ) {
                target = `他`
                message += '也'
              } else {
                message += '也'
              }
              let parentKind = ''
              if (this.notify.parent.kind === 'article') {
                parentKind = '觀點'
              } else if (this.notify.parent.kind === 'account') {
                parentKind = '達人問答'
              }
              message += `回覆了${target}在${parentKind}的留言`
              break
          }
        }
        return message
      },
      articleNotify () {
        return (
          this.notify
          && (this.notify.kind === 'paidArticle'
            || this.notify.kind === 'freeArticle')
        )
      },
      title () {
        if (this.articleNotify) {
          try {
            const json = JSON.parse(this.notify.text)
            return json.title || ''
          } catch (e) {
            return this.notify.text.split('##')[0]
          }
        } else {
          return false
        }
      },
      content () {
        if (this.articleNotify) {
          try {
            const json = JSON.parse(this.notify.text)
            return json.summary || ''
          } catch (e) {
            return this.notify.text.split('##')[1]
          }
        } else {
          return this.notify.text
        }
      },
      commentOrReply () {
        return (
          this.notify
          && (this.notify.kind === 'article'
            || this.notify.kind === 'activity'
            || this.notify.kind === 'account'
            || this.notify.kind === 'comment')
        )
      },
    },
    methods: {
      ...mapActions('api/notify', ['updateNotify']),
      getTime (time) {
        this.timeAgo = this.$moment(time).fromNow()
        const date = new Date(time)
        this.timeSeconds = time
        this.time = `${date.getFullYear()}/${
          date.getMonth() + 1
        }/${date.getDate()} ${date.getHours()}:${date.getMinutes()}`
      },
      markNotifyRead () {
        if (this.notify.read === null) {
          const option = {
            identity: this.notify.identity,
          }
          this.updateNotify(option).then(data => {
            if (!data.error) {
              this.$store.commit('user/readNotify', data)
            }
          })
        }
      },
      toggleMark () {
        const option = {
          identity: this.notify.identity,
          read:     this.notify.read === null,
        }
        this.updateNotify(option).then(data => {
          this.$store.commit('user/readNotify', data)
        })
      },
    },
    created () {
      if (this.notify.status) this.getTime(this.notify.status.created)
    },
    beforeDestroy () {
      clearInterval(this.setUpdateTime)
    },
  }
</script>
<style lang="scss" scoped>
.message--black {
  @important {
    color: #3d4a51;
  }
}

.message--bold {
  font-weight: bold;
}

.bg-read {
  background: $st_color_white;
}

.bg-unread {
  background-color: rgba($st_color_sub_light, 0.1);
}

.bg-unread:hover {
  background-color: rgba($st_color_sub_light, 0.2);
}

.bg-read:hover {
  background-color: #f6f6f6;
}

span {
  color: inherit;
}

a .fa,
a .fas,
a .far,
a .fab {
  opacity: 1;
}

.notifyItem {
  padding: 15px 7px 12px 7px;

  &__text--small {
    @important {
      font-size: 14px;
    }
  }

  &__isRead {
    display: none;
    font-weight: $st_font-weight-light;
    padding-top: 5px;

    @include media-breakpoint-up(sm) {
      @important {
        margin-top: 6px;
      }
    }

    &__read {
      display: inline-block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      background: $st_color_gray;
      margin-top: 2px;
    }

    &__unread {
      display: inline-block;
      border: solid 2px rgba(184, 188, 195, 0.5);
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: $st_color_white;
      margin-top: 2px;
    }

    &__margin {
      @important {
        margin-top: -1px;
      }

      @include media-breakpoint-up(sm) {
        @important {
          margin-top: 9px;
        }
      }
    }

    &--isTitle {
      @include media-breakpoint-up(sm) {
        @important {
          margin-top: 8px;
        }
      }
    }
  }

  &:hover &__isRead {
    display: flex;
  }

  &__name {
    color: $st_color_main_light;
    font-size: $st_font-size-base;
    letter-spacing: $st_font_letter-spacing;
    margin-right: 3px;
    height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &--flex {
    display: flex;
  }

  &__message {
    color: $st_color_gray_darker;
    font-size: $st_font-size-base;
    font-weight: $st_font-weight-light;
    letter-spacing: 1px;
  }

  &__icon {
    color: $st_color_gray;
    font-size: $st_font-size-base;
  }

  &__center {
    &__top {
      line-height: 16px;
    }

    &__bottom {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
      height: 42px;
      color: $st_color_gray_dark;
      margin-top: 8px;

      @important {
        line-height: 1.2rem;
        align-items: flex-start;
      }

      @include media-breakpoint-up(sm) {
        -webkit-line-clamp: 1;
        height: auto;
        margin-top: 15px;

        @important {
          line-height: 1.5rem;
        }
      }
    }
  }

  &__right {
    font-size: $st_font-size-small;
    color: $st_color_gray_dark;
    font-weight: $st_font-weight-medium;
    margin-top: 6px;
    min-width: 60px;
  }

  &__subscribe {
    color: $st_color_sub;
    font-size: $st_font-size-small;

    &__bg {
      background: rgba($st_color_sub_light, 0.1);
      border-radius: 15px;
      padding: 2px 3px;
    }

    &__icon {
      color: $st_color_sub;
      font-size: $st_font-size-small;

      @important {
        padding-right: 0;
      }

      &--size {
        font-size: $st_font-size-small;
      }
    }
  }

  &__title {
    font-size: $st_font-size-small;
    color: $st_color_gray_dark;
    letter-spacing: $st_font_letter-spacing;
    display: table-cell;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include media-breakpoint-up(sm) {
      max-width: 130px;
    }

    @include media-breakpoint-up(md) {
      max-width: 150px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 180px;
    }
  }

  &__content {
    color: #657b85;

    &__inside {
      position: relative;
      letter-spacing: $st_font_letter-spacing;
      color: $st_color_gray_darker;

      &--font {
        font-weight: $st_font-weight-light;
        color: $st_color_gray_dark;
      }
    }

    &--margin {
      margin-left: -8px;
    }
  }

  &__text {
    padding-top: 2px;
    margin-left: 6px;
  }
}

.onMenu {
  .notifyItem {
    &__center__bottom {
      -webkit-line-clamp: 2;
      height: 42px;

      @important {
        margin-top: 8px;
        line-height: 1.2rem;
      }
    }

    &__isRead {
      font-size: $st_font-size-small;

      @important {
        margin-top: 7px;
        padding-top: 0;
      }

      &__margin {
        @important {
          margin-top: 6px;
        }
      }
    }

    &__text {
      padding-top: 4px;
    }

    &__content {
      padding-top: 2px;
    }

    &--flex {
      display: flex;
    }

    &__title {
      margin-top: 1px;
      max-width: 140px;
    }

    &__right {
      font-size: $st_font-size-smaller;
      margin-top: 7px;
    }

    &__subscribe {
      font-size: $st_font-size-smaller;
    }
  }

  .notify-border-bottom {
    @important {
      padding: 7px 7px 12px 8px;
    }
  }
}
</style>
