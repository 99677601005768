import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66ada6bb = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _a2e3a0aa = () => interopDefault(import('../pages/apply-expert.vue' /* webpackChunkName: "pages/apply-expert" */))
const _0ebedc9e = () => interopDefault(import('../pages/become-expert.vue' /* webpackChunkName: "pages/become-expert" */))
const _14cd3629 = () => interopDefault(import('../pages/cooperation.vue' /* webpackChunkName: "pages/cooperation" */))
const _84807830 = () => interopDefault(import('../pages/demo/index.vue' /* webpackChunkName: "pages/demo/index" */))
const _3dc1ef44 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _6d586773 = () => interopDefault(import('../pages/liff-generate.vue' /* webpackChunkName: "pages/liff-generate" */))
const _3f97be5a = () => interopDefault(import('../pages/liff-redirect.vue' /* webpackChunkName: "pages/liff-redirect" */))
const _0ae7577e = () => interopDefault(import('../pages/liff-update-remind-setting.vue' /* webpackChunkName: "pages/liff-update-remind-setting" */))
const _32a5ed58 = () => interopDefault(import('../pages/maxApi.vue' /* webpackChunkName: "pages/maxApi" */))
const _1da60ef1 = () => interopDefault(import('../pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _5ec6f6ed = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _de3aabc0 = () => interopDefault(import('../pages/securities.vue' /* webpackChunkName: "pages/securities" */))
const _97653a60 = () => interopDefault(import('../pages/share/index.vue' /* webpackChunkName: "pages/share/index" */))
const _08fd16a8 = () => interopDefault(import('../pages/shareTargetPicker.vue' /* webpackChunkName: "pages/shareTargetPicker" */))
const _2759e40c = () => interopDefault(import('../pages/terms-of-linebot-privacy-liff.vue' /* webpackChunkName: "pages/terms-of-linebot-privacy-liff" */))
const _2b514d7a = () => interopDefault(import('../pages/terms-of-pay.vue' /* webpackChunkName: "pages/terms-of-pay" */))
const _161ea35a = () => interopDefault(import('../pages/terms-of-privacy.vue' /* webpackChunkName: "pages/terms-of-privacy" */))
const _4991e807 = () => interopDefault(import('../pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _86f452ce = () => interopDefault(import('../pages/wordcloud.vue' /* webpackChunkName: "pages/wordcloud" */))
const _5b2e832b = () => interopDefault(import('../pages/admin/coupon/index.vue' /* webpackChunkName: "pages/admin/coupon/index" */))
const _0ef1ade7 = () => interopDefault(import('../pages/admin/orders.vue' /* webpackChunkName: "pages/admin/orders" */))
const _51248440 = () => interopDefault(import('../pages/article/manage.vue' /* webpackChunkName: "pages/article/manage" */))
const _7e8217c4 = () => interopDefault(import('../pages/demo/form.vue' /* webpackChunkName: "pages/demo/form" */))
const _78874153 = () => interopDefault(import('../pages/demo/icon.vue' /* webpackChunkName: "pages/demo/icon" */))
const _168fca10 = () => interopDefault(import('../pages/demo/list.vue' /* webpackChunkName: "pages/demo/list" */))
const _fef3918e = () => interopDefault(import('../pages/demo/menu.vue' /* webpackChunkName: "pages/demo/menu" */))
const _212aa734 = () => interopDefault(import('../pages/demo/snackbar&modal.vue' /* webpackChunkName: "pages/demo/snackbar&modal" */))
const _6f7f2dd8 = () => interopDefault(import('../pages/demo/tabs.vue' /* webpackChunkName: "pages/demo/tabs" */))
const _273eb81b = () => interopDefault(import('../pages/expert/manage/index.vue' /* webpackChunkName: "pages/expert/manage/index" */))
const _2efdf48a = () => interopDefault(import('../pages/helper/login.vue' /* webpackChunkName: "pages/helper/login" */))
const _e09d0f80 = () => interopDefault(import('../pages/my/activity.vue' /* webpackChunkName: "pages/my/activity" */))
const _40381d02 = () => interopDefault(import('../pages/my/cards.vue' /* webpackChunkName: "pages/my/cards" */))
const _57453a8d = () => interopDefault(import('../pages/my/events/index.vue' /* webpackChunkName: "pages/my/events/index" */))
const _bcaa4686 = () => interopDefault(import('../pages/my/followTo.vue' /* webpackChunkName: "pages/my/followTo" */))
const _4f79977b = () => interopDefault(import('../pages/my/logout.vue' /* webpackChunkName: "pages/my/logout" */))
const _27c421ba = () => interopDefault(import('../pages/my/notify.vue' /* webpackChunkName: "pages/my/notify" */))
const _3670cf6d = () => interopDefault(import('../pages/my/order.vue' /* webpackChunkName: "pages/my/order" */))
const _3f051408 = () => interopDefault(import('../pages/my/ownRoles.vue' /* webpackChunkName: "pages/my/ownRoles" */))
const _5d835a9f = () => interopDefault(import('../pages/my/portfolios/index.vue' /* webpackChunkName: "pages/my/portfolios/index" */))
const _e0855cce = () => interopDefault(import('../pages/my/securitiesFirms/index.vue' /* webpackChunkName: "pages/my/securitiesFirms/index" */))
const _4e759754 = () => interopDefault(import('../pages/my/settings.vue' /* webpackChunkName: "pages/my/settings" */))
const _bef4ba32 = () => interopDefault(import('../pages/my/settings-for-LanternFestival2019.vue' /* webpackChunkName: "pages/my/settings-for-LanternFestival2019" */))
const _1842100e = () => interopDefault(import('../pages/my/start-liff.vue' /* webpackChunkName: "pages/my/start-liff" */))
const _040dead8 = () => interopDefault(import('../pages/my/subscriptions/index.vue' /* webpackChunkName: "pages/my/subscriptions/index" */))
const _d12b25a6 = () => interopDefault(import('../pages/stock/activity/index.vue' /* webpackChunkName: "pages/stock/activity/index" */))
const _1a7af8f6 = () => interopDefault(import('../pages/admin/advertisement/add.vue' /* webpackChunkName: "pages/admin/advertisement/add" */))
const _6e834131 = () => interopDefault(import('../pages/admin/advertisement/manage.vue' /* webpackChunkName: "pages/admin/advertisement/manage" */))
const _73c070b2 = () => interopDefault(import('../pages/admin/advertisement/order.vue' /* webpackChunkName: "pages/admin/advertisement/order" */))
const _61788d20 = () => interopDefault(import('../pages/admin/article/manage.vue' /* webpackChunkName: "pages/admin/article/manage" */))
const _b19bbc2a = () => interopDefault(import('../pages/admin/article/stockPost.vue' /* webpackChunkName: "pages/admin/article/stockPost" */))
const _798d8fba = () => interopDefault(import('../pages/admin/coupon/add.vue' /* webpackChunkName: "pages/admin/coupon/add" */))
const _410640aa = () => interopDefault(import('../pages/expert/manage/add.vue' /* webpackChunkName: "pages/expert/manage/add" */))
const _718fc7f0 = () => interopDefault(import('../pages/my/exchange/capital/index.vue' /* webpackChunkName: "pages/my/exchange/capital/index" */))
const _7e59b42e = () => interopDefault(import('../pages/my/portfolios/add.vue' /* webpackChunkName: "pages/my/portfolios/add" */))
const _0eb90e66 = () => interopDefault(import('../pages/my/portfolios/edit.vue' /* webpackChunkName: "pages/my/portfolios/edit" */))
const _38df6868 = () => interopDefault(import('../pages/my/portfolios/notify.vue' /* webpackChunkName: "pages/my/portfolios/notify" */))
const _524cb665 = () => interopDefault(import('../pages/my/securitiesFirms/transaction.vue' /* webpackChunkName: "pages/my/securitiesFirms/transaction" */))
const _021b34a4 = () => interopDefault(import('../pages/my/exchange/capital/point.vue' /* webpackChunkName: "pages/my/exchange/capital/point" */))
const _563b601a = () => interopDefault(import('../pages/my/exchange/honsec/admin/_admin.vue' /* webpackChunkName: "pages/my/exchange/honsec/admin/_admin" */))
const _2e7fec02 = () => interopDefault(import('../pages/my/exchange/honsec/_type.vue' /* webpackChunkName: "pages/my/exchange/honsec/_type" */))
const _82476394 = () => interopDefault(import('../pages/admin/coupon/_cid.vue' /* webpackChunkName: "pages/admin/coupon/_cid" */))
const _3c6989d6 = () => interopDefault(import('../pages/expert/manage/_pid/index.vue' /* webpackChunkName: "pages/expert/manage/_pid/index" */))
const _390c9916 = () => interopDefault(import('../pages/my/events/_eid.vue' /* webpackChunkName: "pages/my/events/_eid" */))
const _7bd52df8 = () => interopDefault(import('../pages/my/review/_cid/index.vue' /* webpackChunkName: "pages/my/review/_cid/index" */))
const _022e70f9 = () => interopDefault(import('../pages/my/subscriptions/_sid.vue' /* webpackChunkName: "pages/my/subscriptions/_sid" */))
const _81c85256 = () => interopDefault(import('../pages/stock/activity/_id.vue' /* webpackChunkName: "pages/stock/activity/_id" */))
const _9fe9b7ce = () => interopDefault(import('../pages/stock/news/_id.vue' /* webpackChunkName: "pages/stock/news/_id" */))
const _149949ea = () => interopDefault(import('../pages/admin/advertisement/_id/edit.vue' /* webpackChunkName: "pages/admin/advertisement/_id/edit" */))
const _a5da63d2 = () => interopDefault(import('../pages/expert/manage/_pid/transfer.vue' /* webpackChunkName: "pages/expert/manage/_pid/transfer" */))
const _18438402 = () => interopDefault(import('../pages/activity/_id/index.vue' /* webpackChunkName: "pages/activity/_id/index" */))
const _31f35112 = () => interopDefault(import('../pages/article/_id/index.vue' /* webpackChunkName: "pages/article/_id/index" */))
const _0576845e = () => interopDefault(import('../pages/binding/_id.vue' /* webpackChunkName: "pages/binding/_id" */))
const _50d4b09b = () => interopDefault(import('../pages/event/_eid/index.vue' /* webpackChunkName: "pages/event/_eid/index" */))
const _374512cb = () => interopDefault(import('../pages/explore/_type.vue' /* webpackChunkName: "pages/explore/_type" */))
const _a93a5af2 = () => interopDefault(import('../pages/o/_oas/index.vue' /* webpackChunkName: "pages/o/_oas/index" */))
const _1006de70 = () => interopDefault(import('../pages/u/_uid/index.vue' /* webpackChunkName: "pages/u/_uid/index" */))
const _641d300c = () => interopDefault(import('../pages/article/_id/edit.vue' /* webpackChunkName: "pages/article/_id/edit" */))
const _79a95656 = () => interopDefault(import('../pages/event/_eid/checkIn.vue' /* webpackChunkName: "pages/event/_eid/checkIn" */))
const _53117351 = () => interopDefault(import('../pages/event/_eid/edit.vue' /* webpackChunkName: "pages/event/_eid/edit" */))
const _640b5a28 = () => interopDefault(import('../pages/event/_eid/orders.vue' /* webpackChunkName: "pages/event/_eid/orders" */))
const _8eef8226 = () => interopDefault(import('../pages/event/_eid/register/index.vue' /* webpackChunkName: "pages/event/_eid/register/index" */))
const _b2938854 = () => interopDefault(import('../pages/event/_eid/ticket/index.vue' /* webpackChunkName: "pages/event/_eid/ticket/index" */))
const _9d43f62c = () => interopDefault(import('../pages/o/_oas/activity.vue' /* webpackChunkName: "pages/o/_oas/activity" */))
const _e9681cca = () => interopDefault(import('../pages/o/_oas/articles/index.vue' /* webpackChunkName: "pages/o/_oas/articles/index" */))
const _6ade5ecf = () => interopDefault(import('../pages/o/_oas/follow/index.vue' /* webpackChunkName: "pages/o/_oas/follow/index" */))
const _43345aa6 = () => interopDefault(import('../pages/o/_oas/service/index.vue' /* webpackChunkName: "pages/o/_oas/service/index" */))
const _702223fe = () => interopDefault(import('../pages/o/_oas/settings.vue' /* webpackChunkName: "pages/o/_oas/settings" */))
const _7b181718 = () => interopDefault(import('../pages/stock/_tickerSymbol/composition/index.vue' /* webpackChunkName: "pages/stock/_tickerSymbol/composition/index" */))
const _5fa40461 = () => interopDefault(import('../pages/u/_uid/activity.vue' /* webpackChunkName: "pages/u/_uid/activity" */))
const _c447885c = () => interopDefault(import('../pages/u/_uid/articles/index.vue' /* webpackChunkName: "pages/u/_uid/articles/index" */))
const _60b3e71a = () => interopDefault(import('../pages/u/_uid/collection/index.vue' /* webpackChunkName: "pages/u/_uid/collection/index" */))
const _7e99cac6 = () => interopDefault(import('../pages/u/_uid/follow/index.vue' /* webpackChunkName: "pages/u/_uid/follow/index" */))
const _28d19eec = () => interopDefault(import('../pages/u/_uid/order.vue' /* webpackChunkName: "pages/u/_uid/order" */))
const _4217e596 = () => interopDefault(import('../pages/u/_uid/service/index.vue' /* webpackChunkName: "pages/u/_uid/service/index" */))
const _1e682375 = () => interopDefault(import('../pages/u/_uid/settings.vue' /* webpackChunkName: "pages/u/_uid/settings" */))
const _32f323e8 = () => interopDefault(import('../pages/u/_uid/subscribe.vue' /* webpackChunkName: "pages/u/_uid/subscribe" */))
const _678986aa = () => interopDefault(import('../pages/u/_uid/videos.vue' /* webpackChunkName: "pages/u/_uid/videos" */))
const _bfa7abb8 = () => interopDefault(import('../pages/event/_eid/ticket/refund.vue' /* webpackChunkName: "pages/event/_eid/ticket/refund" */))
const _30902878 = () => interopDefault(import('../pages/event/_eid/ticket/signup.vue' /* webpackChunkName: "pages/event/_eid/ticket/signup" */))
const _2b060cfd = () => interopDefault(import('../pages/o/_oas/article/add.vue' /* webpackChunkName: "pages/o/_oas/article/add" */))
const _48f52c6c = () => interopDefault(import('../pages/o/_oas/articles/manage.vue' /* webpackChunkName: "pages/o/_oas/articles/manage" */))
const _2baf0fba = () => interopDefault(import('../pages/o/_oas/follow/followed.vue' /* webpackChunkName: "pages/o/_oas/follow/followed" */))
const _e27c73b4 = () => interopDefault(import('../pages/u/_uid/article/add.vue' /* webpackChunkName: "pages/u/_uid/article/add" */))
const _ef23add6 = () => interopDefault(import('../pages/u/_uid/articles/manage.vue' /* webpackChunkName: "pages/u/_uid/articles/manage" */))
const _c40abf52 = () => interopDefault(import('../pages/u/_uid/collection/article.vue' /* webpackChunkName: "pages/u/_uid/collection/article" */))
const _0b76bad4 = () => interopDefault(import('../pages/u/_uid/collection/service.vue' /* webpackChunkName: "pages/u/_uid/collection/service" */))
const _2d7fb14a = () => interopDefault(import('../pages/u/_uid/event/add.vue' /* webpackChunkName: "pages/u/_uid/event/add" */))
const _29a174cc = () => interopDefault(import('../pages/u/_uid/follow/followed.vue' /* webpackChunkName: "pages/u/_uid/follow/followed" */))
const _0d3a14c8 = () => interopDefault(import('../pages/u/_uid/follow/followTo.vue' /* webpackChunkName: "pages/u/_uid/follow/followTo" */))
const _9bdde166 = () => interopDefault(import('../pages/u/_uid/service/analytics/index.vue' /* webpackChunkName: "pages/u/_uid/service/analytics/index" */))
const _33a87ba9 = () => interopDefault(import('../pages/u/_uid/service/articles.vue' /* webpackChunkName: "pages/u/_uid/service/articles" */))
const _65f7e044 = () => interopDefault(import('../pages/u/_uid/service/review.vue' /* webpackChunkName: "pages/u/_uid/service/review" */))
const _d76c6b0c = () => interopDefault(import('../pages/u/_uid/service/analytics/orders.vue' /* webpackChunkName: "pages/u/_uid/service/analytics/orders" */))
const _55de58cc = () => interopDefault(import('../pages/u/_uid/service/article/add.vue' /* webpackChunkName: "pages/u/_uid/service/article/add" */))
const _37073942 = () => interopDefault(import('../pages/u/_uid/service/events/manage.vue' /* webpackChunkName: "pages/u/_uid/service/events/manage" */))
const _275c5ab4 = () => interopDefault(import('../pages/u/_uid/service/product/manage/index.vue' /* webpackChunkName: "pages/u/_uid/service/product/manage/index" */))
const _a5a494fa = () => interopDefault(import('../pages/u/_uid/service/product/manage/add.vue' /* webpackChunkName: "pages/u/_uid/service/product/manage/add" */))
const _a21c384c = () => interopDefault(import('../pages/u/_uid/service/product/manage/_pid.vue' /* webpackChunkName: "pages/u/_uid/service/product/manage/_pid" */))
const _1047534a = () => interopDefault(import('../pages/u/_uid/service/product/_pid/index.vue' /* webpackChunkName: "pages/u/_uid/service/product/_pid/index" */))
const _594bc7dc = () => interopDefault(import('../pages/u/_uid/service/product/_pid/_oid.vue' /* webpackChunkName: "pages/u/_uid/service/product/_pid/_oid" */))
const _2c25d940 = () => interopDefault(import('../pages/event/_eid/register/_oid.vue' /* webpackChunkName: "pages/event/_eid/register/_oid" */))
const _9b173640 = () => interopDefault(import('../pages/stock/_tickerSymbol/_type.vue' /* webpackChunkName: "pages/stock/_tickerSymbol/_type" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _66ada6bb,
    name: "about"
  }, {
    path: "/apply-expert",
    component: _a2e3a0aa,
    name: "apply-expert"
  }, {
    path: "/become-expert",
    component: _0ebedc9e,
    name: "become-expert"
  }, {
    path: "/cooperation",
    component: _14cd3629,
    name: "cooperation"
  }, {
    path: "/demo",
    component: _84807830,
    name: "demo"
  }, {
    path: "/faq",
    component: _3dc1ef44,
    name: "faq"
  }, {
    path: "/liff-generate",
    component: _6d586773,
    name: "liff-generate"
  }, {
    path: "/liff-redirect",
    component: _3f97be5a,
    name: "liff-redirect"
  }, {
    path: "/liff-update-remind-setting",
    component: _0ae7577e,
    name: "liff-update-remind-setting"
  }, {
    path: "/maxApi",
    component: _32a5ed58,
    name: "maxApi"
  }, {
    path: "/my",
    component: _1da60ef1,
    name: "my"
  }, {
    path: "/search",
    component: _5ec6f6ed,
    name: "search"
  }, {
    path: "/securities",
    component: _de3aabc0,
    name: "securities"
  }, {
    path: "/share",
    component: _97653a60,
    name: "share"
  }, {
    path: "/shareTargetPicker",
    component: _08fd16a8,
    name: "shareTargetPicker"
  }, {
    path: "/terms-of-linebot-privacy-liff",
    component: _2759e40c,
    name: "terms-of-linebot-privacy-liff"
  }, {
    path: "/terms-of-pay",
    component: _2b514d7a,
    name: "terms-of-pay"
  }, {
    path: "/terms-of-privacy",
    component: _161ea35a,
    name: "terms-of-privacy"
  }, {
    path: "/terms-of-service",
    component: _4991e807,
    name: "terms-of-service"
  }, {
    path: "/wordcloud",
    component: _86f452ce,
    name: "wordcloud"
  }, {
    path: "/admin/coupon",
    component: _5b2e832b,
    name: "admin-coupon"
  }, {
    path: "/admin/orders",
    component: _0ef1ade7,
    name: "admin-orders"
  }, {
    path: "/article/manage",
    component: _51248440,
    name: "article-manage"
  }, {
    path: "/demo/form",
    component: _7e8217c4,
    name: "demo-form"
  }, {
    path: "/demo/icon",
    component: _78874153,
    name: "demo-icon"
  }, {
    path: "/demo/list",
    component: _168fca10,
    name: "demo-list"
  }, {
    path: "/demo/menu",
    component: _fef3918e,
    name: "demo-menu"
  }, {
    path: "/demo/snackbar%26modal",
    component: _212aa734,
    name: "demo-snackbar&modal"
  }, {
    path: "/demo/tabs",
    component: _6f7f2dd8,
    name: "demo-tabs"
  }, {
    path: "/expert/manage",
    component: _273eb81b,
    name: "expert-manage"
  }, {
    path: "/helper/login",
    component: _2efdf48a,
    name: "helper-login"
  }, {
    path: "/my/activity",
    component: _e09d0f80,
    name: "my-activity"
  }, {
    path: "/my/cards",
    component: _40381d02,
    name: "my-cards"
  }, {
    path: "/my/events",
    component: _57453a8d,
    name: "my-events"
  }, {
    path: "/my/followTo",
    component: _bcaa4686,
    name: "my-followTo"
  }, {
    path: "/my/logout",
    component: _4f79977b,
    name: "my-logout"
  }, {
    path: "/my/notify",
    component: _27c421ba,
    name: "my-notify"
  }, {
    path: "/my/order",
    component: _3670cf6d,
    name: "my-order"
  }, {
    path: "/my/ownRoles",
    component: _3f051408,
    name: "my-ownRoles"
  }, {
    path: "/my/portfolios",
    component: _5d835a9f,
    name: "my-portfolios"
  }, {
    path: "/my/securitiesFirms",
    component: _e0855cce,
    name: "my-securitiesFirms"
  }, {
    path: "/my/settings",
    component: _4e759754,
    name: "my-settings"
  }, {
    path: "/my/settings-for-LanternFestival2019",
    component: _bef4ba32,
    name: "my-settings-for-LanternFestival2019"
  }, {
    path: "/my/start-liff",
    component: _1842100e,
    name: "my-start-liff"
  }, {
    path: "/my/subscriptions",
    component: _040dead8,
    name: "my-subscriptions"
  }, {
    path: "/stock/activity",
    component: _d12b25a6,
    name: "stock-activity"
  }, {
    path: "/admin/advertisement/add",
    component: _1a7af8f6,
    name: "admin-advertisement-add"
  }, {
    path: "/admin/advertisement/manage",
    component: _6e834131,
    name: "admin-advertisement-manage"
  }, {
    path: "/admin/advertisement/order",
    component: _73c070b2,
    name: "admin-advertisement-order"
  }, {
    path: "/admin/article/manage",
    component: _61788d20,
    name: "admin-article-manage"
  }, {
    path: "/admin/article/stockPost",
    component: _b19bbc2a,
    name: "admin-article-stockPost"
  }, {
    path: "/admin/coupon/add",
    component: _798d8fba,
    name: "admin-coupon-add"
  }, {
    path: "/expert/manage/add",
    component: _410640aa,
    name: "expert-manage-add"
  }, {
    path: "/my/exchange/capital",
    component: _718fc7f0,
    name: "my-exchange-capital"
  }, {
    path: "/my/portfolios/add",
    component: _7e59b42e,
    name: "my-portfolios-add"
  }, {
    path: "/my/portfolios/edit",
    component: _0eb90e66,
    name: "my-portfolios-edit"
  }, {
    path: "/my/portfolios/notify",
    component: _38df6868,
    name: "my-portfolios-notify"
  }, {
    path: "/my/securitiesFirms/transaction",
    component: _524cb665,
    name: "my-securitiesFirms-transaction"
  }, {
    path: "/my/exchange/capital/point",
    component: _021b34a4,
    name: "my-exchange-capital-point"
  }, {
    path: "/my/exchange/honsec/admin/:admin?",
    component: _563b601a,
    name: "my-exchange-honsec-admin-admin"
  }, {
    path: "/my/exchange/honsec/:type?",
    component: _2e7fec02,
    name: "my-exchange-honsec-type"
  }, {
    path: "/admin/coupon/:cid",
    component: _82476394,
    name: "admin-coupon-cid"
  }, {
    path: "/expert/manage/:pid",
    component: _3c6989d6,
    name: "expert-manage-pid"
  }, {
    path: "/my/events/:eid",
    component: _390c9916,
    name: "my-events-eid"
  }, {
    path: "/my/review/:cid",
    component: _7bd52df8,
    name: "my-review-cid"
  }, {
    path: "/my/subscriptions/:sid",
    component: _022e70f9,
    name: "my-subscriptions-sid"
  }, {
    path: "/stock/activity/:id",
    component: _81c85256,
    name: "stock-activity-id"
  }, {
    path: "/stock/news/:id?",
    component: _9fe9b7ce,
    name: "stock-news-id"
  }, {
    path: "/admin/advertisement/:id?/edit",
    component: _149949ea,
    name: "admin-advertisement-id-edit"
  }, {
    path: "/expert/manage/:pid/transfer",
    component: _a5da63d2,
    name: "expert-manage-pid-transfer"
  }, {
    path: "/activity/:id",
    component: _18438402,
    name: "activity-id"
  }, {
    path: "/article/:id",
    component: _31f35112,
    name: "article-id"
  }, {
    path: "/binding/:id?",
    component: _0576845e,
    name: "binding-id"
  }, {
    path: "/event/:eid",
    component: _50d4b09b,
    name: "event-eid"
  }, {
    path: "/explore/:type?",
    component: _374512cb,
    name: "explore-type"
  }, {
    path: "/o/:oas",
    component: _a93a5af2,
    name: "o-oas"
  }, {
    path: "/u/:uid",
    component: _1006de70,
    name: "u-uid"
  }, {
    path: "/article/:id?/edit",
    component: _641d300c,
    name: "article-id-edit"
  }, {
    path: "/event/:eid?/checkIn",
    component: _79a95656,
    name: "event-eid-checkIn"
  }, {
    path: "/event/:eid?/edit",
    component: _53117351,
    name: "event-eid-edit"
  }, {
    path: "/event/:eid?/orders",
    component: _640b5a28,
    name: "event-eid-orders"
  }, {
    path: "/event/:eid?/register",
    component: _8eef8226,
    name: "event-eid-register"
  }, {
    path: "/event/:eid?/ticket",
    component: _b2938854,
    name: "event-eid-ticket"
  }, {
    path: "/o/:oas?/activity",
    component: _9d43f62c,
    name: "o-oas-activity"
  }, {
    path: "/o/:oas?/articles",
    component: _e9681cca,
    name: "o-oas-articles"
  }, {
    path: "/o/:oas?/follow",
    component: _6ade5ecf,
    name: "o-oas-follow"
  }, {
    path: "/o/:oas?/service",
    component: _43345aa6,
    name: "o-oas-service"
  }, {
    path: "/o/:oas?/settings",
    component: _702223fe,
    name: "o-oas-settings"
  }, {
    path: "/stock/:tickerSymbol?/composition",
    component: _7b181718,
    name: "stock-tickerSymbol-composition"
  }, {
    path: "/u/:uid?/activity",
    component: _5fa40461,
    name: "u-uid-activity"
  }, {
    path: "/u/:uid?/articles",
    component: _c447885c,
    name: "u-uid-articles"
  }, {
    path: "/u/:uid?/collection",
    component: _60b3e71a,
    name: "u-uid-collection"
  }, {
    path: "/u/:uid?/follow",
    component: _7e99cac6,
    name: "u-uid-follow"
  }, {
    path: "/u/:uid?/order",
    component: _28d19eec,
    name: "u-uid-order"
  }, {
    path: "/u/:uid?/service",
    component: _4217e596,
    name: "u-uid-service"
  }, {
    path: "/u/:uid?/settings",
    component: _1e682375,
    name: "u-uid-settings"
  }, {
    path: "/u/:uid?/subscribe",
    component: _32f323e8,
    name: "u-uid-subscribe"
  }, {
    path: "/u/:uid?/videos",
    component: _678986aa,
    name: "u-uid-videos"
  }, {
    path: "/event/:eid?/ticket/refund",
    component: _bfa7abb8,
    name: "event-eid-ticket-refund"
  }, {
    path: "/event/:eid?/ticket/signup",
    component: _30902878,
    name: "event-eid-ticket-signup"
  }, {
    path: "/o/:oas?/article/add",
    component: _2b060cfd,
    name: "o-oas-article-add"
  }, {
    path: "/o/:oas?/articles/manage",
    component: _48f52c6c,
    name: "o-oas-articles-manage"
  }, {
    path: "/o/:oas?/follow/followed",
    component: _2baf0fba,
    name: "o-oas-follow-followed"
  }, {
    path: "/u/:uid?/article/add",
    component: _e27c73b4,
    name: "u-uid-article-add"
  }, {
    path: "/u/:uid?/articles/manage",
    component: _ef23add6,
    name: "u-uid-articles-manage"
  }, {
    path: "/u/:uid?/collection/article",
    component: _c40abf52,
    name: "u-uid-collection-article"
  }, {
    path: "/u/:uid?/collection/service",
    component: _0b76bad4,
    name: "u-uid-collection-service"
  }, {
    path: "/u/:uid?/event/add",
    component: _2d7fb14a,
    name: "u-uid-event-add"
  }, {
    path: "/u/:uid?/follow/followed",
    component: _29a174cc,
    name: "u-uid-follow-followed"
  }, {
    path: "/u/:uid?/follow/followTo",
    component: _0d3a14c8,
    name: "u-uid-follow-followTo"
  }, {
    path: "/u/:uid?/service/analytics",
    component: _9bdde166,
    name: "u-uid-service-analytics"
  }, {
    path: "/u/:uid?/service/articles",
    component: _33a87ba9,
    name: "u-uid-service-articles"
  }, {
    path: "/u/:uid?/service/review",
    component: _65f7e044,
    name: "u-uid-service-review"
  }, {
    path: "/u/:uid?/service/analytics/orders",
    component: _d76c6b0c,
    name: "u-uid-service-analytics-orders"
  }, {
    path: "/u/:uid?/service/article/add",
    component: _55de58cc,
    name: "u-uid-service-article-add"
  }, {
    path: "/u/:uid?/service/events/manage",
    component: _37073942,
    name: "u-uid-service-events-manage"
  }, {
    path: "/u/:uid?/service/product/manage",
    component: _275c5ab4,
    name: "u-uid-service-product-manage"
  }, {
    path: "/u/:uid?/service/product/manage/add",
    component: _a5a494fa,
    name: "u-uid-service-product-manage-add"
  }, {
    path: "/u/:uid?/service/product/manage/:pid",
    component: _a21c384c,
    name: "u-uid-service-product-manage-pid"
  }, {
    path: "/u/:uid?/service/product/:pid",
    component: _1047534a,
    name: "u-uid-service-product-pid"
  }, {
    path: "/u/:uid?/service/product/:pid?/:oid",
    component: _594bc7dc,
    name: "u-uid-service-product-pid-oid"
  }, {
    path: "/event/:eid?/register/:oid",
    component: _2c25d940,
    name: "event-eid-register-oid"
  }, {
    path: "/stock/:tickerSymbol?/:type?",
    component: _9b173640,
    name: "stock-tickerSymbol-type"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index"
  }, {
    path: "/o/:oas?/collection",
    redirect: {"name":"o-oas"},
    name: "o-oas-collection"
  }, {
    path: "/o/:oas?/order",
    redirect: {"name":"o-oas"},
    name: "o-oas-order"
  }, {
    path: "/o/:oas?/subscribe",
    redirect: {"name":"o-oas"},
    name: "o-oas-subscribe"
  }, {
    path: "/o/:oas?/videos",
    redirect: {"name":"o-oas"},
    name: "o-oas-videos"
  }, {
    path: "/o/:oas?/collection/article",
    redirect: {"name":"o-oas"},
    name: "o-oas-collection-article"
  }, {
    path: "/o/:oas?/collection/service",
    redirect: {"name":"o-oas"},
    name: "o-oas-collection-service"
  }, {
    path: "/o/:oas?/event/add",
    redirect: {"name":"o-oas"},
    name: "o-oas-event-add"
  }, {
    path: "/o/:oas?/follow/followTo",
    redirect: {"name":"o-oas"},
    name: "o-oas-follow-followTo"
  }, {
    path: "/o/:oas?/service/analytics",
    redirect: {"name":"o-oas"},
    name: "o-oas-service-analytics"
  }, {
    path: "/o/:oas?/service/articles",
    redirect: {"name":"o-oas"},
    name: "o-oas-service-articles"
  }, {
    path: "/o/:oas?/service/review",
    redirect: {"name":"o-oas"},
    name: "o-oas-service-review"
  }, {
    path: "/o/:oas?/service/analytics/orders",
    redirect: {"name":"o-oas"},
    name: "o-oas-service-analytics-orders"
  }, {
    path: "/o/:oas?/service/article/add",
    redirect: {"name":"o-oas"},
    name: "o-oas-service-article-add"
  }, {
    path: "/o/:oas?/service/events/manage",
    redirect: {"name":"o-oas"},
    name: "o-oas-service-events-manage"
  }, {
    path: "/o/:oas?/service/product/manage",
    redirect: {"name":"o-oas"},
    name: "o-oas-service-product-manage"
  }, {
    path: "/o/:oas?/service/product/manage/add",
    redirect: {"name":"o-oas"},
    name: "o-oas-service-product-manage-add"
  }, {
    path: "/o/:oas?/service/product/manage/:pid",
    redirect: {"name":"o-oas"},
    name: "o-oas-service-product-manage-pid"
  }, {
    path: "/o/:oas?/service/product/:pid",
    redirect: {"name":"o-oas"},
    name: "o-oas-service-product-pid"
  }, {
    path: "/o/:oas?/service/product/:pid?/:oid",
    redirect: {"name":"o-oas"},
    name: "o-oas-service-product-pid-oid"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
