import apiUtils from '~/utils/api'

export const actions = {
  getCollection ({}, option) {
    // option = {
    //   identity : <String>,
    //   account : <String>,
    //   kind    : <String>, article || account || channel
    //   index   : <String>,
    // }
    const identity = option.identity || null
    const { account, kind, index } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: ['identity', 'account.identity', 'account.serial', 'kind', 'index'],
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
    } else {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
        {
          field:    'index',
          operator: '=',
          value:    index,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/collection${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getCollections ({}, option) {
    // option = {
    //   kind  : <String>, article || account || channel
    //   account : <String>,
    //   index : <String>,
    //   fields: <Array>,
    //   page  : <Number>,
    //   limit : <Number>,
    //   sort  : <Object>,
    // }
    const { kind } = option
    const index = option.index || null
    const account = option.account || null
    const fields = option.fields || ['identity', 'kind', 'index']
    // ['identity', 'kind', 'index', 'account.identity', 'account.serial', 'account.avatar', 'account.name', 'account.service.approved', 'indexInfo.identity', 'indexInfo.serial', 'indexInfo.avatar', 'indexInfo.name', 'indexInfo.service', 'indexInfo.followed', 'indexInfo.belongOrg', 'indexInfo.belongOrgInfo', 'lineNotifyRelation.identity', 'lineNotifyRelation.mode']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (
      page - 1)
    const sort = option.sort || { 'status.updated': 1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      sort:   sort,
      fields: fields,
    }
    if (!_.isNull(index)) {
      query.filter.push(
        {
          field:    'index',
          operator: '=',
          value:    index,
          logic:    0,
        },
      )
    }
    if (!_.isNull(account)) {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/collections${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getCollectionsNum ({}, option) {
    // option = {
    //   kind  : <String>, article || account || channel
    //   account : <String>,
    //   index : <String>,
    //   fields: <Array>,
    //   page  : <Number>,
    //   limit : <Number>,
    //   sort  : <Object>,
    // }
    const { kind } = option
    const index = option.index || null
    const account = option.account || null
    const fields = option.fields || ['identity', 'kind', 'index']
    // ['identity', 'kind', 'index', 'account.identity', 'account.serial', 'account.avatar', 'account.name', 'account.service.approved', 'indexInfo.identity', 'indexInfo.serial', 'indexInfo.avatar', 'indexInfo.name', 'indexInfo.service', 'indexInfo.followed', 'indexInfo.belongOrg', 'indexInfo.belongOrgInfo', 'lineNotifyRelation.identity', 'lineNotifyRelation.mode']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (
      page - 1)
    const sort = option.sort || { 'status.updated': 1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      sort:   sort,
      fields: fields,
    }
    if (!_.isNull(index)) {
      query.filter.push(
        {
          field:    'index',
          operator: '=',
          value:    index,
          logic:    0,
        },
      )
    }
    if (!_.isNull(account)) {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/collections/num${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createCollection ({ dispatch }, collection) {
    // collection = {
    //   account: <String>,
    //   kind   : <String>, article || activity
    //   index  : <String>,
    // }
    const query = {
      filter: [
        {
          field:    'account',
          operator: '=',
          value:    collection.account,
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    collection.kind,
          logic:    0,
        },
        {
          field:    'index',
          operator: '=',
          value:    collection.index,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    collection['status.deleted'] = null
    const body = {
      data: {
        type:       'collection',
        attributes: collection,
      },
    }

    return this.$axios.put(`${this.state.setting.apiUrlSocial}/collection${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteCollection ({ dispatch }, option) {
    // option = {
    //   identity : <String>,
    //   account : <String>,
    //   kind    : <String>, article || activity
    //   index   : <String>,
    // }
    const identity = option.identity || null
    const { account, kind, index } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: ['identity'],
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
    } else {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
        {
          field:    'index',
          operator: '=',
          value:    index,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/collection${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
