import apiUtils from '~/utils/api'

export const actions = {
  getReply ({}, option) {
    // option = {
    //   identity: <String>,
    //   account : <String>,
    //   comment   : <String>,
    //   content : <String>,
    //   fields  : <Array>
    // }
    const identity = option.identity || null
    const { account, comment, content } = option
    const fields = option.fields || ['identity', 'comment', 'content', 'image', 'likes', 'account.identity', 'account.serial', 'account.avatar', 'account.name', 'account.ownRoles', 'account.belongOrg', 'account.belongOrgInfo', 'status.created']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: fields,
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
    } else {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
        {
          field:    'comment',
          operator: '=',
          value:    comment,
          logic:    0,
        },
        {
          field:    'content',
          operator: '=',
          value:    content,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/reply${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getReplies ({}, option) {
    // option = {
    //   comment : <String>,
    //   fields: <Array>,
    //   page  : <Number>,
    //   limit : <Number>,
    //   sort  : <Object>,
    // }
    const { comment } = option
    const fields = option.fields || ['identity', 'content', 'image', 'account.kind', 'account.organization.kind', 'account.organization.alias', 'account.service', 'account.identity', 'account.serial', 'account.avatar', 'account.name', 'account.ownRoles', 'account.belongOrg', 'account.belongOrgInfo', 'status.created']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (page - 1)
    const sort = option.sort || { 'status.created': 1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'comment',
          operator: _.isArray(comment) ? 'In' : '=',
          value:    comment,
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      sort:   sort,
      fields: fields,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/replies${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createReply ({}, reply) {
    // reply = {
    //   account: <String>,
    //   comment  : <String>,
    //   content: <String>,
    //   summary: <String>,
    //   image  : <Url>,
    //   marketTags: <Array>,
    // }
    const body = {
      data: {
        type:       'reply',
        attributes: reply,
      },
    }

    return this.$axios.post(`${this.state.setting.apiUrlSocial}/reply`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  updateReply ({}, option) {
    // option = {
    //   identity : <String>,
    //   content  : <String>,
    //   summary  : <String>,
    //   image    : <Url>,
    //   marketTags: <Array>,
    // }
    const {
      identity, content, summary, image, marketTags,
    } = option

    const body = {
      data: {
        type:       'reply',
        id:         identity,
        attributes: {
          content:    content,
          summary:    summary,
          image:      image,
          marketTags: marketTags,
        },
      },
    }

    return this.$axios.patch(`${this.state.setting.apiUrlSocial}/reply`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteReply ({}, option) {
    // option = {
    //   identity : <String>,
    //   account : <String>,
    //   comment   : <String>,
    //   content : <String>,
    // }
    const identity = option.identity || null
    const { account, comment, content } = option
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: ['identity'],
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
    } else {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
        {
          field:    'comment',
          operator: '=',
          value:    comment,
          logic:    0,
        },
        {
          field:    'content',
          operator: '=',
          value:    content,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/reply${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
