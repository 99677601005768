import apiUtils from '~/utils/api'

export const actions = {
  getStockDividends ({}, option) {
    // option = {
    //   tickerSymbol  : <String> <Array>,
    //   kind          : <String> single=單次除權息 summary=年度總計 ,
    //   date         : <String Date> YYYY/MM/DD,
    //   dateOperator : <operator>,
    //   timestamp         : <Number>,
    //   timestampOperator : <operator>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const tickerSymbol = option.tickerSymbol || null
    const kind = option.kind || null
    const date = option.date || null
    const dateOperator = option.dateOperator || '<'
    const timestamp = option.timestamp || null
    const timestampOperator = option.timestampOperator || '<'
    const fields = option.fields || ['tickerSymbol', 'country', 'tradeType', 'kind', 'date', 'timestamp', 'year', 'cash', 'stock', 'sum', 'lastClose', 'dividendYield', 'recovery', 'recoveryDate', 'recoveryDays', 'recoveryOnTime', 'accumulatedYear', 'accumulatedDividend', 'eps']
    const page = option.page || 1
    const limit = option.limit || 100
    const offset = limit * (page - 1)
    const sort = option.sort || {
      timestamp: -1,
    }
    const query = {
      filter: [],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(tickerSymbol)) {
      query.filter.push({
        field:    'tickerSymbol',
        value:    tickerSymbol,
        operator: _.isArray(tickerSymbol) ? 'In' : '=',
        logic:    0,
      })
    }
    if (!_.isNull(kind)) {
      query.filter.push({
        field:    'kind',
        value:    kind,
        operator: _.isArray(kind) ? 'In' : '=',
        logic:    0,
      })
    }
    if (date) {
      if (date === 'now') {
        query.filter.push({
          field:    'date',
          value:    this.$moment().format('YYYY/MM/DD'),
          operator: dateOperator,
          logic:    0,
        })
      } else {
        query.filter.push({
          field:    'date',
          value:    date,
          operator: dateOperator,
          logic:    0,
        })
      }
    }
    if (timestamp) {
      if (timestamp === 'now') {
        query.filter.push({
          field:    'timestamp',
          value:    new Date().valueOf(),
          operator: timestampOperator,
          logic:    0,
        })
      } else {
        query.filter.push({
          field:    'timestamp',
          value:    timestamp,
          operator: timestampOperator,
          logic:    0,
        })
      }
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/stockDividends${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
