<template>
  <div class="notifyMenu">
    <b-dropdown
      variant="link" right offset="-10" menuClass="p-0 m-0" noCaret
      @show="dropdownShow" @hide="dropdownHide">
      <template slot="button-content">
        <i class="fa fa-bell icon-bell align-middle" />
        <client-only>
          <div class="notifyCount" v-if="user.notifyCount">
            {{ formatNotifyCount(user.notifyCount) }}
          </div>
        </client-only>
      </template>
      <div class="d-flex justify-content-between px-2 py-2 bg-title">
        <div class="text-top-title st_color_gray_dark text-left">
          最新通知
        </div>
        <div class="text-top-title-read st_color_main_lightest text-mark-all-read" @click="markAllRead">
          全部標示已讀
        </div>
      </div>
      <b-row noGutters class="notifyList">
        <b-col cols="12" class="frame-notify" v-if="user.notifies && user.notifies.length">
          <template v-for="(notify, index) in user.notifies">
            <OldNotifyCard
              :key="notify.identity"
              :notify="notify"
              class="onMenu"
              :menuTime="'menuTime'"
              :notifyClass="'mt-1'"
              @click.native="$root.$emit('clicked::link')"
              v-if="index < 30" />
          </template>
        </b-col>
        <b-col cols="12" v-else-if="user.notifies">
          <noData />
        </b-col>
        <b-col cols="12" v-else>
          <div class="py-3 text-center">
            <i class="fa fa-spinner fa-spin" />
          </div>
        </b-col>
      </b-row>
      <nuxt-link class="frame-footer" :to="{ name: 'my-notify' }" @click.native="$root.$emit('clicked::link')">
        <b-col cols="12" class="p-2 p-1 text-center st_color_main_lightest">
          查看全部
        </b-col>
      </nuxt-link>
    </b-dropdown>
  </div>
</template>

<script>
  import noData from '~/components/views/exception/noData'
  import OldNotifyCard from '~/components/cards/OldNotifyCard'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      OldNotifyCard,
      noData,
    },
    data () {
      return {
        notifyIcon: 'icon_notification_bell',
      }
    },
    computed: {
      ...mapGetters(['user']),
      notifyIconClass () {
        const classList = [this.notifyIcon]
        return classList.join(' ')
      },
    },
    methods: {
      ...mapActions('api/notify', ['getNotifiesByAccount', 'updateNotify']),
      loadNotifyList () {
        const option = {
          account: [this.user.my_info.identity],
          limit:   20,
        }
        if (this.user.notifies && this.user.notifies.length) {
          option.identity = this.user.notifies[0].identity
          option.limit = 500
        }
        this.getNotifiesByAccount(option)
          .then(data => {
            const result = { data: data.data }
            if (!this.user.notifies) {
              result.clear = true
            }
            this.$store.commit('user/setNotifies', result)
            let firstIdentity = null
            if (_.get(data, 'page.total', 0) > 0) {
              firstIdentity = data.data[0].identity
            }
            this.$store.commit('user/setNotifyRead', firstIdentity)
            if (!_.isNil(firstIdentity)) {
              const cookieDomain = location.hostname.split('.').length > 1 ? `.${location.hostname}` : location.hostname
              this.$cookies.set('notifyRead', firstIdentity, {
                maxAge: 60 * 60 * 24 * 30,
                path:   '/',
                domain: cookieDomain,
              })
            }
          })
      },
      dropdownShow () {
        this.notifyIcon = 'icon_notification_ring'
        this.loadNotifyList()
      },
      dropdownHide () {
        this.notifyIcon = 'icon_notification_bell'
      },
      formatNotifyCount (count) {
        return count > 999 ? '999+' : count
      },
      markAllRead () {
        if (!_.get(this, 'user.notifies.length', 0)) return
        Promise.all(this.user.notifies.filter(el => el.read === null)
          .map(el => {
            const option = {
              identity: el.identity,
            }
            return this.updateNotify(option)
          })).then(data => {
          data.forEach(el => {
            this.$store.commit('user/readNotify', el)
          })
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.frame-footer {
  border-top: solid 2px $st_color_gray_lighter;
  font-size: $st_font-size-small;
  letter-spacing: $st_font_letter-spacing;
  text-align: center;
}

.text-top-title {
  font-size: $st_font-size-small;
  font-weight: $st_font-weight-medium;
  letter-spacing: 0.5px;
}

.text-mark-all-read {
  font-size: $st_font-size-small;
  letter-spacing: $st_font_letter-spacing;
  cursor: pointer;
}

.bg-title {
  background: white;
}

.icon-bell {
  font-size: 22px;
  color: white;
}

.notifyMenu {
  .notifyCount {
    position: absolute;
    padding: 2px 3px;
    background: $st_color_rise;
    color: white;
    font-size: 10px;
    line-height: 13px;
    font-weight: bold;
    min-width: 13px;
    border-radius: 6px;
    top: 2px;
    right: 0;
  }

  .notifyList {
    width: 100vw;
    max-width: 435px;
    max-height: 70vh;
    overflow: auto;
    overflow-x: hidden;

    &__header {
      color: #869ca6;
      font-size: 14px;
      line-height: 20px;
      padding: 8px 0 6px 12px;
    }

    &__footer {
      color: #869ca6;
      letter-spacing: 1px;

      &:hover,
      &:focus {
        color: #3d4a51;
      }

      &:focus > div {
        @important {
          background-color: #f5f6f7;
        }
      }
    }
  }
}
</style>
