import Vue from 'vue'
import { vueWindowSizeMixin } from 'vue-window-size'

// vue-window-size 的 windowWidth 在 Server 是 undefined 時，會造成 Client 端 template v-if 觸發轉換失效，但 v-show 或 {{ windowWidth }} 都正常變更
// 而且因使用該套件產生的變數做 v-if 條件會造成 server and client side 的 Vnode 物件出現不一致的情況，導致在 production mode 會出錯。
// 只有在 Production mode 才會出事，開發模式不會有問題
// 因此在該檔案去修改 vue-window-size 的程式
const screen = {}
Object.defineProperties(screen, {
  width: {
    get: vueWindowSizeMixin.computed.windowWidth,
  },
  height: {
    get: vueWindowSizeMixin.computed.windowHeight,
  },
})

Vue.mixin({
  data () {
    return {
      isMounted: false,
    }
  },
  computed: {
    windowWidth () {
      if (this.isMounted) return screen.width
      return this.$device.isMobile ? 500 : 1000
    },
    windowHeight () {
      return this.isMounted && screen.height
    },
  },
  mounted () {
    this.isMounted = true
  },
})
