<template>
  <b-modal
    @shown="$root.$emit('default-lineplugin')"
    :id="`${expert.identity}-lineNotifyModal`"
    centered
    size="md"
    modal-class="lineNotifyRelationSettingsModal"
    header-class="lineNotifyRelationSettingsModal__header position-relative justify-content-center justify-content-md-between border-0 pt-2 pt-md-3 pb-2 w-100 mx-md-auto st_background_white"
    body-class="lineNotifyRelationSettingsModal__body pt-2 pt-md-3 mb-3 mb-md-0 mx-md-auto st_background_white"
    footer-class="lineNotifyRelationSettingsModal__footer border-0 px-0 pt-0 pt-md-2 st_background_white">
    <template slot="modal-title">
      <span class="st_title-medium-big d-inline-block mt-md-2">
        LINE 通知設定
      </span>
    </template>
    <template slot="modal-header-close">
      <span class="d-none d-md-block">
        ×
      </span>
      <span class="d-flex align-items-center d-md-none st_link-medium-base">
        <i class="mr-1 fas fa-chevron-left st_none-none-biggest" />
        返回
      </span>
    </template>
    <div class="d-flex align-items-center mb-1 mb-md-2">
      <ScanTraderAvatar class="mr-2" width="40" height="40" :src="expert.avatar" />
      <span class="text-ellipsis st_main-medium-base ml-2">
        <svg-icon class="mb-1" :width="$userBadge(expert, 'width', '', 0.4)" :height="$userBadge(expert, 'height', '', 0.4)" :name="$userBadge(expert, 'name')" />
        <nuxt-link v-if="$userCheck.is('service', expert) && _.get(expert, 'belongOrgInfo.name.nick', 0)" :to="$userRoute({ name: 'o-oas' }, expert.belongOrgInfo)">
          <svg-icon
            v-b-tooltip.hover.focus.top="expert.belongOrgInfo.name.nick"
            class="mb-1"
            :width="$userBadge(expert.belongOrgInfo, 'width', '', 0.4)"
            :height="$userBadge(expert.belongOrgInfo, 'height', '', 0.4)"
            :name="$userBadge(expert.belongOrgInfo, 'name')" />
        </nuxt-link>
        {{ expert.name.nick }}{{ (expert.belongOrg && _.get(expert, 'name.real', '')) ? `．${expert.name.real || ''}` : '' }}
      </span>
    </div>
    <span class="st_caption-none-small d-inline-block my-1">
      當這個達人發佈動態或部落格文章時，你將會從微股力 LINE 帳號接收通知
    </span>
    <div class="d-flex align-items-center mt-3">
      <span class="st_accent-medium-smaller mr-2">
        還沒加入微股力 LINE 帳號？
      </span>
      <b-link :href="`https://line.me/R/ti/p/${setting.line.id}`" target="_blank">
        <svg-icon name="icon_line_addfriend" width="86" height="20" class="d-block" />
      </b-link>
    </div>
    <template slot="modal-footer">
      <ul class="lineNotifyRelationSettingsModal__notification w-100 p-0 m-0">
        <li
          @click.prevent="changeNotificationMode('all')"
          :class="{
            'st-md_background_gray_lighter': receiveAllMessages
          }"
          class="cursor-pointer">
          <div class="lineNotifyRelationSettingsModal__mode d-flex py-3 py-md-4 px-3 mx-auto">
            <svg-icon class="mt-1 mr-2" width="16" height="14" :name="receiveAllMessages ? 'icon_line-on-green' : 'icon_line-on-gray'" />
            <span class="d-flex flex-column">
              <span
                :class="{
                  'st_color_gray': !receiveAllMessages,
                  'st_color_main': receiveAllMessages
                }"
                class="st_none-medium-base mb-2">
                所有訊息（預設）
                <i v-if="receiveAllMessages" class="fas fa-check ml-2" />
              </span>
              <span class="st_caption-light-small">
                接收這個達人專頁的所有通知，包含動態和部落格發文
              </span>
            </span>
          </div>
        </li>
        <li
          @click.prevent="changeNotificationMode('close')"
          :class="{
            'st-md_background_gray_lighter': disableReceiveFunction
          }"
          class="cursor-pointer">
          <div class="lineNotifyRelationSettingsModal__mode d-flex py-3 py-md-4 px-3 mx-auto">
            <svg-icon class="mt-1 mr-2" width="16" height="14" :name="disableReceiveFunction ? 'icon_line-off-blue' : 'icon_line-off-gray'" />
            <span class="d-flex flex-column">
              <span
                :class="{
                  'st_color_gray': !disableReceiveFunction,
                  'st_color_main': disableReceiveFunction
                }"
                class="st_none-medium-base mb-2">
                關閉通知
                <i v-if="disableReceiveFunction" class="fas fa-check ml-2" />
              </span>
              <span class="st_caption-light-small">
                停止有關這個達人專頁的所有通知
              </span>
            </span>
          </div>
        </li>
      </ul>
    </template>
  </b-modal>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  export default {
    props: {
      expert: {
        type:    Object,
        default: null,
      },
      lineNotifyRelation: {
        type:    Object,
        require: true,
        default () {
          return {
            identity: null,
            mode:     'close',
          }
        },
      },
    },
    computed: {
      ...mapGetters(['user']),
      ...mapState(['setting']),
      receiveAllMessages () {
        return this.lineNotifyRelation.mode === 'all'
      },
      // receiveSpecificMessages () {
      //   return this.lineNotifyRelation.mode === 'focus'
      // },
      disableReceiveFunction () {
        return this.lineNotifyRelation.mode === 'close'
      },
    },
    methods: {
      changeNotificationMode (state) {
        this.$emit('updated', state)
        this.$root.$emit('bv::hide::modal', `${this.expert.identity}-lineNotifyModal`)
      },
    },
  }
</script>

<style lang="scss">
  .lineNotifyRelationSettingsModal {
    $lineNotifyRelationSettingsModal: &;

    .modal-dialog {
      @include media-breakpoint-down(sm) {
        margin: 0;
        height: 100vh;
        max-width: none;
      }

      .modal-content {
        height: 100%;

        @include media-breakpoint-down(sm) {
          border: none;
          border-radius: 0;
          background-color: $st_color_gray_lighter;
        }
      }
    }

    &__header {
      z-index: 1;

      @include media-breakpoint-up(md) {
        max-width: 472px;
      }

      .close {
        padding: 0;
        margin: 0 -14px 0 0;
        position: absolute;
        left: 16px;
        top: 8px;

        @include media-breakpoint-up(md) {
          position: static;
          font-size: 30px;
          line-height: 16px;
        }
      }
    }

    &__body {
      box-shadow: $st_shadow;

      @include media-breakpoint-down(sm) {
        flex: none;
      }

      @include media-breakpoint-up(md) {
        max-width: 472px;
        box-shadow: none;
      }
    }

    &__footer {
      padding-bottom: 0;

      @include media-breakpoint-up(md) {
        padding-bottom: 20px;
      }
    }

    &__notification {
      list-style: none;

      > :last-child {
        & #{$lineNotifyRelationSettingsModal}__mode {
          border-color: rgba($st_color_gray, 0.5);
        }
      }
    }

    &__mode {
      min-height: 110px;
      border-bottom: 1px solid $st_color_gray_lighter;

      @include media-breakpoint-up(md) {
        min-height: 100px;
        max-width: 472px;
        border-bottom: none;
      }
    }
  }
</style>
