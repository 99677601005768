export default {
  errorHandler (error) {
    console.log(error)
    const res = error.response || {}
    const data = res.data || {}
    return { error: true, ...data }
  },
  checkSingleDataAttributes (res) {
    return (
      res.status === 200 && !_.isUndefined(_.get(res, 'data.data.attributes')))
  },
  checkDataAttributesImageUrl (res) {
    return (
      res.status === 200 && !_.isUndefined(_.get(res, 'data.data.attributes.imageUrl')))
  },
  checkDataAttributesPdfUrl (res) {
    return (
      res.status === 200 && !_.isUndefined(_.get(res, 'data.data.attributes.fileName')))
  },
  checkMultiDataAttributes (res) {
    return (
      res.status === 200 && !_.isUndefined(_.get(res, 'data.data[0].attributes')))
  },
  convertSingleDataAttributes (res) {
    if (this.checkSingleDataAttributes(res)) {
      return {
        type: res.data.data.type,
        id:   res.data.data.id,
        ...res.data.data.attributes,
      }
    }

    return {
      type: null,
      id:   null,
    }
  },
  convertMultiDataAttributes (res) {
    if (this.checkMultiDataAttributes(res)) {
      return {
        data: res.data.data.map(data => ({
          type: data.type,
          id:   data.id,
          ...data.attributes,
        })),
        page:    res.data.meta && res.data.meta.pagination ? res.data.meta.pagination : {},
        tabInfo: res.data.meta && res.data.meta.tabInfo ? res.data.meta.tabInfo : null,
      }
    }
    return {
      data: [],
      page: res.data.meta && res.data.meta.pagination ? res.data.meta.pagination : {},
    }
  },
  encodeQSP (querysp) {
    const rawStr = CryptoJS.enc.Utf8.parse(querysp)
    const base64 = CryptoJS.enc.Base64.stringify(rawStr)
    // console.log(`Encrypted value: ${base64}`);

    // https://www.simonkrueger.com/2014/07/10/always-url-encode-your-base64-encoded-query-parameters.html
    const urlencodedb64 = encodeURIComponent(base64)
    return urlencodedb64 // base64
  },
  getQueryStringByOptions (options) {
    return `?${Object.keys(options)
      .map(key => `${key}=${options[key]}`)
      .join('&')}`
  },
  getQueryString (options) {
    const optionsString = JSON.stringify(options)
    return `?query=${this.encodeQSP(optionsString)}`
  },
}
