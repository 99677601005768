import apiUtils from '~/utils/api'

export const actions = {
  bindCard ({}, option) {
    // option = {
    //   payment: <String>,
    //   prime: <String>
    //   AccountId: <String>
    //   callback: <String>
    // }
    return this.$axios.post(`${this.state.setting.apiUrlSocial}/card/bind`, option)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getCard ({}, option) {
    // option = {
    //   id : <String>,
    //   accountId : <String>
    // }
    const { id, accountId } = option
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/card/${id}?accountId=${accountId}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getCardOrder ({}, option) {
    // option = {
    //   subscriptionId : <String>
    // }
    const { subscriptionId } = option
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/card/order?subscriptionId=${subscriptionId}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getCards ({}, option) {
    const page = option.page || 1
    const limit = option.limit || 100
    const offset = limit * (page - 1)
    const query = {
      limit:  limit,
      offset: offset,
    }
    if (!_.isUndefined(option.isValid)) {
      query.isValid = option.isValid === true ? 1 : 0
    }
    if (option.payment) {
      query.payment = option.payment
    }
    if (option.name) {
      query.name = option.name
    }
    if (option.type) {
      query.type = option.type
    }
    if (option.lastFour) {
      query.lastFour = option.lastFour
    }
    if (option.accountId) {
      query.accountId = option.accountId
    }
    const params = apiUtils.getQueryStringByOptions(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/card/list${params}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  changeSubscriptionCard ({}, option) {
    // option = {
    //   subscriptionId: <String>
    //   cardId: <String>
    // }
    return this.$axios.put(`${this.state.setting.apiUrlSocial}/card/changepayment`, option)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  renameCard ({}, option) {
    // option = {
    //   accountId: <String>,
    //   cardId: <String>
    // }
    return this.$axios.put(`${this.state.setting.apiUrlSocial}/card/rename`, option)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  removeCard ({}, option) {
    // option = {
    //   accountId: <String>,
    //   cardId: <String>
    // }
    return this.$axios.put(`${this.state.setting.apiUrlSocial}/card/remove`, option)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
}
