

export default () => {
    if (process.server) {
        return;
    }

    const detect = () => {
        return 'relList' in document.createElement("link") &&
        'supports' in document.createElement("link").relList &&
        document.createElement("link").relList.supports("preload");
      };

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "@digitalkaoz/preload-polyfill"
        ], function (require) {
            const polyfill = require("@digitalkaoz/preload-polyfill");

            const install = () => require('@digitalkaoz/preload-polyfill/dist/preload-polyfill-inline');

            install(polyfill);

            resolve();
        });
    });
}