export const state = () => ({
  setting: {
    prod:                process.env.prod,
    apiUrlSocial:        process.env.apiUrlSocial,
    apiUrlMypicks:       process.env.apiUrlMypicks,
    apiUrlExchange:      process.env.apiUrlExchange,
    apiGateway:          process.env.apiGateway,
    h5Url:               process.env.h5Url,
    socialUrl:           process.env.socialUrl,
    socialUrlV2:         process.env.socialUrlV2,
    MaxApiUrl:           process.env.MaxApiUrl,
    h5GameId:            process.env.h5GameId,
    line:                process.env.line,
    LIFFappId:           process.env.LIFFappId,
    feLIFFappId:         process.env.feLIFFappId,
    mail:                process.env.mail,
    froalaEditorKey:     process.env.froalaEditorKey,
    froalaEditorV2Key:   process.env.froalaEditorV2Key,
    TPDirect:            process.env.TPDirect,
    LanternFestival2019: process.env.LanternFestival2019,
    imageHost:           process.env.imageHost,
  },
  reqPostBody: null,
})

export const getters = {
  siteSetting (state) {
    return state.setting
  },
  user (state) {
    return state.user
  },
  is_login (state) {
    return !_.isNull(state.user.serial) && !_.isNull(state.user.my_info.serial)
  },
  reqPostBody (state) {
    return state.reqPostBody
  },
}

export const mutations = {
  reqPostBody (state, postBody) {
    state.reqPostBody = postBody
  },
}

export const actions = {
  async nuxtServerInit ({ commit, dispatch }, {
    app, req, res, store, query, route, $axios, redirect,
  }) {
    if (req && req.method === 'POST') {
      await commit('reqPostBody', req.body) // not work after redirect.
      res.writeHead(301, { Location: req.url })
      res.end()
      redirect(route.path)
    }

    if (process.server) {
      commit('user/clearMyUserInfo')
      if (query.token) {
        $axios.setToken(query.token, 'Bearer')
      }
      $axios.defaults.timeout = 10000

      await dispatch('user/getMyUserInfo')
        .catch((err = {}) => {
          if (err.code === 2) {
            return redirect(`${route.path || '/'}?token=${err.data.token}`)
          }
        })

      if (route.name && (route.name === 'u' || route.name === 'o')) {
        redirect('/')
      }

      if (route.name && route.name.match(/^u-uid/)) {
        if (store.state.user.user_info.serial !== Number(route.params.uid)) {
          commit('user/clearExpertUserInfo')
          const uData = await dispatch('user/getUserInfo', route.params.uid)
          if (_.get(uData, 'kind', null) === 'organization') {
            redirect(301, route.path.replace('u/', 'o/').replace(`/${route.params.uid}`, `/${uData.organization.alias}`))
          }
          (!uData || uData.error || !uData.identity) && redirect('/')
        }
      } else if (route.name && route.name.match(/^o-oas/)) {
        if (_.get(store, 'state.user.user_info.organization.alias') !== route.params.oas) {
          commit('user/clearExpertUserInfo')
          const oData = await dispatch('user/getUserInfo', {
            serialOrAlias:       route.params.oas,
            isOrganizationAlias: true,
          })
          if (_.get(oData, 'kind', null) === 'personal') {
            redirect(301, route.path.replace('o/', 'u/').replace(`/${route.params.oas}`, `/${oData.serial}`))
          }
          (!oData || oData.error || !oData.identity) && redirect('/')
        }
      } else {
        commit('user/clearExpertUserInfo')
        commit('user/cloneUserInfo')
      }
    }
  },
}
