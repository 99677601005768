import 'tributejs/dist/tribute.css'
import 'froala-editor/js/froala_editor.pkgd.min'
import 'froala-editor/js/languages/zh_tw'
import 'froala-editor/js/third_party/embedly.min'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/third_party/embedly.min.css'
import Tribute from 'tributejs'
import Vue from 'vue'
import VueFroala from 'vue-froala-wysiwyg'

(function froalaEditor ($) {
  if ($.FroalaEditor) {
    // Check if button is active.
    const isActive = function(cmd) {
      let tag = 'N'
      const blocks = this.selection.blocks()

      if (blocks.length) {
        const blk = blocks[0]
        const defaultTag = this.html.defaultTag()
        if (blk.tagName.toLowerCase() !== defaultTag && blk !== this.el) {
          tag = blk.tagName
        }
      }

      if (['LI', 'TD', 'TH'].indexOf(tag) >= 0) {
        tag = 'N'
      }

      return tag.toLowerCase() === cmd
    }

    // Define custom buttons.
    $.FroalaEditor.DefineIcon('h1', { NAME: '<strong>H1</strong>', template: 'text' })
    $.FroalaEditor.DefineIcon('h2', { NAME: '<strong>H2</strong>', template: 'text' })
    $.FroalaEditor.RegisterCommand('h1', {
      title:    'Heading 1',
      callback: function(cmd) {
        if (isActive.apply(this, [cmd])) {
          this.paragraphFormat.apply('N')
        } else {
          this.paragraphFormat.apply(cmd)
        }
      },
      refresh: function($btn) {
        $btn.toggleClass('fr-active', isActive.apply(this, [$btn.data('cmd')]))
      },
    })

    $.FroalaEditor.RegisterCommand('h2', {
      title:    'Heading 2',
      callback: function(cmd) {
        if (isActive.apply(this, [cmd])) {
          this.paragraphFormat.apply('N')
        } else {
          this.paragraphFormat.apply(cmd)
        }
      },
      refresh: function($btn) {
        $btn.toggleClass('fr-active', isActive.apply(this, [$btn.data('cmd')]))
      },
    })
    $.FroalaEditor.DefineIcon('image100', { NAME: '100%', template: 'text' })
    $.FroalaEditor.RegisterCommand('image100', {
      title:                '將圖片大小轉為 100%',
      focus:                true,
      undo:                 true,
      refreshAfterCallback: true,
      callback:             function() {
        const $img = this.image.get()
        $img.css({ height: 'auto', width: '100%' })
        $img.click()
      },
    })

    $.FroalaEditor.DefineIcon('youtube', { NAME: '<i class="fab fa-youtube"></i>', template: 'text' })

    // copy from froala src code and modify icon
    $.FroalaEditor.RegisterQuickInsertButton('youtube', {
      icon:           'youtube',
      requiredPlugin: 'video',
      title:          'Insert Video',
      undo:           !1,
      callback:       function() {
        // eslint-disable-next-line no-alert
        const e = prompt(this.language.translate('Paste the URL of the video you want to insert.'))
        e && this.video.insertByURL(e)
      },
    })

    $.FroalaEditor.RegisterQuickInsertButton('file', {
      icon:           'insertFile',
      requiredPlugin: 'file',
      title:          'Upload File',
      undo:           false,
      callback:       function() {
        const editor = this

        if (!editor.shared.$qi_file_input) {
          editor.shared.$qi_file_input = $(`<input type="file" name="${editor.opts.fileUploadParam}" accept="${editor.opts.fileAllowedTypes.indexOf('*') >= 0 ? '/' : ''}${editor.opts.fileAllowedTypes.join(', ').toLowerCase()}" style="display: none;">`)
          $('body').eq(0).append(editor.shared.$qi_file_input)

          editor.events.$on(editor.shared.$qi_file_input, 'change', function change () {
            const inst = $(this).data('inst')

            if (this.files) {
              inst.quickInsert.hide()
              inst.file.upload(this.files)
            }

            // Chrome fix.
            $(this).val('')
          }, true)
        }

        editor.$qi_file_input = editor.shared.$qi_file_input

        if (editor.helpers.isMobile()) editor.selection.save()
        editor.events.disableBlur()
        editor.$qi_file_input.data('inst', editor).trigger('click')
      },
    })
  }
}(jQuery))

const thirdPartyPlugin = {
  install (Vue) {
    Vue.prototype.$tribute = new Tribute({
      values:         [],
      containerClass: 'tribute-container st_tribute',
      itemClass:      'st_tribute__item',
      searchOpts:     {
        pre:  '<span class="st_tribute__keyword highlight">',
        post: '</span>',
        skip: false,
      },
    })
  },
}
Vue.use(VueFroala)
Vue.use(thirdPartyPlugin)
