<template>
  <b-modal
    id="modalContract"
    ref="modalContract"
    size="lg"
    centered
    hideHeader
    @shown="addModalEvent"
    @hide="removeModalEvent"
    hideHeaderClose
    hideFooter
    noCloseOnEsc
    noCloseOnBackdrop
    noEnforceFocus>
    <b-container fluid>
      <b-row class="bg-light-grey py-3">
        <b-col class="my-2" cols="12">
          <div class="d-flex justify-content-center align-items-center">
            <svg-icon name="icon_menu_expert_blue" :width="35" :height="35" />
            <h3 class="ml-2 fc-fullblack fs-20px">
              創作者合作契約
            </h3>
          </div>
        </b-col>

        <b-col cols="12">
          <div class="d-flex justify-content-center">
            <svg-icon class="bg-white" name="icon_collaborate" :width="198" :height="86" />
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col offsetMd="1" cols="12" md="10">
          <b-container class="border py-2">
            <b-row noGutters>
              <b-col cols="12" md="4">
                <div class="d-flex d-md-inline-block">
                  <div class="fc-blue fs-14px header-label">
                    乙方
                  </div>
                  <div class="fc-black fs-14px">
                    {{ user.my_info.service.contract.partyB }}（{{
                      user.my_info.service.company ? '公司' : '自然人'
                    }}創作者）
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="4" class="text-md-center">
                <div class="d-flex d-md-inline-block">
                  <div class="fc-blue fs-14px header-label">
                    分潤比例
                  </div>
                  <div class="fc-black fs-14px">
                    {{ user.my_info.service.rate.grant * 100 }}%{{
                      user.my_info.service.contract.exclusive && user.my_info.service.contract.exclusive === true
                        ? '（獨家）'
                        : ''
                    }}
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="4" class="text-md-right">
                <div class="d-flex d-md-inline-block">
                  <div class="fc-blue fs-14px header-label">
                    {{ user.my_info.service.company ? '統一編號' : '身分證字號' }}
                  </div>
                  <div class="fc-black fs-14px">
                    {{ user.my_info.service.code }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col offsetMd="1" cols="12" md="10">
          <div class="contract-content px-2" ref="content">
            <div class="contract__body">
              曠世智能股份有限公司（以下稱甲方）為依照中華民國法律合法設立之股份有限公司，統一編號號碼為55860334。本人/本公司為具備財經專業之自然人創作者/法人組織（以下稱乙方），乙方擬與甲方合作，於甲方所提供之「微股力
              ScanTrader」投資理財社交平台（以下稱「本平台」）網站上提供理財知識分享相關內容（以下簡稱「內容」)。甲乙雙方同意依此創作者合約（以下稱「本合約」），約定雙方之權利與義務等相關規範共同遵循之，實際內容以雙方紙本簽訂為為主。
            </div>
            <div class="contract__subTitle">
              一、合作範圍
            </div>
            <div class="contract__body">
              <p>
                平台租用：乙方於甲方平台申請成為創作者，並於網站中簽署本合約時，即視為已閱讀、暸解、並同意本合約及個別規範條款所有約定條款（包括：「<a
                  href="/terms-of-service"
                  target="_blank">
                  服務條款 </a>」、「<a href="/terms-of-privacy" target="_blank"> 隱私權政策 </a>」）的所有內容，
                並同意向甲方租用本平台空間提供第三人（以下稱「訂閱者」）內容訂閱服務。
              </p>
              <p>2.內容提供：乙方得使用本平台上之功能，提供予訂閱者免費或付費之「內容」服務。</p>
              <p>3.其它服務：除上述合作主體外，如有其他服務內容雙方將另定合約之。</p>
            </div>
            <div class="contract__subTitle">
              二、創作者身份
            </div>
            <div class="contract__body">
              <p>
                1.創作者定義：於本平台中提供內容的創作者可分為個人創作者，包括獨立個人創作者以及隸屬於特定法人組織之個人創作者（以下稱「個人創作者」）；與法人組織創作者（以下稱「組織創作者」）等兩種類型。乙方得視自身之合作之意願於簽約時選擇其中一種身份。
              </p>
              <p>
                2.個人創作者：創作者之身份包括兩種類型，第一種為具合法投顧執照、合法證期分析師執照(簡稱個人分析師)、第二種為不具前述兩種執照之網路達人等(簡稱網路達人)，皆得以個人身份於本平台中提供創作內容。
              </p>
              <p>
                3.組織創作者：組織創作者包括兩種類型，第一種為同時管理一位至多位個人分析師之組織或企業（例如投顧公司、投信公司）、第二種則為法人組織或品牌(例如研究機構、理財媒體、部落格網站、報章雜誌或經政府核定之小規模營業人等)提供其所屬之個人創作者於本平台中提供內容服務。
              </p>
            </div>
            <div class="contract__subTitle">
              三、內容管理原則
            </div>
            <div class="contract__body">
              <p>
                1.內容規範：乙方所提供之內容與在網站相關的言論必須符合中華民國投資相關法規之規範，包括但不限於「證券交易法」、「證券投資信託及顧問法」、「證券投資顧問事業管理規則」、「中華民國證券投資信託暨顧問商業同業公會證券投資顧問事業從事廣告及營業活動行為規範」等法規。
              </p>
              <p>
                2.組織管理：乙方若為組織創作者或企業，則必須對其組織內所有的創作者進行言論合法性之要求與約束，並對該組織下所有創作者之言論及行為負責。
              </p>
              <p>
                3.智慧財產：乙方於本平台所提供之內容包括但不限於說明內容、相關訊息、多媒體檔案等，均係由乙方自行提供、上載、及發布，其內容之所有權、著作財產權皆歸屬於乙方，乙方須對內容之真實性、合法性、與智慧財產權負擔完全之責任。針對乙方所刊載之內容，甲方並無審查之義務，亦不負任何明示或默示之承諾或擔保之責任。
              </p>
              <p>
                4.內容管理：乙方創作者之內容如有違反法令、違背公序良俗、侵害第三人權益、或有違反本合約之虞之情形，甲方得不經事先通知，直接加以移除、使之無法被存取或被閱讀、或採取其他限制性措施。
              </p>
              <p>
                5.內容財產：乙方創作者之內容其所有權及著作財產權為乙方所有，其得在考量付費訂閱者權益下，自由使用其內容於其它平台之上。
              </p>
              <p>
                6.資訊揭露：依民國112年1月1日起公布之網路交易稅籍登記新制，符合稅籍登記標準之網路銷售營業人必須於網路銷售頁面或APP明顯位置清楚揭露營業人名稱及統一編號，乙方若符合上述資格須主動提供營業人資訊予甲方，甲方依相關規定揭露至平台網站。（詳見本合約第五條第3點，若有異動則依政府公布而調整）
              </p>
            </div>
            <div class="contract__subTitle">
              四、商業合作
            </div>
            <div class="contract__body">
              <p>
                1.內容付費：乙方可針對其內容設定方案價格(以月為單位)，由訂閱者於本平台中進行付費訂閱或單次購買方式支付後，該訂閱者即可瀏覽、取得該付費之內容
                。
              </p>
              <p>
                2.收費限制：乙方若為投顧、投信公司，其於本平台中所提供之內容訂閱方案，僅得以免費形式提供給訂閱者。不得以任何形式於本平台中對訂閱者收取內容費用。
              </p>
              <p>
                3.稅籍登記新制：依民國112年1月1日起公布之網路交易稅籍登記新制，個人及組織創作者若於平台開啟訂閱方案，需自事實發生日起15日內應主動完成稅籍應登記事項「網域名稱及網路位址及會員帳號」，若創作者為自然人且月營業額達4萬元以上，需按新制申請稅籍登記，若有異動則依政府公布而調整。
              </p>
              <p>4.金流手續費：第三方支付平台收取的手續費用，為單筆訂單金額的3%。</p>
              <p>
                5.平台租金：單筆訂單未稅金額（即單筆訂單金額/1.05，以下同）扣除金流手續費後，再乘以平台租金比例({{
                  ownRate
                }}%)後，為單筆平台租金，當月訂單產生之單筆平台租金加總後，即為「當月平台租金」。當月平台租金計算公式如下：{[(單筆訂單金額/1.05)-(單筆訂單金額*3%)]*{{
                  ownRate
                }}%}*當月訂單筆數
              </p>
              <p>
                6.內容收入：當月訂單未稅總額扣除上述金流手續費、當月平台租金後，剩餘之金額即為乙方「當月內容收入」。若為一次性付款多月或年繳之訂單，則將其拆成單筆訂單按月計算並併入當月內容收入。
              </p>
              <p class="mb-0">
                7.政府稅法規定與健保制度：
              </p>
              <p>
                7-1.
                當乙方為本國自然人與其內容收入達或超過2萬時，甲方將依法進行申報及扣繳，扣繳後再以淨額支付予乙方，說明如下：
              </p>
              <p class="pl-2">
                (1) 當乙方內容收入達或超過2萬時，甲方將依法扣繳10%所得稅與 2.11%
                之二代健保補充保費，前述稅額及保費由甲方代理繳納。實際稅率將依政府法規而有所變動。
                <br>
                (2) 若乙方有政府規定之免扣除二代健保補充保費身分，乙方須於本約簽訂後提供被保險人身分證明予甲方。
              </p>
              <p>
                7-2.
                當乙方為非中華民國境內居住之個人，或在中華民國境內無固定營業場所之營利事業在中華民國境內有所得收入（符合政府「國外營利事業就源扣繳」法規）將扣除政府規定
                20% 之所得稅代扣額度後，依法由甲方代理繳納之。實際稅率將依政府法規而有所變動。
              </p>
              <p class="mb-0">
                8.付款辦法：
              </p>
              <p class="pl-2">
                (1) 乙方所創造之內容收入為當月訂單總金額扣除退訂金額後，承上本條第5、6款公式計算之，每月結算一次。
                <br>
                (2)
                乙方簽約身份為自然人時，甲方於次月25日將乙方應得之內容收入匯入至乙方帳戶；若乙方簽約身份為公司行號時，則由甲方於次月18日前通知乙方前月之收入總額，乙方須於收到收入總額明細後開立並寄出發票（未稅後之金額同本條第5款「內容收入」之金額）請領前月之款項，若甲方於次月25日前（不含當日）收到發票，將於次月25日將乙方應得之內容收入匯入至乙方帳戶。若甲方未於次月25日前收到發票，乙方同意當月內容收入順延至下個月內容收入併行結算。以上日期遇例假日則順延至第一個工作日計算。
                <br>
                (3)
                甲方使用之匯款帳戶為中國信託商業銀行，若乙方非使用該銀行作為收款帳戶或需跨國進行匯款，則匯款所產生之相關手續費皆由乙方負擔，並從乙方內容收入中扣除。
                <br>
                (4) 若遇特殊連續假期，甲方須提前公告並調整次月之時程。
              </p>
              <p>9.付費廣告：乙方得視曝光需求，向甲方付費採購平台上所提供之廣告方案，創造其內容於本平台上之能見度。</p>
              <p>
                10.共同活動：乙方上架即同意參與甲方官方舉辦之活動，包括但不限於「邀請好友送折價券」、「週年慶」等活動，甲方官方有權製作及發放優惠券、點數等優惠，乙方不得異議。
              </p>
              <p>
                11.內容授權：乙方於本平台發佈之所有內容，同意無償授權甲方及甲方合作夥伴使用， 以便甲方進行整合行銷。
              </p>
            </div>
            <div class="contract__subTitle">
              五、其它
            </div>
            <div class="contract__body">
              <p>
                1.違約罰則：甲乙任一方因故未能履行付費內容所提供之約定提供內容予訂閱者、或是違反合約內之內容管理原則時，經他方書面、電子郵件或
                ScanTrader
                微股力平台通知系統通知後仍未改善時，他方有權終止本合約。如係乙方違約，則甲方得沒收乙方該月份之所有收入，做為退款予訂閱者之費用，乙方不得異議。
              </p>
              <p>
                2.爭議處理：第三方對乙方所提供之內容或服務產生爭議時，乙方應主動說明，或配合甲方針對該內容或服務之疑義或爭議，提出相關資料協助說明。
              </p>
              <p>
                3.終止合作：如乙方未按指定方式提供資訊、或所提供之資訊不正確或與事實不符、或提供之相關內容違反法令、違反公序良俗或其他影響觀感之虞等情事或因相關內容涉及行政或司法程序時，甲方有權得經事先書面、電子郵件或
                ScanTrader
                微股力平台系統通知後，拒絕或暫停乙方於本平台所提供之相關服務（包括但不限於下架方案及官方達人認證）。
              </p>
              <p class="mb-0">
                4.保密義務：雙方承諾，對於因本合約所獲知之所有資訊應保密者（以下稱「保密資
                訊」），雙方當事人本身、其所有董事、實質負責人、持有公司股份超過股份總額百分之十的股東、監察人以及經理人等，不得將其全部或一部以任何方式予以散佈、公開或揭露予第三人知悉，並應以善良管理人的注意程度保持其秘密性，其保密義務於本契約終止後五年內其保密義務仍繼續存在。任他方並得隨時以書面、電子郵件或
                ScanTrader
                微股力平台通知系統通知他方於合理範圍內要求他方歸還其所提供的所有保密資訊，他方於收到通知後應立即配合之。但下列情形不在此限：
              </p>
              <p class="pl-2">
                (1) 經雙方事前書面、電子郵件或 ScanTrader 微股力平台通知系統同意； (2)
                保密資訊可自其他已合法公開的處所取得者； (3) 法律另有特別規定者； (4)
                因法律、命令、司法或行政主管機關的裁判、處分或命令，而需公開或交付任何保密資訊，於接獲相關裁判、處分或命令通知時，立即以書面、電子郵件或ScanTrader
                微股力平台通知系統通知他方者； (5)
                該保密資訊係取自合法持有該資訊的第三人，且該第三人對資訊原始所有人未負任何保密義務者。
              </p>
              <p>
                5.書面、電子郵件或 ScanTrader
                微股力平台通知系統通知：除雙方另有約定外，相關之雙方之溝通與通知可採用電子郵件或是電話、或是通訊軟體，唯本合約所規定的正式通知均應以書面、電子郵件或
                ScanTrader 微股力平台通知系統為之。
              </p>
              <p>
                6.不可轉讓：甲乙任一方不得於未經他方書面、電子郵件或 ScanTrader
                微股力平台通知系統同意下，擅自移轉本合約規定的權利義務予本合約以外之第三人。
              </p>
              <p>
                7.部分無效：本合約的任何條款如與相關法令有所牴觸而歸於無效者，僅該牴觸的部分無效，本合約的其他條款仍不受影響。至於因牴觸法令以致無效的部分，由雙方另行協商合法可行的方式替代之。
              </p>
              <p>
                8.異議處理：本合約悉以中華民國法律為準據法。因乙方於本平台所提供之服務所產生之任何爭議，應先以協商方式解決。若提請法院訴訟時，雙方合意以台灣台北地方法院為第一審管轄法院。
              </p>
              <p>
                9.合約調整：甲方保有此合約之解釋、修改、調整、終止等相關權利，相關修正將於修改後十日內以電子郵件通知乙方，其詳細辦法、變更事項或未盡事宜則以網站公告為主。
              </p>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col class="text-center">
          <b-button class="st_main_l" @click.prevent="agreeContract" :disabled="!readOver">
            已詳細閱讀
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    data () {
      return {
        readOver:     false,
        orderPrice:   1000,
        orderPrice2:  3000,
        orderCount:   10,
        invoiceFee:   1.05,
        paymentFee:   0.03,
        govFee:       0.1,
        govHealthFee: 0.0191,
      }
    },
    computed: {
      ...mapGetters(['user']),
      formatOrderPrice () {
        return this.formatNumber(this.orderPrice)
      },
      formatOrderPrice2 () {
        return this.formatNumber(this.orderPrice2)
      },
      ownRate () {
        return 100 - this.user.my_info.service.rate.grant * 100
      },
      ownFee () {
        return this.user.my_info.service.rate.grant * 100
      },
      ownRatePercent () {
        return 100 - this.user.my_info.service.rate.grant * 100
      },
      govFeePercent () {
        return this.govFee * 100
      },
      govHealthFeePercent () {
        return this.govHealthFee * 100
      },
      singleWithoutFee () {
        return (
          this.orderPrice
          - Math.round(this.orderPrice * this.paymentFee)
          - (this.orderPrice - Math.round(this.orderPrice / this.invoiceFee))
        )
      },
      singleOwnGrant () {
        return Math.round(this.singleWithoutFee * this.ownRate)
      },
      monthOwnGrant () {
        return this.singleOwnGrant * this.orderCount
      },
      singleExpertGrant () {
        return this.singleWithoutFee - this.singleOwnGrant
      },
      monthExpertGrant () {
        return this.singleExpertGrant * this.orderCount
      },
      singleWithoutFee2 () {
        return (
          this.orderPrice2
          - Math.round(this.orderPrice2 * this.paymentFee)
          - (this.orderPrice2 - Math.round(this.orderPrice2 / this.invoiceFee))
        )
      },
      singleOwnGrant2 () {
        return Math.round(this.singleWithoutFee2 * this.ownRate)
      },
      monthOwnGrant2 () {
        return this.singleOwnGrant2 * this.orderCount
      },
      singleExpertGrant2 () {
        return this.singleWithoutFee2 - this.singleOwnGrant2
      },
      monthExpertGrant2 () {
        return this.singleExpertGrant2 * this.orderCount
      },
      monthExpertWillGet2 () {
        return (
          this.monthExpertGrant2
          - Math.floor(this.monthExpertGrant2 * this.govFee)
          - Math.round(this.monthExpertGrant2 * this.govHealthFee)
        )
      },
    },
    methods: {
      ...mapActions('api/service', ['updateService']),
      formatNumber (num) {
        return num ? num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,') : 0
      },
      checkingReadoverAndEnabledBTN ($event) {
        const curScrollTop = $event.target.scrollTop + $event.target.clientHeight
        const { scrollHeight } = $event.target
        if (curScrollTop > 0) {
          this.readOver = curScrollTop >= scrollHeight - 100
        }
      },
      agreeContract () {
        const option = {
          contract: this.user.my_info.service.contract,
          identity: this.user.my_info.service.identity,
        }
        option.contract.agree = new Date().valueOf()
        this.updateService(option).then(data => {
          if (!data.error) {
            this.$toast.show('已同意合約內容，您可至銷售分析下載合約').goAway(3000)
          }
        })
      },
      addModalEvent () {
        if (this.$refs.content) {
          this.$refs.content.addEventListener('scroll', this.checkingReadoverAndEnabledBTN)
          this.$refs.content.addEventListener('touchmove', this.checkingReadoverAndEnabledBTN)
        }
      },
      removeModalEvent () {
        if (this.$refs.content) {
          this.$refs.content.removeEventListener('scroll', this.checkingReadoverAndEnabledBTN)
          this.$refs.content.removeEventListener('touchmove', this.checkingReadoverAndEnabledBTN)
        }
      },
    },
    mounted () {
      if (this.user.my_info.service.contract.agree === -1) {
        this.$refs.modalContract.show()
      }
    },
    beforeDestroy () {
      this.removeModalEvent()
    },
  }
</script>

<style lang="scss">
#modalContract {
  @important {
    padding-right: 0;
  }

  @include media-breakpoint-down(md) {
    .header-label {
      width: 30%;
      min-width: 100px;
    }
  }

  .modal-body {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .contract-content {
    overflow-y: auto;
    height: 40vh;
    letter-spacing: 1px;
    font-weight: 500;
    text-align: justify;
  }

  .contract__body {
    color: #3d4a51;
  }

  .contract__subTitle {
    color: #869ca6;
    margin-top: 1rem;
  }
}
</style>
