import annotations from 'highcharts/modules/annotations'
import annotationsadvanced from 'highcharts/modules/annotations-advanced'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import indicatorsInit from 'highcharts/indicators/indicators-all'
import mapInit from 'highcharts/modules/map'
import stockInit from 'highcharts/modules/stock'
import Vue from 'vue'

stockInit(Highcharts)
mapInit(Highcharts)
Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'PingFang TC, "PingFang SC", "Microsoft JhengHei", "微軟正黑體", "Helvetica", "Avenir", sans-serif',
    },
  },
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },
})
indicatorsInit(Highcharts)
annotations(Highcharts)
annotationsadvanced(Highcharts)

Vue.use(HighchartsVue)
