export default ({ store }) => {
  if (window.requestIdleCallback) {
    requestIdleCallback(() => {
      Fingerprint2.get(components => {
        const result = Fingerprint2.x64hash128(components.map(pair => pair.value).join(), 31)
        store.commit('user/setFingerprint', result)
      })
    })
  } else {
    setTimeout(() => {
      Fingerprint2.get(components => {
        const result = Fingerprint2.x64hash128(components.map(pair => pair.value).join(), 31)
        store.commit('user/setFingerprint', result)
      })
    }, 500)
  }
}
