import apiUtils from '~/utils/api'

export const actions = {
  getReaction ({}, option) {
    // option = {
    //   identity : <String>,
    //   account : <String>,
    //   kind    : <String>, article || activity || comment || reply
    //   index   : <String>,
    //   name    : <String>, like
    // }
    const identity = option.identity || null
    const { account, kind, index } = option
    const name = option.name || 'like'
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: ['identity', 'account.identity', 'account.serial', 'kind', 'name', 'status'],
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
    } else {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
        {
          field:    'index',
          operator: '=',
          value:    index,
          logic:    0,
        },
        {
          field:    'name',
          operator: '=',
          value:    name,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/reaction${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getReactions ({}, option) {
    // option = {
    //   kind  : <String>, article || activity || comment || reply
    //   index : <String>,
    //   name  : <String>, like
    //   fields: <Array>,
    //   page  : <Number>,
    //   limit : <Number>,
    //   sort  : <Object>,
    // }
    const { kind, index } = option
    const name = option.name || 'like'
    const fields = option.fields || ['account.identity', 'account.serial', 'account.avatar', 'account.name']
    const page = option.page || 1
    const limit = option.limit || 500
    const offset = limit * (
      page - 1)
    const sort = option.sort || { 'status.updated': -1 }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
        {
          field:    'index',
          operator: '=',
          value:    index,
          logic:    0,
        },
        {
          field:    'name',
          operator: '=',
          value:    name,
          logic:    0,
        },
      ],
      page: {
        limit:  limit,
        offset: offset,
      },
      sort:   sort,
      fields: fields,
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/reactions${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  createReaction ({}, reaction) {
    // reaction = {
    //   account: <String>,
    //   kind   : <String>, article || activity || comment || reply
    //   index  : <String>,
    //   name   : <String>,
    // }
    const query = {
      filter: [
        {
          field:    'account',
          operator: '=',
          value:    reaction.account,
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    reaction.kind,
          logic:    0,
        },
        {
          field:    'index',
          operator: '=',
          value:    reaction.index,
          logic:    0,
        },
        {
          field:    'name',
          operator: '=',
          value:    reaction.name,
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    reaction.status = {
      deleted: null,
    }
    const body = {
      data: {
        type:       'reaction',
        attributes: reaction,
      },
    }

    return this.$axios.put(`${this.state.setting.apiUrlSocial}/reaction${params}`,
      body)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  deleteReaction ({}, option) {
    // option = {
    //   identity : <String>,
    //   account : <String>,
    //   kind    : <String>, article || activity || comment || reply
    //   index   : <String>,
    //   name    : <String>, like
    // }
    const identity = option.identity || null
    const { account, kind, index } = option
    const name = option.name || 'like'
    const query = {
      filter: [
        {
          field:    'status.deleted',
          operator: '=',
          value:    null,
          logic:    0,
        },
      ],
      fields: ['identity'],
    }
    if (!_.isNull(identity)) {
      query.filter.push(
        {
          field:    'identity',
          operator: '=',
          value:    identity,
          logic:    0,
        },
      )
    } else {
      query.filter.push(
        {
          field:    'account',
          operator: '=',
          value:    account,
          logic:    0,
        },
        {
          field:    'kind',
          operator: '=',
          value:    kind,
          logic:    0,
        },
        {
          field:    'index',
          operator: '=',
          value:    index,
          logic:    0,
        },
        {
          field:    'name',
          operator: '=',
          value:    name,
          logic:    0,
        },
      )
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.delete(`${this.state.setting.apiUrlSocial}/reaction${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
