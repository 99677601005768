import apiUtils from '~/utils/api'

export const actions = {
  getComposition ({}, option) {
    // option = {
    //   tickerSymbol: <String>,
    // }
    const { tickerSymbol } = option
    const query = {
      filter: [
        {
          field:    'tickerSymbol',
          value:    tickerSymbol,
          operator: '=',
          logic:    0,
        },
        {
          field:    'lastTick',
          value:    true,
          operator: '=',
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios
      .get(`${this.state.setting.apiUrlSocial}/etf/composition${params}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
  getConstituent ({}, option) {
    const { tickerSymbol } = option
    const query = {
      filter: [
        {
          field:    'tickerSymbol',
          value:    tickerSymbol,
          operator: '=',
          logic:    0,
        },
        {
          field:    'lastTick',
          value:    false,
          operator: '=',
          logic:    0,
        },
        {
          field:    'constituent',
          value:    true,
          operator: '=',
          logic:    0,
        },
        {
          field:    'limit',
          value:    10, // mobile:5, desktop:10
          operator: '=',
          logic:    0,
        },
      ],
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios
      .get(`${this.state.setting.apiUrlSocial}/etf/composition${params}`)
      .then(res => res.data)
      .catch(apiUtils.errorHandler)
  },
}
