import apiUtils from '~/utils/api'

export const actions = {
  getCouponType ({}, option = {}) {
    // option = {
    //   identity: <String>,
    //   drafted  : <Boolean>,
    //   disabled  : <Boolean>,
    //   fields  : <Array>,
    // }
    const { identity } = option
    const drafted = option.drafted || null
    const disabled = option.disabled || null
    const fields = option.fields || ['identity', 'title', 'content', 'source', 'drafted', 'disabled', 'published', 'inputCode', 'discount', 'usePeriod', 'whereConditions', 'payConditions', 'userConditions', 'account.name', 'account.serial']
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
        {
          field:    'identity',
          value:    identity,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
    }
    if (!_.isNull(drafted)) {
      query.filter.push({
        field:    'drafted',
        value:    drafted,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(disabled)) {
      query.filter.push({
        field:    'disabled',
        value:    disabled,
        operator: '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/couponType${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getCouponTypes ({}, option = {}) {
    // option = {
    //   search  : <String>,
    //   identity: <String> <Array>,
    //   drafted  : <Boolean>,
    //   disabled  : <Boolean>,
    //   usePeriodStart  : <String>now <Number>,
    //   usePeriodStartOperator  : <Operator>,
    //   usePeriodStart  : <String>now <Number>,
    //   usePeriodEndOperator  : <Operator>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const search = option.search || null
    const identity = option.identity || null
    const drafted = option.drafted || null
    const disabled = option.disabled || null
    const usePeriodStart = option.usePeriodStart || null
    const usePeriodStartOperator = option.usePeriodStartOperator || '<='
    const usePeriodEnd = option.usePeriodEnd || null
    const usePeriodEndOperator = option.usePeriodEndOperator || '>='
    const fields = option.fields || ['identity', 'title', 'content', 'source', 'disabled', 'inputCode', 'discount', 'usePeriod', 'whereConditions', 'payConditions', 'userConditions', 'account.name', 'account.serial', 'account.kind', 'account.service', 'account.organization.alias']
    const page = option.page || 1
    const limit = option.limit || 50
    const offset = limit * (page - 1)
    const sort = option.sort || {
      'status.updated': -1,
    }
    const query = {
      filter: [
        {
          field:    'status.deleted',
          value:    null,
          operator: '=',
          logic:    0,
        },
      ],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(search)) {
      query.filter.push({
        field:    'title',
        value:    search,
        operator: 'Like',
        logic:    0,
      })
    }
    if (identity) {
      query.filter.push({
        field:    'identity',
        value:    identity,
        operator: _.isArray(identity) ? 'In' : '=',
        logic:    0,
      })
    }
    if (!_.isNull(drafted)) {
      query.filter.push({
        field:    'drafted',
        value:    drafted,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(disabled)) {
      query.filter.push({
        field:    'disabled',
        value:    disabled,
        operator: '=',
        logic:    0,
      })
    }
    if (usePeriodStart) {
      if (usePeriodStart === 'now') {
        query.filter.push({
          field:    'usePeriod.start',
          value:    new Date().valueOf(),
          operator: usePeriodStartOperator,
          logic:    0,
        })
      } else {
        query.filter.push({
          field:    'usePeriod.start',
          value:    usePeriodStart,
          operator: usePeriodStartOperator,
          logic:    0,
        })
      }
    }
    if (usePeriodEnd) {
      if (usePeriodEnd === 'now') {
        query.filter.push({
          field:    'usePeriod.end',
          value:    new Date().valueOf(),
          operator: usePeriodEndOperator,
          logic:    0,
        })
      } else {
        query.filter.push({
          field:    'usePeriod.end',
          value:    usePeriodEnd,
          operator: usePeriodEndOperator,
          logic:    0,
        })
      }
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/couponTypes${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getCouponTypeCodesCheck ({ commit }, option) {
    // option = {
    //   payment: <String>,
    //   payType: <String>,
    //   month: <Number>,
    //   kind: <String>,
    //   index: <String>,
    //   codes: <String>,
    // }
    const {
      payment, payType, totalMonth, kind, index, codes,
    } = option

    const query = {
      payment,
      payType,
      totalMonth,
      kind,
      index,
      codes,
    }
    const params = apiUtils.getQueryStringByOptions(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/couponType/codes/check${params}`)
      .then(res => res.data.data)
      .catch(apiUtils.errorHandler)
  },
}
