

export default () => {
    if (process.server) {
        return;
    }

    const detect = () => {
        return [Element.prototype, Document.prototype, DocumentFragment.prototype].every((e) => e.hasOwnProperty('append'));
      };

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "mdn-polyfills/Node.prototype.append"
        ], function (require) {
            const polyfill = require("mdn-polyfills/Node.prototype.append");

            resolve();
        });
    });
}