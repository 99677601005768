import apiUtils from '~/utils/api'

export const actions = {
  getTickerSymbol ({}, option) {
    // option = {
    //   text  : <String>,
    //   tickerSymbol  : <String>,
    //   fields  : <Array>,
    // }
    const text = option.text || null
    const tickerSymbol = option.tickerSymbol || null
    const fields = option.fields || ['name.primary', 'info', 'delisting', 'priority', 'tickerSymbol', 'tradeType', 'country', 'canPortfolio', 'lastTick']
    // const fields = option.fields || ['identity', 'name', 'info', 'exchange', 'tradeType', 'country', 'delisting', 'priority', 'onlineDate', 'tickerSymbol', 'checkedAt', 'canPortfolio', 'lastTick', 'pTicks']
    const query = {
      filter: [],
      fields: fields,
    }
    if (!_.isNull(text)) {
      query.filter.push({
        field:    'text',
        value:    text,
        operator: '=',
        logic:    0,
      })
    }
    if (!_.isNull(tickerSymbol)) {
      query.filter.push({
        field:    'tickerSymbol',
        value:    tickerSymbol,
        operator: _.isArray(tickerSymbol) ? 'In' : '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/tickerSymbol${params}`)
      .then(res => apiUtils.convertSingleDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
  getTickerSymbols ({}, option) {
    // option = {
    //   text  : <String> <Array>,
    //   tickerSymbol  : <String> <Array>,
    //   fields  : <Array>,
    //   page    : <Number>,
    //   limit   : <Number>,
    //   sort    : <Object>,
    // }
    const text = option.text || null
    const tickerSymbol = option.tickerSymbol || null
    const fields = option.fields || ['name.primary', 'info', 'delisting', 'priority', 'tickerSymbol', 'tradeType', 'country', 'canPortfolio', 'lastTick']
    // const fields = option.fields || ['identity', 'name', 'info', 'exchange', 'tradeType', 'country', 'delisting', 'priority', 'onlineDate', 'tickerSymbol', 'checkedAt', 'canPortfolio', 'lastTick', 'pTicks']
    const page = option.page || 1
    const limit = option.limit || ((text && _.isArray(text)) ? (text.length * 2) : 50)
    const offset = limit * (page - 1)
    const sort = option.sort || {
      priority:     1,
      tickerSymbol: 1,
    }
    const query = {
      filter: [],
      fields: fields,
      page:   {
        limit:  limit,
        offset: offset,
      },
      sort: sort,
    }
    if (!_.isNull(text)) {
      query.filter.push({
        field:    'text',
        value:    text,
        operator: _.isArray(text) ? 'In' : 'Like',
        logic:    0,
      })
    }
    if (!_.isNull(tickerSymbol)) {
      query.filter.push({
        field:    'tickerSymbol',
        value:    tickerSymbol,
        operator: _.isArray(tickerSymbol) ? 'In' : '=',
        logic:    0,
      })
    }
    const params = apiUtils.getQueryString(query)
    return this.$axios.get(`${this.state.setting.apiUrlSocial}/tickerSymbols${params}`)
      .then(res => apiUtils.convertMultiDataAttributes(res))
      .catch(apiUtils.errorHandler)
  },
}
